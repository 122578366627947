import React, {useMemo} from "react"
import clsx from "clsx"

interface InventoryItem {
  label: string
  value: any
}

export interface CardSection {
  values: InventoryItem[]
  title?: string
  className?: string
}

const Card: React.FC<CardSection> = ({title, values, className}) => {
  return (
    <div className={clsx("w-full md:w-max", className)}>
      {title && <label className="overflow line-clamp-1 text-sm color-gray-400 mb-2">{title}</label>}
      <div
        className={clsx(
          "flex flex-col md:flex-row gap-2 md:gap-3",
          "items-start justify-start p-2 md:p-4 rounded-lg border border-gray-100 shadow bg-white"
        )}>
        {values.map((value, index) => (
          <div
            key={index}
            className={clsx(
              "flex flex-row items-end md:items-start gap-2 md:flex-col w-full md:w-max border-b md:border-b-0 md:border-r",
              "border-gray-100 pb-3 md:pb-0 md:pr-3 last:border-none last:pb-0 last:pr-0"
            )}>
            <label className="font-bold text-lg">{value.value}</label>
            <label className="text-sm color-gray-400">{value.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

const CardWrapper = ({
  cardSectionData = [],
  className = "mb-3"
}: {
  cardSectionData: CardSection[]
  className?: string
}) => {
  const inventorySections = useMemo(
    () =>
      cardSectionData.map((data, index) => (
        <Card key={index} title={data.title} values={data.values} className={data?.className} />
      )),
    [cardSectionData]
  )

  return (
    <div
      className={clsx(
        "flex flex-wrap gap-x-2 gap-y-4 md:justify-start justify-between items-stretch md:w-auto w-full",
        className
      )}>
      {inventorySections}
    </div>
  )
}

export default CardWrapper
