import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "ru",
    fallbackLng: "ru",
    keySeparator: ".",

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-store"
      }
    },
    debug: window.location.hostname.includes("localhost"),

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
