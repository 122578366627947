import {useEffect, useState} from "react"
import integrationService from "src/services/integration"

export function WazzupFrameComponent({filter = undefined}: {filter?: Array<{name: string; phone: string}>}) {
  const [url, setUrl] = useState<string>(null)

  useEffect(() => {
    integrationService.wazzupIFrame({filter: filter}).then(({url}) => setUrl(url))
    return () => setUrl(undefined)
  }, [])

  return url && <iframe src={url} width="100%" height="100%" />
}
