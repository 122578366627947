import {RegisterOptions} from "react-hook-form"
import {SharedInventoryGroupSelect} from "src/components/shared/components/select/inventory-group"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {FormControlCkeditor} from "src/components/shared/inputs/form-control-ckeditor"
import {FormControlColor} from "src/components/shared/inputs/form-control-color"
import {FormControlDate} from "src/components/shared/inputs/form-control-date"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {FormControl} from "src/components/shared/inputs/form-control"
import FormSelectAsyncPagination from "src/components/shared/inputs/form-select-async.pagination"
import {ClientModel} from "src/models/manager/clients/client.model"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import {CustomFieldType} from "src/models/setting-config"
import clientService from "src/services/clients.service"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import inventorySetService from "src/services/inventory/inventory-set.service"
import inventoryService from "src/services/inventory/inventory.service"

export interface FieldParams {
  name: string
  rootclassname?: string
  className?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  params?: RegisterOptions<any, any>
  options?: any[]
}

const datetimeConfig = {
  minuteStep: 1,
  showTime: {format: "HH:mm"},
  allowClear: false
}

const fields = (params: FieldParams): Record<CustomFieldType, any> => ({
  [CustomFieldType.STRING]: (
    <FormControl
      rootclassname={params.rootclassname}
      className={params.className}
      label={params.label}
      placeholder={params.placeholder}
      required={params.required}
      disabled={params.disabled}
      params={params.params}
      name={params.name}
    />
  ),
  [CustomFieldType.HTML]: (
    <FormControlCkeditor
      rootclassname={params.rootclassname}
      className={params.className}
      label={params.label}
      placeholder={params.placeholder}
      required={params.required}
      disabled={params.disabled}
      params={params.params}
      name={params.name}
    />
  ),
  [CustomFieldType.BOOLEAN]: (
    <FormControlCheckbox
      rootclassname={params.rootclassname}
      name={params.name}
      disabled={params.disabled}
      className="form-check-input"
      label={params.label}
    />
  ),
  [CustomFieldType.INTEGER]: (
    <FormControl
      rootclassname={params.rootclassname}
      className={params.className}
      label={params.label}
      placeholder={params.placeholder}
      required={params.required}
      disabled={params.disabled}
      params={params.params}
      name={params.name}
      number_only={+true}
      number_decimal={+false}
    />
  ),
  [CustomFieldType.DECIMAL]: (
    <FormControl
      rootclassname={params.rootclassname}
      className={params.className}
      label={params.label}
      placeholder={params.placeholder}
      required={params.required}
      disabled={params.disabled}
      params={params.params}
      name={params.name}
      number_only={+true}
      number_decimal={+true}
      pattern="^\d*(\.\d{0,2})?$"
    />
  ),
  [CustomFieldType.DATE]: (
    <FormControlDate
      label={params.label}
      placeholder={params.placeholder}
      name={params.name}
      rootclassname={params.rootclassname}
      className={params.className}
      disabled={params.disabled}
      required={params.required}
      params={params.params}
      type="date"
    />
  ),
  [CustomFieldType.DATETIME]: (
    <FormControlDatetime
      label={params.label}
      placeholder={params.placeholder}
      rootclassname={params.rootclassname}
      disabled={params.disabled}
      datetimeConfig={datetimeConfig}
      name={params.name}
    />
  ),
  [CustomFieldType.COLOR]: (
    <FormControlColor
      label={params.label}
      placeholder={params.placeholder}
      rootclassname={params.rootclassname}
      disabled={params.disabled}
      name={params.name}
    />
  ),
  [CustomFieldType.CLIENT]: (
    <FormSelectAsyncPagination<ClientModel>
      name={params.name}
      className="col"
      label={params.label}
      required={params.required}
      placeholder={params.label}
      disabled={params.disabled}
      params={params.params}
      listOptions={(params) => clientService.list(params)}
      getValue={(id) => clientService.get(+id)}
      getOptionLabel={(val: ClientModel) => val.name}
      getOptionValue={(val: ClientModel) => (val ? String(val.id) : undefined)}
      isSearchable={false}
      isClearable={true}
    />
  ),
  [CustomFieldType.INVENTORY]: (
    <FormSelectAsyncPagination<InventoryModel>
      name={params.name}
      className="col"
      label={params.label}
      required={params.required}
      placeholder={params.label}
      params={params.params}
      disabled={params.disabled}
      listOptions={(params) => inventoryService.list(params)}
      getValue={(id) => inventoryService.get(+id)}
      getOptionLabel={(val: InventoryModel) => `${val.name} (${val.unique_id})`}
      getOptionValue={(val: InventoryModel) => (val ? String(val.id) : undefined)}
      isSearchable={false}
      isClearable={true}
    />
  ),
  [CustomFieldType.PRODUCT]: (
    <SharedInventoryGroupSelect
      name={params.name}
      className="col"
      label={params.label}
      required={params.required}
      placeholder={params.label}
      params={params.params}
      disabled={params.disabled}
      listOptions={(params) => inventoryGroupService.list(params)}
      isSearchable={false}
      isClearable={true}
    />
  ),
  [CustomFieldType.SET]: (
    <FormSelectAsyncPagination<InventorySetModel>
      name={params.name}
      className="col"
      label={params.label}
      required={params.required}
      placeholder={params.label}
      params={params.params}
      disabled={params.disabled}
      listOptions={(params) => inventorySetService.list(params)}
      getValue={(id) => inventorySetService.get(+id)}
      getOptionLabel={(val: InventorySetModel) => val.name}
      getOptionValue={(val: InventorySetModel) => (val ? String(val.id) : undefined)}
      isSearchable={false}
      isClearable={true}
    />
  )
})

export default fields
