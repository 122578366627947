import axios from "axios"
import {ListModel} from "../../models/common"
import {ApiAbstract} from "../../abstract/api.abstract"
import {
  InventorizationInventoryModel,
  InventorizationInventoryStateModel,
  InventoryInventorizationModel
} from "src/models/manager/order/order-request-inventory-comment"

export class InventoryInventorizationService extends ApiAbstract {
  constructor() {
    super("v1/crm/inventorizations")
  }

  public async list(params?: any): Promise<ListModel<InventoryInventorizationModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async post(payload: any): Promise<InventorizationInventoryModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async bulkCreate(payload: any): Promise<undefined> {
    const response = await axios.post(this.getUrl("bulk_create"), payload)
    return response.data
  }
  public async listStates(params?: any): Promise<InventorizationInventoryStateModel[]> {
    const response = await axios.get(this.getUrl("states"), {params})
    return response.data
  }
  public async taskList(params?: any): Promise<ListModel<InventorizationInventoryModel>> {
    const response = await axios.get(this.getUrl("tasklist"), {params})
    return response.data
  }
}

const inventoryInventorizationService = new InventoryInventorizationService()
export default inventoryInventorizationService
