import {Switch} from "antd"
import {useId} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import {SwitchSize} from "antd/es/switch"
import clsx from "clsx"

export interface FormControlConfig {
  className?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: JSX.Element | string
  size?: SwitchSize
  onChange?: () => void
  style?: React.CSSProperties
}

export function FormControlSwitch(config: FormControlConfig) {
  const id = useId()
  const {watch, setValue} = useFormContext()
  const value = watch(config.name)

  return (
    <div className={clsx("flex gap-2 items-center", config.className)}>
      <Switch
        id={`${id}_${config.name}`}
        size={config.size}
        value={value}
        onChange={(checked) => {
          setValue(config.name, checked, {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true
          })
          config.onChange && config.onChange()
        }}
        style={config.style}
      />

      {config.label && (
        <label className="cursor-pointer" htmlFor={`${id}_${config.name}`}>
          {config.label}
        </label>
      )}
    </div>
  )
}
