import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ClientIndividualPassportModel} from "src/models/manager/clients/client-individual-passport.model"

class ManageClientIndividualPassportService extends ApiAbstract {
  constructor() {
    super("v1/crm/clients")
  }

  public async delete(clientId: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`${clientId}/passport/individual`))
    return response.data
  }
  public async get(clientId: number): Promise<ClientIndividualPassportModel> {
    const response = await axios.get(this.getUrl(`${clientId}/passport/individual`), {
      params: {skip_loader: true, skip_error_handling: true}
    })
    return response.data
  }
  public async post(clientId: number, payload: any): Promise<ClientIndividualPassportModel> {
    const response = await axios.post(this.getUrl(`${clientId}/passport/individual`), payload)
    return response.data
  }
  public async patch(clientId: number, payload: any): Promise<ClientIndividualPassportModel> {
    const response = await axios.patch(this.getUrl(`${clientId}/passport/individual`), payload)
    return response.data
  }
}
const clientIndividualPassportService = new ManageClientIndividualPassportService()
export default clientIndividualPassportService
