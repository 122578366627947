import {useContext} from "react"
import {ConfigContext} from "src/index"
import {useTranslation} from "react-i18next"
import {IntegrationLandingWrapper} from "../../integration/landing"
import {Link, Outlet, useNavigate} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import WidgetLogo from "./widget-logo"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"

const WidgetWrapperComponent = () => {
  const {integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const integration = integrationMap["widget"]
  const navigate = useNavigate()
  const activeRoute = useCurrentRoute()

  const tabs: TabPanelConfig[] = [
    {key: "categories", label: "Категории"},
    {key: "products", label: "Продукты"}
  ]

  const tabsConfig: TabConfig = {
    items: tabs,
    onClick: (config: TabPanelConfig) => navigate(`/integrations/widget/${config.key}`),
    activeTab: activeRoute
  }

  return (
    integration && (
      <>
        <HeaderContent>
          <div className="flex gap-2 items-center header-content">
            <Link to="/integrations" className="text-2xl font-semibold link">
              {t("integration.header")}
            </Link>
            <Icon icon="chevron_right" />
            <div className="text-2xl font-semibold">{integration.name}</div>
          </div>
        </HeaderContent>

        <div className="flex flex-col gap-6">
          {/* <IntegrationLandingWrapper integration={integrationMap['widget']}>
            </IntegrationLandingWrapper> */}

          <div className="bg-white rounded-lg border border-gray-100 shadow overflow-hidden">
            <TabPanel className="rounded-t-lg" {...tabsConfig} />
            <div className="p-3">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default WidgetWrapperComponent
