import React, {InputHTMLAttributes, useId} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import clsx from "clsx"

export interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  rootstyle?: React.CSSProperties
  name: string
  params?: RegisterOptions<any, any>
  label?: string | React.ReactNode
  className?: string
  checkboxStyle?: string
}

export function FormControlCheckbox(config: FormControlConfig) {
  const id = useId()
  const {register} = useFormContext()

  const className = `${config.className ? `form-check-input ${config.className}` : "form-check-input"}`

  return (
    <div className={`flex gap-2 items-center ${config.rootclassname}`} style={config.rootstyle}>
      <input
        {...config}
        {...register(config.name, config.params)}
        type="checkbox"
        className={clsx(className, config.checkboxStyle)}
        style={{marginTop: 12, marginBottom: 12}}
        autoComplete="off"
        id={id}
      />
      {config.label && (
        <label className="overflow line-clamp-1 text-sm color-gray-400 font-medium flex items-center" htmlFor={id}>
          {config.label}
        </label>
      )}
    </div>
  )
}
