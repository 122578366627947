import {BehaviorSubject, firstValueFrom, tap} from "rxjs"
import modalService from "src/components/modal/global/modal.service"
import authService from "./auth.service"
import {toast} from "react-toastify"
import {Progress} from "antd"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import i18n from "i18next"

export type GuideStates = "product" | "document" | "order" | "metrics"

export interface OnboadringState {
  intro: boolean
  hidden: boolean
  states: Record<GuideStates, boolean>
}

export interface Checklist {
  key: string
  label: string
  checked?: boolean
}

export class OnboardingService {
  private static instance: OnboardingService

  state$: BehaviorSubject<OnboadringState> = new BehaviorSubject<OnboadringState>(undefined)
  checklist$: BehaviorSubject<Checklist[]> = new BehaviorSubject<Checklist[]>([])

  updateStorage(val: OnboadringState) {
    firstValueFrom(
      authService.profile$.pipe(tap((profile) => localStorage.setItem(`onboarding_${profile.id}`, JSON.stringify(val))))
    )
  }

  public setState(state: OnboadringState) {
    this.updateStorage(state)
    this.state$.next(state)
  }

  public setChecklist(list: Checklist[]) {
    this.checklist$.next(list.map((obj) => ({...obj, checked: obj?.checked || false})))
  }

  public activate(stateKey: GuideStates) {
    if (!i18n.isInitialized) {
      return null
    }

    const state = this.state$.value
    if (!state) return
    state.states[stateKey] = true

    const success = Object.keys(state.states).reduce((p, c) => p && state.states[c], true)
    if (success && !state.hidden) {
      state.hidden = true
      toast(
        <div className="d-flex align-items-center gap-3">
          <Progress
            percent={100}
            size={48}
            status="normal"
            type="circle"
            format={(percent) => {
              if (percent === 100) return "4/4"
            }}
            style={{fontWeight: 600}}
            trailColor="var(--color-gray-100)"
            strokeColor="var(--color-primary)"
            success={{strokeColor: "var(--color-primary)"}}
            strokeWidth={12}
            strokeLinecap="butt"
          />
          <h5 className="font-15 fw-500 color-black mb-0">{i18n.t("onboarding.finished")}</h5>
        </div>
      )
    }

    this.state$.next(state)
    this.updateStorage(state)
  }

  public hide() {
    if (!i18n.isInitialized) {
      return null
    }

    const state = this.state$.value
    if (!state) return
    const success = Object.keys(state.states).reduce((p, c) => p && state.states[c], true)
    const onConfirm = () => {
      this.state$.next({...state, hidden: true})
      this.updateStorage({...state, hidden: true})
    }

    if (!success) {
      modalService.open({
        component: (
          <ConfirmModal
            message={i18n.t("onboarding.skip.message")}
            confirm_text={i18n.t("onboarding.skip.confirm")}
            onConfirm={onConfirm}
          />
        )
      })
    } else {
      onConfirm()
    }
  }

  public trigger(key: string, val: boolean) {
    this.checklist$.next(
      this.checklist$.value.map((obj) => ({
        ...obj,
        checked: key === obj.key ? val : obj.checked
      }))
    )
  }

  public static getInstance(): OnboardingService {
    if (!OnboardingService.instance) {
      OnboardingService.instance = new OnboardingService()
    }
    return OnboardingService.instance
  }
}

const onboardingService = OnboardingService.getInstance()
export default onboardingService
