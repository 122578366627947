import axios from "axios"
import {BehaviorSubject, Observable, of, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from "src/abstract/api.abstract"
import {CustomFieldModel, CustomFieldOption, SettingConfig} from "src/models/setting-config"
import {getAPI} from "../interceptor"
import authService from "./auth.service"

export class SettingService extends ApiAbstract {
  constructor() {
    super("v1/crm/settings")
  }

  private _refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined)

  public config$: Observable<SettingConfig> = authService.logged$.pipe(
    switchMap((logged) =>
      logged
        ? this._refresh$.pipe(
            switchMap(() => getAPI<SettingConfig>(axios.get(this.getUrl(), {params: {skip_error_handling: true}})))
          )
        : of(undefined)
    ),
    shareReplay(1)
  )

  public refreshSettings = () => this._refresh$.next()

  public async get(params?: any): Promise<SettingConfig> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }

  public async getInventoryOptions(params: any = {}): Promise<CustomFieldOption> {
    const response = await axios.get(this.getUrl("custom_fields/inventory"), {
      params
    })
    return response.data
  }

  public async save(payload: any): Promise<SettingConfig> {
    const response = await axios.patch(this.getUrl(), payload)
    this.refreshSettings()
    return response.data
  }

  public async saveCustomFields(payload: any): Promise<CustomFieldModel> {
    const response = await axios.patch(this.getUrl("custom_fields"), payload)
    this.refreshSettings()
    return response.data
  }
}

const settingService = new SettingService()
export default settingService
