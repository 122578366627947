import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {LabelModel} from "src/models/label"
import {useContext} from "react"
import {ConfigContext} from "src"
import {useFormContext} from "react-hook-form"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "options"

export function SharedPaymentTypeSelect(config: Omit<FormSelectConfig<InventoryStateStatus>, RemovalKeys>) {
  const {t} = useTranslation()
  const {constants, constantsMap} = useContext(ConfigContext)
  const form = useFormContext()
  const value = form.watch(config.name)

  return (
    <FormSelect<LabelModel>
      label={t("common.select.payment")}
      placeholder={t("common.select.placeholder.payment")}
      {...config}
      options={constants.PAYMENT_TYPES}
      value={constantsMap.PAYMENT_TYPES[+value]}
      getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
      getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
  )
}
