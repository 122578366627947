import {Link} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {IntegrationLandingWrapper} from "../landing"
import {ConfigContext} from "src"
import {Image} from "antd"
import wazzup1 from "src/assets/images/integrations/wazzup_1.png"
import wazzup2 from "src/assets/images/integrations/wazzup_2.png"
import wazzup3 from "src/assets/images/integrations/wazzup_3.png"

export function WazzupLandingComponent() {
  const {integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const wazzup = integrationMap["wazzup"]

  return (
    wazzup && (
      <>
        <HeaderContent>
          <div className="flex gap-2 items-center header-content">
            <Link to="/integrations" className="text-2xl font-semibold link">
              {t("integration.header")}
            </Link>
            <Icon icon="chevron_right" />
            <div className="text-2xl font-semibold">{wazzup.name}</div>
          </div>
        </HeaderContent>

        <IntegrationLandingWrapper integration={wazzup}>
          <div className="flex flex-col gap-2">
            <div className="text-base font-medium">Пишите первыми в соцсетях и мессенджерах прямо из CRM</div>

            <div className="flex flex-row gap-3 overflow-x-auto mb-3" style={{scrollBehavior: "smooth"}}>
              <Image.PreviewGroup>
                <Image
                  src={wazzup3}
                  style={{height: "16rem", width: "auto", maxWidth: "none"}}
                  className="object-contain rounded-lg shadow border border-gray-100 bg-white"
                  draggable={false}
                />
                <Image
                  src={wazzup1}
                  style={{height: "16rem", width: "auto", maxWidth: "none"}}
                  className="object-contain rounded-lg shadow border border-gray-100 bg-white"
                  draggable={false}
                />
                <Image
                  src={wazzup2}
                  style={{height: "16rem", width: "auto", maxWidth: "none"}}
                  className="object-contain rounded-lg shadow border border-gray-100 bg-white"
                  draggable={false}
                />
              </Image.PreviewGroup>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-base">Интеграция с Wazzup: Удобная коммуникация с клиентами через мессенджеры</p>
            <p className="text-base">
              Наша CRM-система теперь интегрирована с Wazzup, что позволяет вашим сотрудникам эффективно
              взаимодействовать с клиентами через популярные мессенджеры, такие как WhatsApp, Instagram и Telegram
            </p>

            <div className="flex flex-col gap-2">
              <h3 className="text-[18px] font-semibold mb-0">Как работает интеграция:</h3>
              <ol className="flex flex-col gap-1 list-decimal text-base">
                <li className="ml-4">
                  <strong>Централизованное управление сообщениями:</strong> Все переписки с клиентами из разных
                  мессенджеров будут отображаться прямо в нашей CRM, что позволяет отслеживать коммуникации с клиентами
                  в одном месте
                </li>
                <li className="ml-4">
                  <strong>История и архив сообщений:</strong> Все сообщения сохраняются в системе, что помогает вам
                  легко возвращаться к перепискам, искать по ключевым словам и анализировать взаимодействие с клиентами
                </li>
                <li className="ml-4">
                  <strong>Массовые рассылки:</strong> С помощью Wazzup можно настроить массовые рассылки сообщений,
                  чтобы информировать клиентов об акциях, новостях или важных изменениях
                </li>
                <li className="ml-4">
                  <strong>Уведомления и автоматизация:</strong> Установите автоматические уведомления и ответы на часто
                  задаваемые вопросы, чтобы экономить время сотрудников и улучшать скорость отклика
                </li>
                <li className="ml-4">
                  <strong>Поддержка многоканального общения:</strong> Интеграция Wazzup с нашей CRM обеспечивает
                  возможность подключения сразу нескольких мессенджеров для поддержки клиентов, делая работу более
                  эффективной
                </li>
              </ol>
            </div>

            <div className="flex flex-col gap-2">
              <h3 className="text-[18px] font-semibold mb-0">Преимущества интеграции:</h3>
              <ol className="flex flex-col gap-1 list-disc text-base">
                <li className="ml-4">
                  <strong>Удобство и скорость:</strong> Все сообщения поступают в один интерфейс CRM, избавляя от
                  необходимости переключаться между мессенджерами
                </li>
                <li className="ml-4">
                  <strong>Легкость в управлении:</strong> Управляйте коммуникациями команды из одного центра,
                  отслеживайте работу сотрудников и распределяйте задачи
                </li>
                <li className="ml-4">
                  <strong>Повышение клиентского сервиса:</strong> Быстрый доступ к историям переписки и возможность
                  автоматизации значительно улучшают качество обслуживания клиентов
                </li>
              </ol>
            </div>
          </div>
        </IntegrationLandingWrapper>
      </>
    )
  )
}
