import {Image} from "antd"
import {useContext} from "react"
import {ConfigContext} from "src"
import sms1 from "src/assets/images/integrations/sms_1.png"
import sms2 from "src/assets/images/integrations/sms_2.png"
import sms3 from "src/assets/images/integrations/sms_3.png"
import sms4 from "src/assets/images/integrations/sms_4.png"

export function SmsLanding({onConnect}: {onConnect: () => void}) {
  const {integrationMap} = useContext(ConfigContext)
  const integration = integrationMap && integrationMap["sms"]

  return (
    integration &&
    !integration.connected && (
      <div className="flex flex-col">
        <div className="flex flex-row gap-3 overflow-x-scroll mb-6">
          <Image.PreviewGroup>
            <Image
              src={sms1}
              style={{height: "16rem", width: "auto", maxWidth: "none"}}
              className="object-contain rounded-lg shadow border border-gray-100 bg-white"
              draggable={false}
            />
            <Image
              src={sms2}
              style={{height: "16rem", width: "auto", maxWidth: "none"}}
              className="object-contain rounded-lg shadow border border-gray-100 bg-white"
              draggable={false}
            />
            <Image
              src={sms3}
              style={{height: "16rem", width: "auto", maxWidth: "none"}}
              className="object-contain rounded-lg shadow border border-gray-100 bg-white"
              draggable={false}
            />
            <Image
              src={sms4}
              style={{height: "16rem", width: "auto", maxWidth: "none"}}
              className="object-contain rounded-lg shadow border border-gray-100 bg-white"
              draggable={false}
            />
          </Image.PreviewGroup>
        </div>

        <div className="font-semibold text-2xl mb-3">Об интеграции</div>
        <div className="font-medium text-base mb-2">
          Сократите время на подписание договоров и увеличьте скорость заключения сделок
        </div>
        <ul className="list-disc ml-5 text-black text-[14px] mb-6">
          <li>Удалённое подписание документов через СМС-сообщения</li>
          <li>Повышайте удобство для клиентов и партнёров с минимальными усилиями</li>
          <li>Автоматизируйте процессы подписания и освобождайте ресурсы для других задач</li>
          <li>Моментально получайте подписанные договоры без необходимости в личной встрече</li>
          <li>Интеграция с вашим CRM для удобного хранения и управления документами</li>
        </ul>

        <div className="font-semibold text-2xl mb-3">Возможности</div>
        <div className="font-medium text-base mb-2">
          Сократите время на подписание договоров и увеличьте скорость заключения сделок
        </div>
        <ul className="list-disc ml-5 text-black text-[14px] mb-6">
          <li>Оптимизация рабочего процесса | Быстрая отправка и получение подписанных документов</li>
          <li>Повышение эффективности | Ускорение оформления договоров и сделок</li>
          <li>Повышение удобства | Клиенты и партнёры могут подписывать документы в любое время и в любом месте</li>
          <li>Управление документами | Полный контроль над подписанными документами через удобный интерфейс</li>
        </ul>

        <div className="flex flex-col sm:flex-row gap-3 sm:items-start">
          <div className="flex flex-col card-bio flex-1 gap-3 bg-white items-start">
            <div className="text-black font-semibold text-xl">100 сообщении / 2 400 ₸</div>
            <button className="btn btn-primary btn-color-white font-medium w-full" onClick={onConnect}>
              Подключить
            </button>
          </div>
          <div className="flex flex-col card-bio flex-1 gap-3 bg-white items-start">
            <div className="text-black font-semibold text-xl">500 сообщении / 12 000 ₸</div>
            <button className="btn btn-primary btn-color-white font-medium w-full" onClick={onConnect}>
              Подключить
            </button>
          </div>
          <div className="flex flex-col card-bio flex-1 gap-3 bg-white items-start">
            <div className="text-black font-semibold text-xl">1000 сообщении / 24 000 ₸</div>
            <button className="btn btn-primary btn-color-white font-medium w-full" onClick={onConnect}>
              Подключить
            </button>
          </div>
        </div>
      </div>
    )
  )
}
