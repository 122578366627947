import clsx from "clsx"

interface IconConfig extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  icon: string
}

export default function Icon(config: IconConfig) {
  return (
    <span {...config} className={clsx("material-symbols-rounded leading-none user-select-none", config.className)}>
      {config.icon}
    </span>
  )
}
