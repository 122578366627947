import React from "react"
import {Dropdown, type MenuProps} from "antd"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import notificationService from "src/services/notification.service"

const NotificationActions = ({
  notifications,
  handleHide,
  markRead,
  notificationIds,
  fetchNotifications,
  page,
  handleClickArchive,
  showArchived,
  setNotifications
}) => {
  const {t} = useTranslation()

  const archiveAll = async () => {
    await notificationService.archivePost({
      notification_ids: notificationIds,
      archive_all: true
    })
    setNotifications((prev) => ({
      ...prev,
      results: [],
      next: null
    }))
    fetchNotifications({page})
  }

  const items: MenuProps["items"] = [
    {
      key: "inventory",
      style: {display: "flex", flexDirection: "row"},
      expandIcon: () => null,
      onClick: () => markRead(notificationIds, true),
      label: (
        <div className="flex gap-2 hover:text-primary" style={{paddingLeft: -12}}>
          <Icon icon="check_box" style={{marginLeft: -4}} />
          {t("notification.allArchiveRead")}
        </div>
      )
    },
    {
      key: "archived",
      style: {display: "flex", flexDirection: "row"},
      expandIcon: () => null,
      onClick: archiveAll,
      label: (
        <div className="flex gap-2 hover:text-primary" style={{paddingLeft: -12}}>
          <Icon icon="archive" style={{marginLeft: -4}} />
          {t("notification.archiveAll")}
        </div>
      )
    },
    {
      key: "archive",
      style: {display: "flex", flexDirection: "row"},
      expandIcon: () => null,
      onClick: handleClickArchive,
      label: (
        <div className="flex gap-2 hover:text-primary" style={{paddingLeft: -12}}>
          <Icon icon="inventory_2" style={{marginLeft: -4}} />
          {showArchived ? t("notification.showAll") : t("notification.showArchive")}
        </div>
      )
    }
  ]

  return (
    <div
      className={`flex p-4 w-full items-center justify-between ${notifications.results.length === 0 && "border-b"} border-gray-100`}>
      <h2 className="text-lg font-semibold text-gray-800 flex ">{t("notification.notifications")}</h2>
      <div className="flex gap-3">
        <Dropdown
          overlayStyle={{zIndex: 10000}}
          menu={{items}}
          placement="bottomLeft"
          trigger={["click", "click"]}
          arrow={false}>
          <button className="gap-2 text-nowrap text-gray-400">
            <Icon icon="more_horiz" />
          </button>
        </Dropdown>
        <button className="gap-2 text-nowrap text-gray-400" onClick={handleHide}>
          <Icon icon="close" />
        </button>
      </div>
    </div>
  )
}

export default NotificationActions
