import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink.js'
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave.js'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js'
import DataFilter from '@ckeditor/ckeditor5-html-support/src/datafilter.js'
import DataSchema from '@ckeditor/ckeditor5-html-support/src/dataschema.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
import MediaEmbedToolbar from '@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js'
import Mention from '@ckeditor/ckeditor5-mention/src/mention.js'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SelectAll from '@ckeditor/ckeditor5-select-all/src/selectall.js'
import ShowBlocks from '@ckeditor/ckeditor5-show-blocks/src/showblocks.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters.js'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js'
import StandardEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode.js'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js'
import Style from '@ckeditor/ckeditor5-style/src/style.js'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize.js'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js'
import TextPartLanguage from '@ckeditor/ckeditor5-language/src/textpartlanguage.js'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js'
import TodoList from '@ckeditor/ckeditor5-list/src/todolist'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js'
import LineHeight from './line-height/lineheight'
import Placeholder from './placeholder'

class Editor extends ClassicEditor {}

// Plugins to include in the build.
Editor.builtinPlugins = [
  Alignment,
  AutoImage,
  Autoformat,
  AutoLink,
  Autosave,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  DataFilter,
  DataSchema,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlEmbed,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  MediaEmbedToolbar,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  StandardEditingMode,
  Strikethrough,
  Style,
  Subscript,
  LineHeight,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  TodoList,
  Underline,
  WordCount,
  Placeholder,
]

// Editor configuration.
Editor.defaultConfig = {
  toolbar: {
    items: [
      'style',
      'heading',
      'alignment',
      'fontFamily',
      'fontSize',
      'lineHeight',
      'highlight',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'link',
      'bulletedList',
      'numberedList',
      'superscript',
      'subscript',
      '|',
      'outdent',
      'indent',
      '|',
      'imageUpload',
      'imageInsert',
      'blockQuote',
      'insertTable',
      '-',
      'specialCharacters',
      'removeFormat',
      'htmlEmbed',
      'showBlocks',
      'selectAll',
      'pageBreak',
      'undo',
      'redo',
    ],
    shouldNotGroupWhenFull: true,
  },
  language: 'ru',
  image: {
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      'linkImage',
    ],
  },
  lineHeight: {
    options: [0.5, 1, 1.5, 2, 2.5]
  },
  // table: {
  //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'],
  // },
  placeholderKeys: {
    "user": "Имя менеджера",
    "proxy_user": "Владелец доверенности",
    "proxy_num": "Номер доверенности",
    "proxy_date": "Дата доверенности",
    "rent_id": "ID",
    "rent_unique_id": "Уникальный номер",
    "rent_start": "Начало аренды",
    "rent_start_fact": "Фактическое начало аренды",
    "rent_end": "Конец аренды",
    "rent_end_fact": "Фактический конец аренды",
    "rent_price": "Сумма аренды без учета скидок",
    "rent_price_discount": "Сумма аренды",
    "rent_price_inv": "Сумма инвентарей",
    "rent_price_service": "Сумма сервисов",
    "rent_discount": "Сумма скидок",
    "rent_discount_inv": "Сумма скидок инвентарей",
    "rent_discount_service": "Сумма скидок сервисов",
    "rent_penalty": "Сумма штрафа",
    "rent_created_by": "Автор аренды",
    "rent_created_at": "Дата создания",
    "rent_reserved_at": "Дата резервирования",
    "client_id": "ID",
    "client_name": "Имя",
    "client_phone": "Телефон",
    "client_email": "Email",
    "client_agreement_id": "Номер договора",
    "client_sign_date": "Дата подписания договора",
    "client_discount": "Персональная скидка",
    "client_type": "Тип клиента",
    "client_ind_iin": "ИИН",
    "client_ind_num": "Номер",
    "client_ind_date": "Дата выдачи",
    "client_ind_date_end": "Срок окончания",
    "client_ind_birth_date": "День рождение",
    "client_ind_issuer": "Выдан",
    "client_leg_bin": "БИН",
    "client_leg_address": "Адрес",
    "client_leg_director": "Директор",
    "client_leg_iban": "IBAN",
    "client_leg_bik": "БИК",
    "client_leg_bank": "Банк",
    "inv_index": "Индекс",
    "inv_id": "ID",
    "inv_name": "Название",
    "inv_unique_id": "Артикул",
    "inv_duration": "Длительнось",
    "inv_duration_fact": "Фактическая длительность",
    "inv_discount": "Сумма скидка",
    "inv_start": "Начало аренды",
    "inv_start_fact": "Фактическое начало аренды",
    "inv_end": "Конец аренды",
    "inv_end_fact": "Фактический конец аренды",
    "inv_price": "Сумма без учета скидки",
    "inv_price_discount": "Сумма",
    "inv_penalty": "Сумма штрафа",
    "inv_inventory_category": "Категория",
    "inv_inventory_buy_price": "Закупочная стоимость",
    "inv_inventory_buy_date": "Дата покупки",
    "inv_inventory_point_name": "Название точки проката",
    "inv_inventory_point_address": "Адрес точки проката",
    "service_index": "Индекс",
    "service_id": "ID",
    "service_name": "Название",
    "service_price": "Сумма без учета скидки",
    "service_price_discount": "Сумма",
    "service_extra_delivery_address": "Адрес доставки",
    "service_info": "Комментарии",
    "service_tarif_price": "Цена тарифа",
    "service_tarif_duration": "Длительность тарифа",
    "service_worker": "Исполнитель",
  }
}

Editor.create(document.getElementById('editor'))
export default Editor