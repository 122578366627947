import axios from "axios"
import {ApiAbstract} from "../../abstract/api.abstract"
import exportExcel from "src/utils/export/excel"

export class MetricsExportService extends ApiAbstract {
  constructor() {
    super("v1/crm/export/metrics")
  }

  public async uploadTransactions(filename: string, params: any): Promise<void> {
    const response = await axios.get(this.getUrl("transactions"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadEfficency(filename: string, params: any): Promise<void> {
    const response = await axios.get(this.getUrl("efficency"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadGroupEarning(filename: string, params: any): Promise<void> {
    const response = await axios.get(this.getUrl("earning/groups"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadInventoryEarning(filename: string, params: any): Promise<void> {
    const response = await axios.get(this.getUrl("earning/inventories"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadInventoryEarningDetail(filename: string, id: number, params: any): Promise<void> {
    const response = await axios.get(this.getUrl(`earning/inventories/${id}`), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadSalary(filename: string, params?: any): Promise<void> {
    const response = await axios.get(this.getUrl("efficency"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadUserSalary(filename: string, params?: any): Promise<void> {
    const response = await axios.get(this.getUrl("users/salaries"), {
      params,
      responseType: "blob"
    })
    exportExcel(filename, response)
  }

  public async uploadUserSalaryTypeEarningList(filename: string, id: number, params?: any): Promise<void> {
    const response = await axios.get(this.getUrl(`users/salaries/${id}/actions`), {params, responseType: "blob"})
    exportExcel(filename, response)
  }
}

const metricsExportService = new MetricsExportService()
export default metricsExportService
