import {ApiAbstract} from "../../abstract/api.abstract"
import {DiscountModel} from "../../models/manager/discount/discount.model"
import {BehaviorSubject, Observable, firstValueFrom, map, shareReplay, switchMap, tap, of} from "rxjs"
import axios from "axios"
import {getAPI} from "../../interceptor"
import authService from "../auth.service"
import {SettingConfig} from "../../models/setting-config"

class ManageDiscountService extends ApiAbstract {
  constructor() {
    super("v1/crm/discounts")
  }

  private loadDiscounts$: BehaviorSubject<void> = new BehaviorSubject(null)
  loadDiscounts = () => this.loadDiscounts$.next()

  discounts$: Observable<DiscountModel[]> = authService.logged$.pipe(
    switchMap((logged) =>
      logged ? this.loadDiscounts$.pipe(switchMap(() => getAPI<DiscountModel[]>(axios.get(this.getUrl())))) : of([])
    ),
    shareReplay(1)
  )

  discounts = {
    discount: this.discounts$.pipe(
      map((discounts) => discounts.filter((obj) => obj.type === 0 && obj.deleted === false))
    ),
    promocode: this.discounts$.pipe(
      map((discounts) => discounts.filter((obj) => obj.type === 1 && obj.deleted === false))
    )
  }

  get(id: number): Observable<DiscountModel> {
    return this.discounts$.pipe(map((discounts) => discounts.find((discount) => discount.id === id)))
  }

  post(payload: any): Promise<DiscountModel> {
    return firstValueFrom(getAPI(axios.post(this.getUrl(), payload)).pipe(tap(() => discountService.loadDiscounts())))
  }

  patch(id: number, payload: any): Promise<DiscountModel> {
    return firstValueFrom(
      getAPI(axios.patch(this.getUrl(id), payload)).pipe(tap(() => discountService.loadDiscounts()))
    )
  }

  delete(id: number): Promise<DiscountModel> {
    return firstValueFrom(getAPI(axios.delete(this.getUrl(id))).pipe(tap(() => discountService.loadDiscounts())))
  }
}

const discountService = new ManageDiscountService()
export default discountService
