import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import PlaceholderCommand from "./placeholdercommand";
import "./theme/placeholder.css";

import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from "@ckeditor/ckeditor5-widget/src/utils";


export default class PlaceholderEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add("placeholder", new PlaceholderCommand(this.editor));

    this.editor.editing.mapper.on(
      "viewToModelPosition",
      viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass("placeholder")),
    );

    this.editor.config.define("placeholderProps", {
      types: ["name", "date"],
    });

    this.editor.config.define("placeholderKeys", {});
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register("placeholder", {
      allowWhere: "$text",
      isInline: true,
      isObject: true,
      inheritAllFrom: '$inlineObject',
      allowAttributes: ["name", "data"],
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;
    const config = this.editor.config;

    conversion.for("upcast").elementToElement({
      view: {
        name: "span",
        classes: ["placeholder"],
        attributes: ["data"]
      },
      model: (viewElement, writer) => {
        let data = viewElement.getChild(0).parent.getAttribute('data')
        const modelWriter = writer.writer || writer;
        return modelWriter.createElement("placeholder", {data: data.replace(/[{} ]/g, '')});
      },
    });

    conversion.for("editingDowncast").elementToElement({
      model: "placeholder",
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        const widgetElement = createPlaceholderView(modelItem, viewWriter);
        return toWidget(widgetElement, viewWriter);
      },
    });

    conversion.for("dataDowncast").elementToElement({
      model: "placeholder",
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        return createPlaceholderView(modelItem, viewWriter)
      },
    });

    // Helper method for both downcast converters.
    function createPlaceholderView(modelItem, viewWriter) {
      const data = modelItem.getAttribute("data");
      const name = config.get("placeholderKeys")[data] || data;
      const placeholderView = viewWriter.createContainerElement("span", {class: "placeholder", data});
      const innerText = viewWriter.createText(name);
      
      viewWriter.insert(
        viewWriter.createPositionAt(placeholderView, 0),
        innerText,
      );

      return placeholderView;
    }
  }
}
