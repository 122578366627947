export interface Resource {
  id: number
  file: string
}

export enum OrderMediaType {
  BEFORE = 0,
  AFTER = 1
}

export interface OrderImageModel {
  id: number
  created_by: {
    id: number
    username: string
    avatar: string
    first_name: string
    last_name: string
    email: string
    is_staff: boolean
    is_sublease: boolean
    is_superuser: boolean
  }
  type: OrderMediaType
  request: number
  state: number
  created_at: string
  updated_at: string
  resources: Resource[]
}
