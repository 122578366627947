import {ImageField} from "src/components/shared/image-loader/image"
import introImage from "src/assets/images/onboarding-intro.png"
import modalService from "src/components/modal/global/modal.service"
import {useEffect, useState} from "react"
import onboardingService, {OnboadringState} from "src/services/checklist.service"
import {useTranslation} from "react-i18next"

export default function OnboardingIntroModal() {
  const {t} = useTranslation()
  const [state, setState] = useState<OnboadringState>(undefined)

  const onStart = () => {
    onboardingService.setState({...state, intro: true})
    modalService.closeModal()
  }

  useEffect(() => {
    const sub = onboardingService.state$.subscribe(setState)
    return () => sub.unsubscribe()
  }, [])

  return (
    <div className="flex flex-col items-center justify-center">
      <ImageField
        className="mb-4"
        src={introImage}
        draggable={false}
        style={{
          objectFit: "cover",
          width: "100%",
          height: 160,
          borderRadius: 8
        }}
      />

      <h4 className="text-2xl font-semibold mb-3">{t("onboarding.intro.header")}</h4>

      <div className="text-center text-[15px] font-normal mb-4" style={{lineHeight: "22px"}}>
        {t("onboarding.intro.message")}
      </div>

      <button className="btn btn-primary btn-color-white font-semibold px-5 text-center" onClick={onStart}>
        {t("onboarding.intro.start")}
      </button>
    </div>
  )
}
