import {FormControl} from "src/components/shared/inputs/form-control"
import {useState} from "react"
import {useFormContext} from "react-hook-form"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {SharedProfileSelect} from "src/components/shared/components/select/profile"

export default function InventoryEditSubleaseComponent({initial}: {initial: boolean}) {
  const {setValue} = useFormContext()
  const [sublease, setSublease] = useState(initial)
  const {t} = useTranslation()
  const toggle = () => setSublease(true)

  const onRemove = () => {
    setValue("sublease_user", null)
    setValue("sublease_percent", null)
    setSublease(false)
  }

  return (
    <>
      <div className="text-lg font-semibold my-3">{t("groups.form.sublease.title")}</div>

      {sublease ? (
        <div className="flex gap-3 w-50 items-end">
          <SharedProfileSelect
            className="col"
            label={t("groups.form.sublease.user")}
            placeholder={t("groups.form.sublease.placeholder.user")}
            name="sublease_user"
            menuPlacement="top"
            listParams={{is_sublease: true}}
            isClearable={true}
            isSearchable={false}
          />
          <FormControl
            rootclassname="col"
            className="form-control"
            name="sublease_percent"
            label={t("groups.form.sublease.percent")}
            placeholder={t("groups.form.sublease.placeholder.percent")}
            number_only={+true}
            number_decimal={+true}
            type="text"
            addonright={<>%</>}
            number_max={100}
          />
          <button className="btn btn-red btn-color-white" onClick={onRemove}>
            <Icon icon="delete" />
          </button>
        </div>
      ) : (
        <button onClick={toggle} style={{width: "max-content"}} className="btn btn-black-outline flex gap-1">
          <Icon icon="add" />
          {t("groups.form.sublease.add")}
        </button>
      )}
    </>
  )
}
