import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ClientLegalPassportModel} from "src/models/manager/clients/client-legal-passport.model"

class ManageClientLegalPassportService extends ApiAbstract {
  constructor() {
    super("v1/crm/clients")
  }
  public async get(clientId: number): Promise<ClientLegalPassportModel> {
    const response = await axios.get(this.getUrl(`${clientId}/passport/legal`), {
      params: {skip_loader: true, skip_error_handling: true}
    })
    return response.data
  }
  public async post(clientId: number, payload: any): Promise<ClientLegalPassportModel> {
    const response = await axios.post(this.getUrl(`${clientId}/passport/legal`), payload)
    return response.data
  }
  public async patch(clientId: number, payload: any): Promise<ClientLegalPassportModel> {
    const response = await axios.patch(this.getUrl(`${clientId}/passport/legal`), payload)
    return response.data
  }
  public async delete(clientId: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`${clientId}/passport/legal`))
    return response.data
  }
}

const clientLegalPassportService = new ManageClientLegalPassportService()
export default clientLegalPassportService
