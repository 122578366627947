import {InventoryTarifModel} from "src/models/manager/inventory/inventory-tarif.model"
import {toPrice} from "src/utils/price"
import {useTranslation} from "react-i18next"

export default function SaleInventoryGroupTarifList({
  tarifs,
  onSelect
}: {
  tarifs: InventoryTarifModel[]
  onSelect: (tarif: InventoryTarifModel) => void
}) {
  const {t} = useTranslation()

  return (
    <>
      <div className="text-2xl font-semibold mb-4">{t("sale.edit.inventories.modals.price_modal.choose_price")}</div>
      <div className="flex flex-wrap gap-3">
        {tarifs.map((tarif) => (
          <button key={tarif.id} className="btn btn-card" onClick={() => onSelect(tarif)}>
            {toPrice(tarif.price)}
          </button>
        ))}
      </div>
    </>
  )
}
