import {useContext, useEffect, useMemo, useState} from "react"
import widgetService from "src/services/widget.service"
import {ProductOrder, ProductResult} from "src/models/manager/website"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {FormProvider, useForm} from "react-hook-form"
import {ImageField} from "src/components/shared/image-loader/image"
import inventoryGroupService from "src/services/inventory/inventory-group.service"
import inventorySetService from "src/services/inventory/inventory-set.service"
import modalService from "src/components/modal/global/modal.service"
import {EMPTY_LIST, ListModel} from "src/models/common"
import Table from "src/components/shared/antd-custom/table"
import ModalContent from "./product-add"
import {ConfigContext} from "src/index"
import {useTranslation} from "react-i18next"
import {Link, useNavigate} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import {Dropdown, MenuProps, Tooltip} from "antd"
import {ColumnsType} from "antd/es/table"
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable"
import {DndContext, DragEndEvent} from "@dnd-kit/core"
import {restrictToVerticalAxis} from "@dnd-kit/modifiers"
import {FormControl} from "src/components/shared/inputs/form-control"
import servicesService from "src/services/services"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {SharedDiscountTypeSelect} from "src/components/shared/components/select/discount-type"
import SortableRow from "src/components/shared/antd-custom/table-sortable-row"
import {toPrice} from "src/utils/price"

interface FieldProps {
  product: ProductResult
  onUpdate: (itemId: number, type: "set" | "product" | "service", payload: any) => Promise<void>
}

function getService(type: "set" | "product" | "service") {
  switch (type) {
    case "set":
      return inventorySetService
    case "product":
      return inventoryGroupService
    case "service":
      return servicesService
  }
}

const PriceField = ({product, onUpdate}: FieldProps) => {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {price: product.price}})

  const {setValue, handleSubmit} = form

  const onSubmit = handleSubmit((payload) =>
    onUpdate(product.id, product.type, payload).catch(() => setValue("price", product.price))
  )

  return (
    <FormProvider {...form}>
      <FormControl
        name="price"
        placeholder={t("groups.detail.tarif.price_placeholder")}
        rootclassname="flex-1"
        className="form-control"
        number_only={+true}
        params={{required: true}}
        onBlurCapture={onSubmit}
      />
    </FormProvider>
  )
}

const DiscountField = ({product, onUpdate}: FieldProps) => {
  const form = useForm({defaultValues: {discount: product.discount}})

  const {setValue, handleSubmit} = form

  const onSubmit = handleSubmit((payload) =>
    onUpdate(product.id, product.type, payload).catch(() => setValue("discount", product.discount))
  )

  return (
    <FormProvider {...form}>
      <SharedDiscountTypeSelect
        className="col"
        name="discount"
        type="discount"
        isClearable={true}
        onChange={onSubmit}
        menuPortalTarget={document.body}
      />
    </FormProvider>
  )
}

const CategoryField = ({product, onUpdate}: FieldProps) => {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {category: product.category}})
  const {setValue, handleSubmit} = form

  const onSubmit = handleSubmit((payload) =>
    onUpdate(product.id, product.type, payload).catch(() => setValue("category", product.category))
  )

  return (
    <FormProvider {...form}>
      <SharedCategorySelect
        className="flex-1"
        placeholder={t("common.select.placeholder.category")}
        name="category"
        params={{required: true}}
        required={true}
        isClearable={false}
        isSearchable={false}
        onChange={onSubmit}
        menuPortalTarget={document.body}
      />
    </FormProvider>
  )
}

const TarifsListField = ({product}: {product: ProductResult}) => {
  const link = useMemo(() => {
    switch (product.type) {
      case "product":
        return `/groups/${product.group_id}/tarifs`
      case "set":
        return `/inventory-sets/${product.set_id}`
      case "service":
        return `/services`
    }
  }, [product])
  return (
    <div className="flex gap-2 items-center">
      <div className="flex flex-col gap-1 flex-1">
        {product.tariffs.map((tariff) => (
          <div className="flex justify-between gap-1" key={tariff.id}>
            <span className="font-medium">{tariff.period}</span>
            <span className="font-medium">{toPrice(tariff.price)}</span>
          </div>
        ))}
      </div>
      <Link to={link} className="font-medium">
        <Icon icon="edit" />
      </Link>
    </div>
  )
}

const ProductPublishedList = () => {
  const {integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const [ordering, setOrdering] = useState<Record<string, ProductOrder>>({})
  const [list, setList] = useState<ListModel<ProductResult>>(EMPTY_LIST)
  const form = useForm<any>({defaultValues: {page: 1, pageSize: 10}})
  const {setValue, watch} = form
  const values = watch()
  const integration = integrationMap["widget"]
  const navigate = useNavigate()

  const getProduct = async (params = {page: values.page, pageSize: values.pageSize}) => {
    widgetService
      .list(params)
      .then(setList)
      .catch((e) => e.response?.status === 404 && setValue("page", 1))
  }

  const updateProduct = async (itemId: number, type: "set" | "product" | "service", payload: any) => {
    if (itemId === undefined) return Promise.reject()
    return getService(type)
      .patch(itemId, payload, {skip_loader: true})
      .then(() => {})
  }

  const checkAndUpdateProduct = (product: any, remove: boolean) => {
    if (product.set_id) return updateProduct(product.set_id, "set", {published: remove})
    if (product.group_id) return updateProduct(product.group_id, "product", {published: remove})
    if (product.service_id) return updateProduct(product.service_id, "service", {published: remove})
  }

  const onShowModal = (type: "set" | "product" | "service") => {
    modalService.open({
      props: {size: "lg"},
      component: <ModalContent service={getService(type)} getProduct={getProduct} />
    })
  }

  const onNavigate = (product: ProductResult, ...args) => {
    switch (product.type) {
      case "product":
        navigate(`/groups/${product.group_id}/edit`)
        return
      case "set":
        navigate(`/inventory-sets/${product.set_id}`)
        return
      case "service":
        navigate(`/services`)
        return
    }
  }

  const columns: ColumnsType<ProductResult> = [
    {
      width: 48,
      key: "sort"
    },
    {
      width: "30%",
      ellipsis: true,
      title: "Продукты",
      key: "image",
      className: "p-2",
      render: (_, product) => (
        <div className="flex gap-1 items-center justify-between">
          <div className="flex gap-2 items-center overflow-hidden">
            <ImageField className="w-12 h-12" src={product.image} />
            <div className="font-medium">{product.name}</div>
          </div>
          <Icon icon="edit" onClick={() => onNavigate(product)} />
        </div>
      )
    },
    {
      width: 120,
      title: "Доступно",
      key: "amount",
      dataIndex: "amount",
      className: "p-2",
      render: (amount) =>
        amount !== 0 ? (
          <span className="font-medium">{amount}</span>
        ) : (
          <Tooltip
            title={
              <div className="flex gap-2 flex-col">
                <span className="text-center">
                  Данный товар не доступен для заказа, инвентари сломаны или не существуют
                </span>
              </div>
            }
            zIndex={10001}>
            <span className="font-medium text-alert">{amount}</span>
          </Tooltip>
        )
    },
    {
      width: 240,
      title: "Тарифы",
      key: "discount",
      className: "p-2",
      render: (product) => <TarifsListField product={product} />
    },
    {
      width: 160,
      title: "Категория",
      key: "category",
      className: "p-2",
      render: (product) => <CategoryField product={product} onUpdate={updateProduct} />
    },
    {
      width: 160,
      title: "Цена на сайте",
      key: "image",
      className: "p-2",
      render: (product) => <PriceField product={product} onUpdate={updateProduct} />
    },
    {
      width: 240,
      title: "Скидка",
      key: "discount",
      className: "p-2",
      render: (product) => <DiscountField product={product} onUpdate={updateProduct} />
    }
  ]

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (!over || !over.id) return
    if (!ordering[active.id]) return
    if (!ordering[over.id]) return
    if (!values.page) return
    if (!values.pageSize) return

    if (active.id !== over.id) {
      setList((previous) => {
        const activeIndex = previous.results.findIndex((i) => `${i.type}_${i.id}` === active.id)
        const overIndex = previous.results.findIndex((i) => `${i.type}_${i.id}` === over.id)

        widgetService.patchOrder(ordering[active.id].id as number, {
          order: (values.page - 1) * values.pageSize + ordering[over.id].order
        })
        return {
          ...previous,
          results: arrayMove(previous.results, activeIndex, overIndex)
        }
      })
    }
  }

  const items: MenuProps["items"] = [
    {
      key: "group",
      onClick: () => onShowModal("product"),
      label: (
        <div className="flex gap-2">
          <Icon icon="inventory_2" /> {t("website.widget.add_group")}
        </div>
      )
    },
    {
      key: "set",
      onClick: () => onShowModal("set"),
      label: (
        <div className="flex gap-2">
          <Icon icon="dataset" /> {t("website.widget.add_set")}
        </div>
      )
    },
    {
      key: "service",
      onClick: () => onShowModal("service"),
      label: (
        <div className="flex gap-2">
          <Icon icon="design_services" /> {t("website.widget.add_service")}
        </div>
      )
    }
  ]

  useEffect(() => {
    getProduct()
  }, [])

  useEffect(() => {
    const subscription = watch((values) => {
      getProduct({page: values.page, pageSize: values.pageSize})
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    const sub = widgetService.ordering$.subscribe(setOrdering)
    return () => sub.unsubscribe()
  }, [])

  return (
    integration && (
      <FormProvider {...form}>
        <div className="flex flex-col gap-3">
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={list.results.map((obj) => ({
                ...obj,
                id: `${obj.type}_${obj.id}`
              }))}
              strategy={verticalListSortingStrategy}>
              <Table
                title={() => (
                  <div className="flex gap-2 justify-between">
                    <SharedCategorySelect
                      name="category"
                      className="min-w-56 max-w-64"
                      placeholder={t("common.select.category")}
                      isClearable={true}
                      menuPortalTarget={document.body}
                      isSearchable={false}
                    />
                    <Dropdown menu={{items}} placement="bottomLeft" trigger={["click", "click"]} arrow={false}>
                      <button className="btn btn-primary btn-color-white font-medium gap-2 text-nowrap">
                        <Icon icon="add" /> {t("common.add")}
                      </button>
                    </Dropdown>
                  </div>
                )}
                rowKey={(row) => `${row.type}_${row.id}`}
                columns={columns}
                dataSource={list.results}
                components={{body: {row: SortableRow}}}
                onChange={(pagination) => setValue("page", pagination.current)}
                onDelete={(data) =>
                  checkAndUpdateProduct(data, false).then(() =>
                    getProduct({page: values.page, pageSize: values.pageSize})
                  )
                }
              />
            </SortableContext>
          </DndContext>
          <PaginationFormComponent count={list.count} />
        </div>
      </FormProvider>
    )
  )
}

export default ProductPublishedList
