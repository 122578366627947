import noImage from "src/assets/images/camera-icon.svg"
import {useEffect, useState} from "react"
import clsx from "clsx"

export function ImageField(props: React.ImgHTMLAttributes<HTMLImageElement> & {fallbackimage?: string}) {
  const [imgSrc, setImgSrc] = useState<string | undefined>("")
  const fallbackImage = props.fallbackimage || noImage
  const onError = () => setImgSrc(fallbackImage)

  useEffect(() => setImgSrc(props.src), [props.src])

  return (
    <img
      {...props}
      draggable={false}
      className={clsx("pointer-events-none", props.className)}
      style={{objectFit: "contain", ...props.style}}
      src={imgSrc ? imgSrc : fallbackImage}
      onError={onError}
    />
  )
}
