import {InputHTMLAttributes, useContext, useState} from "react"
import {Controller, FieldError, RegisterOptions, useFormContext} from "react-hook-form"
import {DatePicker} from "../antd-custom"
import moment from "moment"
import clsx from "clsx"
import {ConfigContext} from "src"
import InputMask from "react-input-mask"
import {isNil} from "src/utils/isNil"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlDate(config: FormControlConfig) {
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors}
  } = useFormContext()
  const error = errors[config.name]
  const {isMobile} = useContext(ConfigContext)
  const className = `${config.className ? config.className : ""} ${error ? "invalid" : ""} w-full`
  const value = watch(config.name) ? moment(watch(config.name)) : null
  const [val, setVal] = useState<string>(watch(config.name) ? moment(watch(config.name)).format("DD-MM-YYYY") : null)

  const onChange = (date: moment.Moment | string) => {
    if (isNil(date)) {
      setVal(null)
      setValue(config.name, null, {shouldDirty: true, shouldTouch: true})
      clearErrors(config.name)
      return
    }

    if (typeof date === "string") {
      setVal(date)
      const dateMoment = moment(date, "DD-MM-YYYY", true)
      const isValid = dateMoment.isValid()
      if (isValid) {
        setValue(config.name, dateMoment.format("YYYY-MM-DD"), {
          shouldDirty: true,
          shouldTouch: true
        })
        clearErrors(config.name)
      } else {
        setError(config.name, {message: "Неправильный формат даты"})
      }
    } else if (date && moment.isMoment(date)) {
      setValue(config.name, date.format("YYYY-MM-DD"), {
        shouldDirty: true,
        shouldTouch: true
      })
    }
  }

  return (
    <div className={clsx("w-full", config.rootclassname)}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      {isMobile ? (
        // <input
        //   type="date"
        //   {...register(config.name, config.params)}
        //   value={value ? value.format("YYYY-MM-DD") : undefined}
        //   className={clsx(className, "block")}
        //   disabled={config.disabled}
        //   placeholder={config.placeholder || ""}
        // />
        <Controller
          name={config.name}
          rules={config.params}
          render={({fieldState}) => (
            <>
              <InputMask
                mask="99-99-9999"
                placeholder="DD-MM-YYYY"
                // placeholder={config.placeholder || "DD-MM-YYYY"}
                value={val}
                className={clsx(className, "block", fieldState.error && "border border-alert")}
                disabled={config.disabled}
                onChange={(e) => onChange(e.target.value)}
                autoComplete="off"
              />
              {fieldState.error && (
                <span className="text-alert text-sm">{(errors[config.name] as FieldError)?.message}</span>
              )}
            </>
          )}
        />
      ) : (
        <DatePicker
          {...register(config.name, config.params)}
          format="DD-MM-YYYY"
          value={value}
          className={className}
          onChange={onChange}
          disabled={config.disabled}
          placeholder={config.placeholder || "Выберите дату"}
          inputReadOnly={true}
          onBlur={(e) => {
            const value = (e.target as any).value
            const date = moment(value, "DD-MM-YYYY")
            if (value === "") {
              onChange(null)
              return
            }
            if (date.isValid()) onChange(date)
          }}
        />
      )}
    </div>
  )
}
