import {useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import modalService from "src/components/modal/global/modal.service"
import {
  InventoryMaintenanceModel,
  MaintenancePeriodType,
  MaintenanceType
} from "src/models/manager/maintenance/maintenance.model"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"
import {daysToTimeString} from "src/utils/daysToTimeString"
import OneTimeMaintenanceFields from "./details/maintenance-conditions/OneTimeMaintenanceFields"
import PeriodicMaintenanceFields from "./details/maintenance-conditions/PeriodicMaintenanceFields"
import DoNowOrPlanSelector from "./details/maintenance-conditions/DoNowOrPlanSelector"
import DateOrDistanceSelector from "./details/maintenance-conditions/DateOrDistanceSelector"
import MaintenanceTypeSelector from "./details/maintenance-conditions/MaintenanceTypeSelector"

interface MaintenanceForm {
  id: number
  name: string
  state: number
  type: MaintenanceType
  interval: string
  period_type?: MaintenancePeriodType
  description?: string
  comment?: string
  maintenance_date: string
  time_threshold: string
  notify_distance: number
  last_request: number
  responsible: number
  total_distance: number
  interval_distance: number
}

export default function MaintenanceModal({
  inventory,
  onSubmit
}: {
  inventory: InventoryMaintenanceModel
  onSubmit: (payload: MaintenanceForm) => Promise<any>
}) {
  const [doToday, setDoToday] = useState(false)
  const [selectedType, setSelectedType] = useState("date")
  const {t} = useTranslation()

  const form = useForm<MaintenanceForm>({defaultValues: {type: MaintenanceType.ONETIME}})
  const {reset, setValue, watch, getValues} = form
  const values = getValues()

  const handleSubmit = form.handleSubmit((data) => {
    const updatedData = {
      ...data,
      ...(data.type === MaintenanceType.PERIODIC && {interval: daysToTimeString(Number(data.interval))}),
      ...(doToday && {maintenance_date: null}),
      time_threshold: daysToTimeString(Number(data.time_threshold || 0))
    }
    onSubmit(updatedData).then(() => modalService.closeAllModal())
  })

  const maintenanceType = watch("type")

  const periodTypes = [
    {
      label: t("maintenance.maintenance_types.one_time"),
      value: MaintenanceType.ONETIME,
      hint: t("maintenance.one_time_maintenance")
    },
    {
      label: t("maintenance.maintenance_types.periodic"),
      value: MaintenanceType.PERIODIC,
      hint: t("maintenance.periodic_maintenance")
    }
  ]

  const doTypes = [
    {label: t("maintenance.maintenance_do.now"), value: true},
    {label: t("maintenance.maintenance_do.plan"), value: false}
  ]

  const options = [
    {label: t("maintenance.by_date"), value: "date"},
    {label: t("maintenance.by_mileage"), value: "distance"}
  ]

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-6">
        <div className="font-semibold text-2xl text-test-black">
          {inventory.name} ({inventory.unique_id})
        </div>

        <MaintenanceTypeSelector
          value={maintenanceType}
          onChange={(val) => reset({...values, type: val})}
          options={periodTypes}
        />

        {maintenanceType === MaintenanceType.ONETIME && (
          <DoNowOrPlanSelector
            value={doToday}
            onChange={(val) => {
              setDoToday(val)
              if (val) setValue("maintenance_date", null)
              reset(values)
            }}
            options={doTypes}
          />
        )}

        <DateOrDistanceSelector
          selectedType={selectedType}
          onChange={(val) => {
            setSelectedType(val.value)
            if (val.value === "distance") setValue("total_distance", inventory.extra?.total_distance)
          }}
          options={options}
          maintenanceType={maintenanceType}
          doToday={doToday}
        />

        {maintenanceType === MaintenanceType.ONETIME ? (
          <OneTimeMaintenanceFields doToday={doToday} selectedType={selectedType} />
        ) : (
          <PeriodicMaintenanceFields selectedType={selectedType} />
        )}

        <FormControlTextarea
          name="description"
          label={t("common.input.textarea_label")}
          rootclassname="w-full"
          className="form-control cursor-text h-[187px] placeholder:text-gray-test-350"
          placeholder={t("groups.inventories.form.comment_placeholder")}
          required={false}
        />

        <div className="flex justify-end gap-4">
          <button className="btn btn-card font-medium bg-gray-test-70" onClick={() => modalService.closeModal()}>
            {t("common.back")}
          </button>
          <button
            disabled={!form.formState.isValid}
            className="btn btn-primary btn-color-white font-medium"
            onClick={handleSubmit}>
            {t("common.save")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
