import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router-dom"
import {Segmented} from "src/components/shared/antd-custom"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {useContext, useState} from "react"
import {ConfigContext} from "../../../index"
import Icon from "src/components/shared/components/material-icon"

export default function ReportSellsLayout() {
  const navigate = useNavigate()
  const page = useCurrentRoute()
  const {t} = useTranslation()
  const {isMobile} = useContext(ConfigContext)
  const [onFilter, setFilter] = useState<() => void>()

  return (
    <>
      <div className="w-[calc(100%_+_32px)] flex items-center md:w-fit px-4 py-2 md:px-0 md:py-0 gap-2 bg-white md:bg-transparent -mx-4 -mt-4 md:m-0 md:mb-3 border-b border-gray-100 md:border-none">
        <Segmented
          value={page}
          className="flex w-full md:w-fit bg-gray-100 rounded-md"
          onChange={(option) => navigate(option)}
          style={{
            background: "var(--color-bg-light)",
            padding: 4,
            borderRadius: 6
          }}
          options={[
            {
              label: t("metrics.finance.pages.main"),
              value: "main",
              className: "md:p-1 font-medium text-black md:h-auto text-center"
            },
            {
              label: t("metrics.finance.pages.penalties"),
              value: "penalties",
              className: "md:p-1 font-medium text-black md:h-auto text-center"
            }
          ]}
          {...(isMobile && {block: true})}
        />
        {isMobile && onFilter && (
          <button className="btn btn-card p-2" onClick={onFilter}>
            <Icon icon="filter_list" />
          </button>
        )}
      </div>

      <Outlet context={{setFilter}} />
    </>
  )
}
