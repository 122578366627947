import moment from "moment"
import {InputHTMLAttributes, useMemo} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import Select from "react-select"
import {colourStyles} from "./form-select-color-style"
import {shortHumanizer} from "src/utils/duriation"
import CustomSelect from "./select/select"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

const dayOptions = Array(31)
  .fill(0)
  .map((_, value) => ({
    value,
    label: value !== 0 ? shortHumanizer("ru")(moment.duration(value, "days")) : 0
  }))
const hourOptions = Array(24)
  .fill(0)
  .map((_, value) => ({
    value,
    label: value !== 0 ? shortHumanizer("ru")(moment.duration(value, "hours")) : 0
  }))
const minuteOptions = Array(60)
  .fill(0)
  .map((_, value) => ({
    value,
    label: value !== 0 ? shortHumanizer("ru")(moment.duration(value, "minutes")) : 0
  }))

export function FormControlDuration(config: FormControlConfig) {
  const {setValue, watch} = useFormContext()
  const value = moment.duration(watch(config.name))

  const days = +useMemo(() => value.days(), [value])
  const hours = +useMemo(() => value.hours(), [value])
  const minutes = +useMemo(() => value.minutes(), [value])

  const handleSubmit = (days: number, hour: number, minute: number) => {
    // const val = moment.duration(`${days} ${hour}:${minute}:00`)
    // const valFormated = `${Math.max(val.days(), 0)} ${locate(val.days() <= 0 ? Math.max(val.hours(), 1) : val.hours())}:${locate(val.minutes())}:${locate(val.seconds())}`
    setValue(config.name, `${days} ${hour}:${minute}:00`, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true
    })
  }

  return (
    <div className={config.rootclassname || ""}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="flex gap-6 w-full">
        <CustomSelect
          className="col"
          rootclassname="w-full"
          isClearable={false}
          isSearchable={false}
          styles={{
            ...colourStyles(false)
          }}
          value={{
            value: days,
            label: days !== 0 ? shortHumanizer("ru")(moment.duration(days, "days")) : 0
          }}
          options={dayOptions}
          onChange={(val: any) => handleSubmit(val.value, hours, minutes)}
          required
          label="Кол-во Дней"
        />
        <CustomSelect
          className="col"
          rootclassname="w-full"
          isClearable={false}
          isSearchable={false}
          styles={{
            ...colourStyles(false)
          }}
          value={{
            value: hours,
            label: hours !== 0 ? shortHumanizer("ru")(moment.duration(hours, "hours")) : 0
          }}
          options={hourOptions}
          onChange={(val: any) => handleSubmit(days, val.value, minutes)}
          required
          label="Кол-во Часов"
        />
        <CustomSelect
          className="col"
          rootclassname="w-full"
          isClearable={false}
          isSearchable={false}
          styles={{
            ...colourStyles(false)
          }}
          value={{
            value: minutes,
            label: minutes !== 0 ? shortHumanizer("ru")(moment.duration(minutes, "minutes")) : 0
          }}
          options={minuteOptions}
          onChange={(val: any) => handleSubmit(days, hours, val.value)}
          required
          label="Кол-во Минут"
        />
      </div>
    </div>
  )
}
