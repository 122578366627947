import {ApiAbstract} from "src/abstract/api.abstract"
import loadingService from "./loading.service"

const getHTML = (content: string) => `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="theme-color" content="#000000">
    <meta name="title" content="Учет и автоматизация аренды">
    <meta name="keywords" content="Камеры, Аренда, Аренда продукта, Cъемка">
    <meta name="description" content="Учет и автоматизация аренды">
    <style type="text/css">
        table { page-break-inside:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        thead { display:table-row-group }
        tfoot { display:table-footer-group }
        
        body {
            z-index: 100 !important;
            visibility: visible !important;
            position: relative !important;
            display: block !important;
            position: relative !important;
            padding: 0 !important;
            top: 0 !important;
            left: 0 !important;
            margin: auto !important;
            orphans: 0 !important;
            widows: 0!important;
            overflow: visible !important;
        }

      .ck-header {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: center;
          margin: 16px auto;
      }

      .ck-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          background-color: #A165FD;
          box-shadow: var(--shadow-md);
          padding: 16px;
          border-radius: 8px;
          cursor: pointer;
          color: #fff;
          width: max-content;
      }

      .ck-border {
          border: 1px solid #EDEEF0;
          box-shadow: var(--shadow-md);
          border-radius: 12px;
          padding: 24px 32px;
          margin: 16px auto;
      }

      .ck-content .m-0 {
          margin: 0 !important;
      }

      .ck-content .m-0 {
          padding: 0 !important;
      }

      .ck-content .table>figcaption {
          display: table-caption;
          caption-side: top;
          word-break: break-word;
          text-align: center;
          color: var(--ck-color-table-caption-text);
          background-color: var(--ck-color-table-caption-background);
          padding: .6em;
          font-size: .75em;
          outline-offset: -1px;
      }

      .ck-content .table {
          margin: 4px auto;
          display: table;
      }

      .ck-content .table table {
          border-collapse: collapse;
          border-spacing: 0;
          border: 1px double transparent;
      }

      .ck-content .table table td,
      .ck-content .table table th {
          min-width: 2em;
          padding: .4em;
          border: 1px solid transparent;
      }

      .ck-content .table table th {
          font-weight: bold;
          background: #0000000d;
      }

      .ck-content[dir="rtl"] .table th {
          text-align: right;
      }

      .ck-content[dir="ltr"] .table th {
          text-align: left;
      }

      .ck-content .table .ck-table-resized {
          width: 100% !important;
          table-layout: fixed;
      }

      .ck-content .table table {
          width: 100% !important;
          table-layout: fixed;
          overflow: hidden;
      }

      .ck-content .table td,
      .ck-content .table th {
          overflow-wrap: break-word;
          position: relative;
      }

      .ck-content *:not(.table *) {
          overflow-x: scroll;
          max-width: 100vw !important;
      }

      .ck-content .media {
          clear: both;
          margin: 0.9em 0;
          display: block;
          min-width: 15em;
      }

      .ck-content .todo-list {
          list-style: none;
      }

      .ck-content .todo-list li {
          margin-bottom: 5px;
      }

      .ck-content .todo-list li .todo-list {
          margin-top: 5px;
      }

      .ck-content .todo-list .todo-list__label>input {
          -webkit-appearance: none;
          display: inline-block;
          position: relative;
          width: var(--ck-todo-list-checkmark-size);
          height: var(--ck-todo-list-checkmark-size);
          vertical-align: middle;
          border: 0;
          left: -25px;
          margin-right: -15px;
          right: 0;
          margin-left: 0;
      }

      .ck-content .todo-list .todo-list__label>input::before {
          display: block;
          position: absolute;
          box-sizing: border-box;
          content: "";
          width: 100%;
          height: 100%;
          border: 1px solid #333333;
          border-radius: 2px;
          transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
      }

      .ck-content .todo-list .todo-list__label>input::after {
          display: block;
          position: absolute;
          box-sizing: content-box;
          pointer-events: none;
          content: "";
          left: calc(var(--ck-todo-list-checkmark-size) / 3);
          top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
          border-style: solid;
          border-color: transparent;
          border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
          transform: rotate(45deg);
      }

      .ck-content .todo-list .todo-list__label>input[checked]::before {
          background: #26ab33;
          border-color: #26ab33;
      }

      .ck-content .todo-list .todo-list__label>input[checked]::after {
          border-color: #ffffff;
      }

      .ck-content .todo-list .todo-list__label .todo-list__label__description {
          vertical-align: middle;
      }

      .ck-content .image {
          display: table;
          clear: both;
          text-align: center;
          margin: 0.9em auto;
          min-width: 50px;
      }

      .ck-content .image img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          min-width: 100%;
      }

      .ck-content .image-inline {
          display: inline-flex;
          max-width: 100%;
          align-items: flex-start;
      }

      .ck-content .image-inline picture {
          display: flex;
      }

      .ck-content .image-inline picture,
      .ck-content .image-inline img {
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 100%;
      }

      .ck-content .image.image_resized {
          max-width: 100%;
          display: block;
          box-sizing: border-box;
      }

      .ck-content .image.image_resized img {
          width: 100%;
      }

      .ck-content .image.image_resized>figcaption {
          display: block;
      }

      .ck-content .image>figcaption {
          display: table-caption;
          caption-side: bottom;
          word-break: break-word;
          color: var(--ck-color-image-caption-text);
          background-color: var(--ck-color-image-caption-background);
          padding: .6em;
          font-size: .75em;
          outline-offset: -1px;
      }

      .ck-content .marker-yellow {
          background-color: var(--ck-highlight-marker-yellow);
      }

      .ck-content .marker-green {
          background-color: var(--ck-highlight-marker-green);
      }

      .ck-content .marker-pink {
          background-color: var(--ck-highlight-marker-pink);
      }

      .ck-content .marker-blue {
          background-color: var(--ck-highlight-marker-blue);
      }

      .ck-content .pen-red {
          color: var(--ck-highlight-pen-red);
          background-color: transparent;
      }

      .ck-content .pen-green {
          color: var(--ck-highlight-pen-green);
          background-color: transparent;
      }

      .ck-content ol {
          list-style-type: decimal;
      }

      .ck-content ol ol {
          list-style-type: lower-latin;
      }

      .ck-content ol ol ol {
          list-style-type: lower-roman;
      }

      .ck-content ol ol ol ol {
          list-style-type: upper-latin;
      }

      .ck-content ol ol ol ol ol {
          list-style-type: upper-roman;
      }

      .ck-content ul {
          list-style-type: disc;
      }

      .ck-content ul ul {
          list-style-type: circle;
      }

      .ck-content ul ul ul {
          list-style-type: square;
      }

      .ck-content ul ul ul ul {
          list-style-type: square;
      }

      .ck-content .image-style-block-align-left,
      .ck-content .image-style-block-align-right {
          max-width: calc(100% - var(--ck-image-style-spacing));
      }

      .ck-content .image-style-align-left,
      .ck-content .image-style-align-right {
          clear: none;
      }

      .ck-content .image-style-side {
          float: right;
          margin-left: var(--ck-image-style-spacing);
          max-width: 50%;
      }

      .ck-content .image-style-align-left {
          float: left;
          margin-right: var(--ck-image-style-spacing);
      }

      .ck-content .image-style-align-center {
          margin-left: auto;
          margin-right: auto;
      }

      .ck-content .image-style-align-right {
          float: right;
          margin-left: var(--ck-image-style-spacing);
      }

      .ck-content .image-style-block-align-right {
          margin-right: 0;
          margin-left: auto;
      }

      .ck-content .image-style-block-align-left {
          margin-left: 0;
          margin-right: auto;
      }

      .ck-content p+.image-style-align-left,
      .ck-content p+.image-style-align-right,
      .ck-content p+.image-style-side {
          margin-top: 0;
      }

      .ck-content .image-inline.image-style-align-left,
      .ck-content .image-inline.image-style-align-right {
          margin-top: var(--ck-inline-image-style-spacing);
          margin-bottom: var(--ck-inline-image-style-spacing);
      }

      .ck-content .image-inline.image-style-align-left {
          margin-right: var(--ck-inline-image-style-spacing);
      }

      .ck-content .image-inline.image-style-align-right {
          margin-left: var(--ck-inline-image-style-spacing);
      }

      .ck-content code {
          background-color: #c7c7c74d;
          padding: .15em;
          border-radius: 2px;
      }

      .ck-content blockquote {
          overflow: hidden;
          padding-right: 1.5em;
          padding-left: 1.5em;
          margin-left: 0;
          margin-right: 0;
          font-style: italic;
          border-left: solid 5px #cccccc;
      }

      .ck-content[dir="rtl"] blockquote {
          border-left: 0;
          border-right: solid 5px #cccccc;
      }

      .ck-content .text-tiny {
          font-size: .7em;
      }

      .ck-content .text-small {
          font-size: .85em;
      }

      .ck-content .text-big {
          font-size: 1.4em;
      }

      .ck-content .text-huge {
          font-size: 1.8em;
      }

      .ck-content .mention {
          background: var(--ck-color-mention-background);
          color: var(--ck-color-mention-text);
      }

      .ck-content hr {
          margin: 15px 0;
          height: 4px;
          background: #dedede;
          border: 0;
      }

      .ck-content pre {
          padding: 1em;
          color: #353535;
          background: #c7c7c74d;
          border: 1px solid #c4c4c4;
          border-radius: 2px;
          text-align: left;
          direction: ltr;
          tab-size: 4;
          white-space: pre-wrap;
          font-style: normal;
          min-width: 200px;
      }

      .ck-content pre code {
          background: unset;
          padding: 0;
          border-radius: 0;
      }
    </style>
  </head>
  <body>
    <div class="ck-content">${content}</div>
  </body>
</html>
`

interface PageProperties {
  singlePage: boolean
  paperWidth: number | string
  paperHeight: number | string
  marginTop: number | string
  marginBottom: number | string
  marginLeft: number | string
  marginRight: number | string
  preferCssPageSize: boolean
  printBackground: boolean
  omitBackground: boolean
  landscape: boolean
  scale: number
  nativePageRanges: string
}

const defaultProperties: PageProperties = {
  singlePage: false,
  paperWidth: 8.3,
  paperHeight: 11.7,
  marginTop: 1,
  marginBottom: 0.4,
  marginLeft: 0.3,
  marginRight: 0.3,
  preferCssPageSize: false,
  printBackground: false,
  omitBackground: false,
  landscape: false,
  scale: 1.0,
  nativePageRanges: ""
}

class PdfService extends ApiAbstract {
  constructor() {
    super("v1/documents")
  }

  async generate(
    filename: string,
    content: string,
    onDownload: (filename: string, file: Blob) => Promise<void> = async (filename: string, file: Blob) =>
      await this.download(filename, file),
    properties?: Partial<PageProperties>
  ): Promise<void> {
    const _properties = {...defaultProperties, ...properties}
    const formData = new FormData()
    const contentBlob = new Blob([getHTML(content)], {type: "text/html"})
    formData.append("files", contentBlob, "index.html")
    formData.append("skipNetworkIdleEvent", "true")
    formData.append("emulatedMediaType", "print")
    Object.keys(_properties).forEach((key) => formData.append(key, String(_properties[key])))

    loadingService.setLoading(filename)
    const response = await fetch("https://docs.api.yume.cloud/forms/chromium/convert/html", {
      method: "POST",
      body: formData
    })

    const file = await response.blob()
    await onDownload(filename, file)
    loadingService.removeLoading(filename)
  }

  async print(filename: string, file: Blob): Promise<void> {
    var blobURL = URL.createObjectURL(file)

    const iframe = document.createElement("iframe")
    document.body.appendChild(iframe)

    iframe.style.display = "none"
    iframe.src = blobURL
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus()
        iframe.contentWindow.print()
      }, 1)
    }
  }

  async download(filename: string, file: Blob, extension = ".pdf"): Promise<void> {
    const href = URL.createObjectURL(file)
    const link = document.createElement("a")
    link.href = href
    link.setAttribute("download", `${filename}${extension}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
}

const pdfService = new PdfService()
export default pdfService
