import {useContext, useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"
import {ConfigContext} from "src"
import HeaderContent from "src/components/header/header-content"
import {SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {ProductType, SettingConfig} from "src/models/setting-config"

export default function SettingsComponent() {
  const form = useForm<SettingConfig>({
    defaultValues: {
      rent_product_types: [ProductType.GROUP, ProductType.INVENTORTY, ProductType.SALE]
    }
  })
  const {settings, integrationMap} = useContext(ConfigContext)
  const {t} = useTranslation()

  useEffect(() => {
    if (!settings) return
    form.reset({
      ...settings,
      language: (localStorage.getItem("language") as any) || settings.language
    })
    return () => {}
  }, [form, settings])

  const menus = useMemo(() => {
    let list = [
      {link: "/settings/main", label: t("settings.sections.main")},
      {link: "/settings/rent", label: t("settings.sections.rent")},
      {
        link: "/settings/order-status",
        label: t("settings.sections.order-status")
      },
      {
        link: "/settings/inventory-state",
        label: t("settings.sections.inventory-state")
      },
      {link: "/settings/bonus", label: t("settings.sections.bonuses")},
      {link: "/settings/managers", label: t("settings.sections.managers")},
      {
        link: "/settings/custom-fields",
        label: t("settings.sections.custom-fields")
      }
    ]
    // if (integrationMap["wazzup"] && integrationMap["wazzup"].connected && !integrationMap["wazzup"].disabled) {
    //   list.push({link: "/settings/wazzup", label: "Wazzup"})
    // }
    return list
  }, [])

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t("settings.header")}</div>
      </HeaderContent>

      <SidebarWrapper options={[{menus: menus}]}>
        <Outlet />
      </SidebarWrapper>
    </FormProvider>
  )
}
