import {DetailOrderRequestServiceModel} from "src/models/manager/order/order-request-service"
import {OrderRequestServiceForm} from "../context/order-context.model"

interface ComparisonModel {
  id: number
  service: number
  tarif: number
  worker: number
  info: string
  start_at: string
  end_at: string
  extra: {
    delivery_address: string
    back_delivery_address: string
  }
}

export default function detectServiceChange(
  prev: DetailOrderRequestServiceModel[],
  curr: OrderRequestServiceForm[]
): ComparisonModel[] {
  const prevFiltered: ComparisonModel[] = prev.map((obj) => ({
    id: obj.id,
    service: obj.service.id,
    tarif: obj.tarif,
    worker: obj.worker && obj.worker.id,
    info: obj.info,
    extra: obj.extra,
    start_at: obj.start_at,
    end_at: obj.end_at
  }))

  const currFiltered: ComparisonModel[] = curr.map((obj) => ({
    id: obj.object_id,
    service: obj.service.id,
    tarif: obj.tarif,
    worker: obj.worker,
    info: obj.info,
    extra: obj.extra,
    start_at: obj.start_at,
    end_at: obj.end_at
  }))

  let list: ComparisonModel[] = currFiltered

  prevFiltered.forEach((obj) => {
    list = list.filter(
      (_) =>
        !(
          _.id === obj.id &&
          _.service === obj.service &&
          _.tarif === obj.tarif &&
          _.worker === obj.worker &&
          _.info === obj.info &&
          _.extra.delivery_address === obj.extra.delivery_address &&
          _.extra.back_delivery_address === obj.extra.back_delivery_address &&
          _.start_at === obj.start_at &&
          _.end_at === obj.end_at
        )
    )
  })

  return list
}
