import axios from "axios"
import {CrudInterface} from "../interfaces/crud.interface"
import {ListModel} from "../models/common"
import {ApiAbstract} from "../abstract/api.abstract"
import {ProfileModel} from "./auth.service"

export class ManageUsersService extends ApiAbstract implements CrudInterface<ProfileModel> {
  constructor() {
    super("v1/crm/users")
  }

  public async list(params?: any): Promise<ListModel<ProfileModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }

  public async get(id: number): Promise<ProfileModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }

  async post(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }

  async patch(id: number, payload: any): Promise<ProfileModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }

  async delete(id: number): Promise<any> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
}

const usersService = new ManageUsersService()
export default usersService
