import axios from "axios"
import {ApiAbstract} from "../abstract/api.abstract"
import {defaultConstant, ManagerConstantModel} from "src/models/manager/constants"
import {BehaviorSubject, Observable, map, of, shareReplay, switchMap} from "rxjs"
import authService from "./auth.service"
import {getAPI} from "src/interceptor"

export class AppService extends ApiAbstract {
  constructor() {
    super("v1/crm")
  }

  private loadConstants$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  loadConstants = () => this.loadConstants$.next()

  constants$: Observable<ManagerConstantModel> = authService.logged$.pipe(
    switchMap((logged) =>
      logged
        ? this.loadConstants$.pipe(
            switchMap(() =>
              getAPI<ManagerConstantModel>(
                axios.get(this.getUrl("constants"), {
                  params: {skip_error_handling: true}
                })
              )
            )
          )
        : of(defaultConstant)
    ),
    shareReplay(1)
  )

  getConstant(key: keyof ManagerConstantModel): Observable<any> {
    return this.constants$.pipe(map((constants) => constants[key]))
  }
}

const appService = new AppService()
export default appService
