import {useTranslation} from "react-i18next"
import {Outlet, createSearchParams, useNavigate} from "react-router-dom"
import {Segmented} from "src/components/shared/antd-custom"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import useQueryParams from "src/hooks/useQuertParams"

export default function ReportManagerLayout() {
  const {searchParams} = useQueryParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const page = useCurrentRoute()

  return (
    <>
      <Segmented
        value={page}
        className="bg-gray-100 mb-3 self-center rounded-md"
        onChange={(option) =>
          navigate({
            pathname: option,
            search: `?${createSearchParams(searchParams)}`
          })
        }
        style={{
          background: "var(--color-bg-light)",
          padding: 4,
          borderRadius: 6
        }}
        options={[
          {
            label: t("metrics.managers.pages.salary"),
            value: "salary",
            className: "p-1 font-medium text-black"
          },
          {
            label: t("metrics.managers.pages.workshifts"),
            value: "workshifts",
            className: "p-1 font-medium text-black"
          }
        ]}
      />

      <Outlet />
    </>
  )
}
