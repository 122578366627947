import qz from "qz-tray"
import modalService from "src/components/modal/global/modal.service"

const protocol = process.env.REACT_APP_API_PROTOCOL
const endpointApi = process.env.REACT_APP_API_BACKEND
const host = window.location.host

const subdomainPattern = /(https?:\/\/)([^.]+)\.(.*)/

const url = `${protocol}://${host.replace("localhost:3000", endpointApi)}`.replace(subdomainPattern, "$1$2.api.$3")

export const initializeQZ = () => {
  qz.security.setCertificatePromise(() =>
    fetch(`${url}/v1/crm/communication/qz/`, {cache: "no-store", headers: {"Content-Type": "text/plain"}}).then(
      async (data) => data.text()
    )
  )
  qz.security.setSignatureAlgorithm("SHA512")
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      fetch(`${url}/v1/crm/communication/qz_sign/?request=${toSign}`, {
        cache: "no-store",
        headers: {"Content-Type": "text/plain"}
      }).then(async (data) => {
        const text = await data.text()
        if (data.ok) {
          resolve(text)
        } else {
          reject(text)
        }
      })
    }
  })
}

export const connectQZ = () => {
  if (!qz.websocket.isActive()) {
    return qz.websocket.connect().then(() => console.log("Connected to QZ Tray"))
  }
  return Promise.resolve()
}

export const selectPrinter = async () => {
  let printer = localStorage.getItem("printer-name")

  if (!printer) {
    const onSelect = (name: string) => {
      localStorage.setItem("printer-name", name)
      printer = name
    }
    const list = await qz.printers.find()

    if (typeof list === "string") {
      onSelect(list)
    } else {
      modalService.open({
        component: (
          <div className="flex gap-2 flex-col">
            <h3 className="font-bold text-xl mb-3">Доступные принтеры чеков</h3>
            <div className="flex flex-wrap gap-2">
              {list.map((name, i) => (
                <span
                  key={i}
                  onClick={() => onSelect(name)}
                  className="font-medium p-2 rounded-lg bg-bg-light cursor-pointer">
                  {name}
                </span>
              ))}
            </div>
          </div>
        )
      })
    }
  }

  return printer
}

export const disconnectQZ = () => {
  if (qz.websocket.isActive()) {
    return qz.websocket.disconnect()
  }
  return Promise.resolve()
}
