import {isNil} from "./isNil"

export default function objectFilter(data: any) {
  const payload = {...data}
  Object.keys(payload).forEach((key) => {
    const nil = isNil(payload[key])
    if (nil) {
      delete payload[key]
    } else if (Array.isArray(payload[key])) {
      payload[key] = payload[key].join(",")
    }
  })
  return payload
}
