import moment from "moment"
import {useContext, useEffect, useMemo, useState} from "react"
import {timer} from "rxjs"
import {TenantModel} from "src/services/tenant.service"
import {ModalComponent} from "../modal/global/modal.v2"
import Icon from "../shared/components/material-icon"
import subscriptionModalService from "../../services/subscription.service"
import {ConfigContext} from "src"
import clsx from "clsx"
import {useTranslation} from "react-i18next"

const timer$ = timer(0, 1000)

function Timer({tenant, children}: {tenant: TenantModel; children: JSX.Element}) {
  const [days, setDays] = useState<number>()
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    const sub = subscriptionModalService.show$.subscribe(({show}) => setShowModal(show))

    const timer = timer$.subscribe(() => {
      const duration = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), "milliseconds")
      const daysLeft = duration.asDays()
      setDays(daysLeft)

      if (daysLeft <= 0 && !showModal) setShowModal(true)
    })

    return () => {
      sub.unsubscribe()
      timer.unsubscribe()
    }
  }, [])

  if (days && days > 0) return children

  return (
    <>
      <ModalComponent
        component={
          <div className="flex flex-col gap-8 items-center justify-center pt-4">
            <div className={"flex justify-center items-center bg-[#F5222D33] h-12 w-12 rounded-md"}>
              <Icon icon="error" className="text-[#F5222D] h-5 w-5" />
            </div>
            <div className="flex flex-col items-center gap-4 text-center max-w-96">
              <span className="font-semibold text-2xl text-black">Период подписки завершен</span>
              <span className="font-normal text-[15px] text-black">
                Выберите и оформите подписку на сервис, либо свяжитесь с менеджером для уточнения деталей
              </span>
            </div>
            <div className="flex flex-col gap-4 items-center w-full">
              <span
                className="w-full btn btn-primary btn-color-white font-medium"
                onClick={() => subscriptionModalService.setShow({show: true, type: null})}>
                Перейти к тарифам
              </span>
              <a
                className="w-full btn btn-icon font-medium text-[15px]"
                href="https://wa.me/+7779479990"
                target="_blank"
                rel="noreferrer">
                Связаться с менеджером
              </a>
            </div>
          </div>
        }
        show={showModal}
        removable={false}
      />
      {children}
    </>
  )
}

export default function HeaderPeriodBanner() {
  const {t} = useTranslation()
  const {tenant, isMobile} = useContext(ConfigContext)
  const daysLeft = useMemo(() => tenant && moment(tenant.end_at).diff(moment(), "days"), [tenant])

  const datetime = useMemo(
    () => tenant && moment.duration(moment(tenant.end_at).valueOf() - moment.now(), "milliseconds").humanize(true),
    [tenant]
  )

  const showBanner = useMemo(() => {
    if (!tenant) return false
    if (!daysLeft && daysLeft !== 0) return false
    if (!tenant.subscription && daysLeft <= 3) return true
    // if (!tenant.subscription && daysLeft <= 14) return true
    return daysLeft <= 3
  }, [tenant, daysLeft])

  return (
    showBanner && (
      <Timer tenant={tenant}>
        <div
          className={clsx("flex justify-center items-center min-h-14 gap-1 md:gap-6 p-1", {
            "bg-[#FF541B]": daysLeft <= 1,
            "bg-[#FFCA1B]": daysLeft > 1
          })}>
          <div className="flex gap-2 items-center">
            <Icon icon="error" className={clsx("h-5 w-5", daysLeft === 1 ? "text-white" : "text-black")} />
            {daysLeft === 1 ? (
              <span className="font-medium text-white">{t("banner.last_day")}</span>
            ) : (
              <span
                className="font-medium text-sm text-black"
                dangerouslySetInnerHTML={{__html: t("banner.message", {datetime: datetime})}}
              />
            )}
          </div>
          <span
            className={clsx("btn p-1 text-nowrap", {
              "btn-black": daysLeft <= 1,
              "btn-white btn-color-black": daysLeft > 1
            })}
            onClick={() => subscriptionModalService.setShow({show: true, type: null})}>
            {isMobile ? <Icon icon="chevron_right" /> : "Перейти к тарифам"}
            <Icon icon="chevron_right" />
          </span>
        </div>
      </Timer>
    )
  )
}
