import {InputHTMLAttributes, SyntheticEvent} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import Icon from "src/components/shared/components/material-icon"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  maxcount: number
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  removeable?: boolean
}

export function FormControlEntity(config: FormControlConfig) {
  const {register, watch, setValue} = useFormContext()
  const value = +watch(config.name) || 1

  const onMinus = (e: SyntheticEvent) => {
    e.stopPropagation()
    setValue(config.name, Math.max(value - 1, 1), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true
    })
  }

  const onAdd = (e: SyntheticEvent) => {
    e.stopPropagation()
    setValue(config.name, Math.min(value + 1, config.maxcount), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true
    })
  }

  const handleChange = (event) => {
    setValue(config.name, Math.min(Math.max(+event.target.value, 1), config.maxcount), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true
    })
  }

  return (
    <div className={config.rootclassname || ""}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <div className="flex align-center p-0" style={{backgroundColor: "var(--color-bg-light)", borderRadius: 6}}>
        <button
          className="btn"
          onClick={onMinus}
          disabled={config.removeable ? config.removeable && +value === 1 : false}>
          <Icon icon="remove" />
        </button>
        <input
          {...config}
          onChange={handleChange}
          {...register(config.name, config.params)}
          type="number"
          className="form-control-number btn px-1"
          style={{width: 40, borderRadius: 4}}
        />
        <button className="btn" onClick={onAdd}>
          <Icon icon="add" />
        </button>
      </div>
    </div>
  )
}
