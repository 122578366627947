import Table from "src/components/shared/antd-custom/table"
import {useEffect, useState} from "react"
import type {ColumnsType} from "antd/es/table"
import websiteService from "src/services/website.service"
import {EventModel} from "src/models/manager/website"
import modalService from "src/components/modal/global/modal.service"
import EventEdit from "./edit"
import toDateTime from "src/utils/date-time"
import {ImageField} from "src/components/shared/image-loader/image"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"

const WebsiteEvents = () => {
  const [list, setList] = useState<EventModel[]>([])
  const {t} = useTranslation()
  const onArticle = (article: EventModel) => {
    modalService.open({
      props: {size: "lg", backdrop: true},
      component: <EventEdit event={article} postSubmit={() => websiteService.listEvents().then(setList)} />
    })
  }

  const onCreateModal = () => {
    modalService.open({
      props: {size: "lg", backdrop: true},
      component: <EventEdit postSubmit={() => websiteService.listEvents().then(setList)} />
    })
  }

  const columns: ColumnsType<EventModel> = [
    {
      title: t("website.events.name"),
      key: "title",
      dataIndex: "title",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <ImageField src={record.image} style={{width: 64, height: 64, borderRadius: 8, objectFit: "cover"}} />
          <span className="font-medium">{record.title}</span>
        </div>
      )
    },
    {
      title: t("website.events.date"),
      key: "date",
      dataIndex: "date",
      render: (date) => toDateTime(date)
    },
    {
      title: t("website.events.register_count"),
      key: "user_registered",
      dataIndex: "user_registered",
      render: (user_registered) => user_registered
    },
    {
      title: t("website.events.slot_count"),
      key: "user_limit",
      dataIndex: "user_limit",
      render: (user_limit) => user_limit
    },
    {
      width: "1%",
      title: "",
      key: "action",
      render: (_, record) => (
        <button onClick={() => onArticle(record)} className="btn btn-card">
          <Icon className="text-lg" icon="edit" />
        </button>
      )
    }
  ]

  useEffect(() => {
    websiteService.listEvents().then(setList)
    return () => setList([])
  }, [])

  return (
    <>
      <div className="flex gap-3 items-center mb-3">
        <div className="text-xl font-semibold">{t("website.events.events")}</div>
        <button className="btn btn-add" onClick={onCreateModal}>
          <Icon icon="add" />
        </button>
      </div>
      <Table rowKey={(obj) => obj.id} columns={columns} dataSource={list} />
    </>
  )
}

export default WebsiteEvents
