import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react"
import {Accept, useDropzone} from "react-dropzone"
import Icon from "src/components/shared/components/material-icon"
import "./form-dropzone.scss"
import {useTranslation} from "react-i18next"
import {ImageField} from "../../image-loader/image"
import {ConfigContext} from "../../../../index"
import {useNavigate} from "react-router-dom"

export interface ImageModel {
  fileId: number
  src: string
  type?: "video" | "image"
  mediaId?: number
}

interface FormDropzoneProps<T> {
  medias: ImageModel[]
  onDelete?: (fileId: number, mediaId?: number) => void
  onSubmit?: (files: File[]) => Promise<T> | void
  accept?: Accept
  customContent?: React.ReactNode
  downloadBtnNeed?: boolean
  replaceContent?: boolean
  imgContainerClasses?: string
  navigateBack?: boolean
  containerLabel?: string | ReactNode
}

export function FormDropzone<T>({
  medias,
  onDelete,
  onSubmit,
  accept,
  customContent,
  downloadBtnNeed = true,
  replaceContent = false,
  imgContainerClasses = "",
  navigateBack = false,
  containerLabel = false
}: FormDropzoneProps<T>) {
  const [files, setFiles] = useState<File[]>([])
  const {t} = useTranslation()
  const [totalSize, setTotalSize] = useState<number>(0)
  const [showLimitText, setShowLimitText] = useState<boolean>(false)
  const navigate = useNavigate()
  const memoryLimit = 250
  const MAX_SIZE = memoryLimit * 1024 * 1024
  const {isMobile, hideMobileMenu, showMobileMenu} = useContext(ConfigContext)
  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => file.size <= MAX_SIZE)
      const filteredFilesSize = filteredFiles.reduce((acc, file) => acc + file.size, 0)

      if (filteredFiles.length < acceptedFiles.length) {
        setShowLimitText(true)
      } else {
        setShowLimitText(false)
      }

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles])
      setTotalSize((prevSize) => prevSize + filteredFilesSize)
    },
    [totalSize]
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: accept || {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "video/*": []
    },
    onDrop
  })

  const handleDelete = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.name !== file.name))
    setTotalSize((prevSize) => prevSize - file.size)
  }

  const renderMedia = (src: string, type = "image", id?: number, onRemove?: () => void) => (
    <div key={id || src} className={`dropzone-media-container ${imgContainerClasses}`}>
      {type === "image" ? (
        <div key={id || src} className={`dropzone-image-container ${imgContainerClasses}`}>
          <ImageField draggable={false} className="dropzone-image bg-gray-test-70" src={src} />
          {onRemove && <Icon className="remove text-base" onClick={onRemove} icon="add" />}
        </div>
      ) : (
        <div key={id || src} className={`dropzone-image-container ${imgContainerClasses}`}>
          <div
            className="dropzone-image bg-gray-test-70 flex items-center justify-center h-full w-full"
            onClick={() => window.open(src, "_blank")}>
            <Icon icon="play_arrow" className="text-[28px]" />
          </div>
          {onRemove && <Icon className="remove text-base" onClick={onRemove} icon="add" />}
        </div>
      )}
    </div>
  )

  const handleSubmit = async () => {
    try {
      await onSubmit(files)
      setFiles([])
      setTotalSize(0)
      if (navigateBack) {
        navigate(-1)
      }
    } catch (error) {
      console.error("Error during submission:", error)
    }
  }

  useEffect(() => {
    return () => setFiles([])
  }, [])

  useEffect(() => {
    if (isMobile) {
      hideMobileMenu()
    }
    return () => showMobileMenu()
  }, [files, showLimitText, isMobile])

  const content = (
    <label {...getRootProps()} className={`dropzone mb-0 cursor-pointer ${isDragActive ? "active" : ""}`}>
      {customContent || (
        <div className="flex flex-col items-center gap-2">
          <div className="text-lg font-medium">{t("common.upload_file.move_text")}</div>
          <div className="color-gray-200">{t("common.upload_file.or")}</div>
          <button className="btn btn-card gap-2">
            <Icon icon="upload_file" />
            <span>{t("common.upload_file.upload_text")}</span>
          </button>
        </div>
      )}
    </label>
  )

  const filesContent = (
    <>
      {files.map((file) =>
        renderMedia(
          URL.createObjectURL(file),
          file.type.startsWith("video") ? "video" : "image",
          file.lastModified,
          () => handleDelete(file)
        )
      )}
      {replaceContent && (
        <div className={`dropzone-image-container cursor-pointer ${imgContainerClasses}`} {...getRootProps()}>
          <div className={`dropzone-image flex items-center justify-center ${showLimitText && "border-alert"}`}>
            <Icon
              icon="add_photo_alternate"
              className={`text-2xl ${showLimitText ? "text-alert" : "text-test-black"}`}
            />
          </div>
        </div>
      )}
    </>
  )

  const additionalContent = (
    <>
      {showLimitText && (
        <p className={"font-normal text-sm text-alert mt-3"}>Ограничение по объему файлов {memoryLimit}МБ</p>
      )}
      {downloadBtnNeed && (
        <div className="flex justify-end">
          <button className="btn btn-black" onClick={handleSubmit}>
            {t("common.upload_photo")}
          </button>
          <hr className="color-gray-300" />
        </div>
      )}
    </>
  )
  return (
    <div>
      {containerLabel && <p className="font-normal text-[13px] text-gray-400 mb-3">{containerLabel}</p>}
      {isMobile && (
        <div className="bg-white shadow py-2 px-4 fixed left-0 bottom-0 right-0">
          <button
            onClick={handleSubmit}
            disabled={files.length === 0 || showLimitText}
            className="w-full flex items-center shadow-menu h-14 z-[100] btn btn-primary text-white font-medium text-sm">
            {t("common.save")}
          </button>
        </div>
      )}
      <div className={isMobile ? "flex" : ""}>
        {((files.length > 0 && isMobile) || medias.length > 0) && (
          <>
            <div className="flex gap-2 flex-wrap">
              {medias.map((media) =>
                renderMedia(media.src, media.type, media.fileId, () =>
                  onDelete(media.fileId, media.mediaId ?? undefined)
                )
              )}
              {isMobile && filesContent}
              {isMobile && additionalContent}
            </div>
            {!isMobile && <hr className="color-gray-300 my-4" />}
          </>
        )}
        {files.length > 0 && !isMobile && <div className="flex gap-2 flex-wrap">{filesContent}</div>}
        {files.length > 0 && !isMobile && additionalContent}
      </div>

      <input autoComplete="off" {...getInputProps()} />

      {replaceContent ? files.length > 0 || medias.length > 0 ? <></> : <>{content}</> : <>{content}</>}
    </div>
  )
}
