import {ApiAbstract} from "../abstract/api.abstract"
import {ProductOrder, ProductResult} from "../models/manager/website"
import axios from "axios"
import {ListModel} from "../models/common"
import {BehaviorSubject, from, map, Observable, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "src/interceptor/axios-response"

export class WidgetService extends ApiAbstract {
  listOrdering$ = new BehaviorSubject<void>(null)
  listOrdering = () => this.listOrdering$.next()

  ordering$: Observable<Record<string, ProductOrder>> = this.listOrdering$.pipe(
    switchMap(() =>
      from(axios.get<ProductOrder[]>("/v1/website/products/ordering/?skip_loader=true")).pipe(axiosResponce)
    ),
    map((list: ProductOrder[]) => list.reduce((prev, curr) => ({[`${curr.type}_${curr.product}`]: curr, ...prev}), {})),
    shareReplay(1)
  )

  constructor() {
    super("v1")
  }

  public async list(params?: any): Promise<ListModel<ProductResult>> {
    const response = await axios.get(this.getUrl("shared/products"), {params})
    return response.data
  }

  public async patchOrder(id: number, payload: any): Promise<ProductOrder> {
    const response = await axios.patch(this.getUrl(`website/products/ordering/${id}`), payload, {
      params: {skip_loader: true}
    })
    this.listOrdering()
    return response.data
  }
}

const widgetService = new WidgetService()
export default widgetService
