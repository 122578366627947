import Icon from "src/components/shared/components/material-icon"
import {MouseEvent, useState} from "react"
import {useTranslation} from "react-i18next"
import {BaseMaintenanceModel, InventoryMaintenanceModel} from "src/models/manager/maintenance/maintenance.model"
import {Popover} from "antd"
import moment from "moment"
import toDateTime from "src/utils/date-time"
import clsx from "clsx"
import Duration from "src/utils/duriation"

interface OptionMaintenanceProps {
  finishMaintenance: (inventory: InventoryMaintenanceModel, maintenance: BaseMaintenanceModel) => void
  newMaintenance: (inventory: InventoryMaintenanceModel) => void
  inventory: InventoryMaintenanceModel
}

const OptionMaintenance = ({finishMaintenance, newMaintenance, inventory}: OptionMaintenanceProps) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const maintenance = inventory.maintenances.length > 0 ? inventory.maintenances[0] : null
  const targetDateMoment = maintenance ? moment(maintenance.maintenance_date) : moment()
  const remaining = targetDateMoment.diff(moment(), "seconds")
  const daysRemaining = targetDateMoment.diff(moment(), "days")
  const termClass = remaining < 0 ? "text-alert" : "text-primary"

  const onFinish =
    (inventory: InventoryMaintenanceModel, maintenance: BaseMaintenanceModel) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      setOpen(false)
      finishMaintenance(inventory, maintenance)
    }

  const onNew = (inventory: InventoryMaintenanceModel) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    setOpen(false)
    newMaintenance(inventory)
  }

  if (inventory.maintenances.length === 0) {
    return (
      <button className="text-primary flex items-center gap-2 font-normal cursor-pointer" onClick={onNew(inventory)}>
        <Icon icon="add" />
        <span className="hover:underline hover:decoration-primary w-full h-full">
          {t("maintenance.new_maintenance")}
        </span>
      </button>
    )
  }

  return (
    <Popover
      trigger="click"
      open={open}
      onOpenChange={(open) => setOpen(open)}
      className="flex gap-2 items-center"
      arrow={false}
      overlayInnerStyle={{padding: "0", overflow: "hidden"}}
      placement="bottom"
      content={
        <div className="flex flex-col">
          {inventory.maintenances.map((maintenance) => (
            <button
              key={maintenance.id}
              className={clsx("flex gap-2 p-2 items-center min-w-[192px] border-b border-gray-100 first:rounded-t-lg", {
                "text-alert bg-alert bg-opacity-20 border-alert border-opacity-50":
                  moment(maintenance.maintenance_date) < moment()
              })}
              onClick={onFinish(inventory, maintenance)}>
              <Icon icon="handyman" />
              <span className="flex-1 text-start">{maintenance.name}</span>
              <span className="font-medium">{toDateTime(maintenance.maintenance_date)}</span>
            </button>
          ))}

          <button className="flex gap-2 p-2 items-center min-w-[192px]" onClick={onNew(inventory)}>
            <Icon icon="add" />
            <span>{t("maintenance.new_maintenance")}</span>
          </button>
        </div>
      }>
      <div className="flex gap-2 items-center cursor-pointer">
        <Icon icon="warning" className={termClass} />
        <div
          className={clsx("cursor-pointer flex flex-col hover:underline w-full h-full", termClass, {
            "hover:decoration-primary": remaining > 0,
            "hover:decoration-red-500": remaining <= 0
          })}>
          <span>{toDateTime(maintenance.maintenance_date)}</span>
          <span>
            {daysRemaining < 0 && t("maintenance.according_to_plan")}
            {daysRemaining === 0 && remaining <= 0 && t("common.times_count.today")}
            {daysRemaining < 20 && daysRemaining > 0 && moment.duration(remaining, "seconds").humanize(true)}
          </span>
        </div>
      </div>
    </Popover>
  )
}

export default OptionMaintenance
