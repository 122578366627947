import {useContext} from "react"
import {use100vh} from "react-div-100vh"
import {ConfigContext} from "src"
import workshiftService from "src/services/workshift.service"

export default function WorkshiftStart() {
  const {headerHeight} = useContext(ConfigContext)
  const minHeight = use100vh() - headerHeight

  const onStart = () => workshiftService.start()

  return (
    <div className="flex p-5 items-center justify-center w-full" style={{minHeight}}>
      <button className="btn btn-primary btn-color-white" onClick={onStart}>
        Начать смену
      </button>
    </div>
  )
}
