import React from "react"
import Icon from "src/components/shared/components/material-icon"
import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}

const SortableRow = ({children, ...props}: RowProps) => {
  const {attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging} = useSortable({
    id: props["data-row-key"]
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && {...transform, scaleY: 1}),
    transition,
    ...(isDragging ? {position: "relative", zIndex: 99} : {})
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        const element: React.ReactElement = child as React.ReactElement

        if (element.key === "sort") {
          return React.cloneElement(element, {
            className: "min-w-12 max-w-12 p-0 bg-bg-light",
            children: (
              <div
                className="flex items-center justify-center w-full h-full p-2 py-4"
                ref={setActivatorNodeRef}
                {...listeners}>
                <Icon icon="menu" className="m-auto" />
              </div>
            )
          })
        }

        return child
      })}
    </tr>
  )
}

export default SortableRow
