import * as amplitude from "@amplitude/analytics-browser"

const AMPLITUDE_PROJECT_ID = "b3a1629d8a8a866b986124fcfc0ccfd2"

export const initAmplitude = () => amplitude.init(AMPLITUDE_PROJECT_ID, {defaultTracking: {pageViews: false}})

export const trackAmplitudeData = (
  eventType: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, unknown>
) => {
  amplitude.track(eventType, eventProperties)
}
