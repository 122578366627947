import {ApiAbstract} from "../abstract/api.abstract"
import {ProductResult} from "src/models/manager/website"
import axios from "axios"
import {ListModel} from "src/models/common"
import {BehaviorSubject, from, map, Observable, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "src/interceptor/axios-response"

export class NotificationService extends ApiAbstract {
  getCount$ = new BehaviorSubject<void>(null)
  getCount = () => this.getCount$.next()

  count$: Observable<number> = this.getCount$.pipe(
    switchMap(() =>
      from(axios.get<{unreaded: number}>("/v1/crm/communication/notifications/count/")).pipe(axiosResponce)
    ),
    map(({unreaded}) => unreaded),
    shareReplay(1)
  )

  constructor() {
    super("v1/crm/communication")
  }
  public async list(params?: any): Promise<ListModel<ProductResult>> {
    const response = await axios.get(this.getUrl("notifications"), {params})
    return response.data
  }
  public async archivePost(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl("notifications/archive"), payload)
    this.getCount()
    return response.data
  }
  public async post(payload: any): Promise<any> {
    const response = await axios.post(this.getUrl("notifications/read"), payload)
    this.getCount()
    return response.data
  }
}

const notificationService = new NotificationService()
export default notificationService
