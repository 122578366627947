import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router"
import onboardingService, {GuideStates, OnboadringState} from "src/services/checklist.service"
import authService from "src/services/auth.service"
import checkIcon from "src/assets/images/check.svg"
import {Link} from "react-router-dom"
import {firstValueFrom, switchMap} from "rxjs"
import orderService from "src/services/order-requests/order.service"
import {Progress} from "antd"
import {ModalComponent} from "../modal/global/modal.v2"
import OnboardingIntroModal from "./modal/intro"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {trackAmplitudeData} from "src/services/amplitude"
import {ConfigContext} from "src"
import "../header/styles.scss"
import "./styles.scss"
import {isProd} from "src/utils/isProd"

const stateLabel: Record<GuideStates, string> = {
  product: "onboarding.sections.group",
  document: "onboarding.sections.document",
  order: "onboarding.sections.order",
  metrics: "onboarding.sections.metrics"
}

const stateIndex: Record<GuideStates, number> = {
  product: 1,
  document: 2,
  order: 3,
  metrics: 4
}

const defaultState: OnboadringState = {
  hidden: false,
  intro: false,
  states: {
    product: false,
    document: false,
    order: false,
    metrics: false
  }
}

// function LabelComponent({label}: {label: string}) {
//   return (
//     <div className="weigth-500 text-sm flex items-center gap-2 cursor-pointer">
//       <span>{label}</span>
//       <Icon icon="chevron_right" />
//     </div>
//   )
// }

// function TourSpotlighting() {
//   const navigate = useNavigate()
//   const [spoted, setSpoted] = useState<Spot>(undefined)
//   const [steps, setSteps] = useState<Object>({})

//   const stepList: Spot[] = [
//     {key: 'sp_group_add_main', name: "Информация продукта", actionBefore: () => navigate('/groups/add', {replace: false})},
//     {key: 'sp_group_add_inventory', name: "Информация инвентарей"},
//     {key: 'sp_group_add_inventory_tarif', name: "Информация тарифа для продукта"},
//     {key: 'sp_group_add_sublease', name: "Информация субаренды"}
//   ]

//   const documentList: Spot[] = [
//     {key: 'sp_config', name: "Страница конфигурации", type: 'spotlight', animate: true, component: <LabelComponent label="Зайдите в раздел конфигурации" />, onClick: () => spotlightService.spotV2('sp_config_documents')},
//     {key: 'sp_config_documents', name: "Секция документа", actionBefore: () => navigate('/config'), onClick: () => spotlightService.spotV2('sp_config_documents_add')},
//     {key: 'sp_config_documents_add', name: "Кнопка добавления", actionBefore: () => navigate('/config/documents'), onClick: () => spotlightService.spotV2('sp_config_documents_template_btn')},
//     {key: 'sp_config_documents_template_btn', name: "Примеры документов", onClick: () => spotlightService.unspot()},
//   ]

//   const groupInvTour = () => spotlightService.setSteps(stepList)
//   const documentTour = () => spotlightService.setSteps(documentList)

//   useEffect(() => {
//     spotlightService.spoted$.subscribe(setSpoted)
//     spotlightService.steps$.subscribe(setSteps)
//   }, [])

//   return <div className="p-3 flex gap-2 flex-col">
//     <div className="btn btn-primary btn-color-white" onClick={groupInvTour}>groupInvTour</div>
//     <div className="btn btn-primary btn-color-white" onClick={documentTour}>documentTour</div>

//     {spoted &&
//       <>
//         <hr />

//         <ul className="flex gap-2 flex-col">
//           {stepList.map((step, i) =>
//             <li
//               className="cursor-pointer"
//               style={{color: step.key === spoted.key ? 'var(--color-primary)' : 'var(--color-gray-300)'}}
//               key={i}
//               onClick={() => spotlightService.spotV2(step.key)}
//             >{step.name}</li>)}
//         </ul>

//         <hr />
//       </>
//     }

//     {spoted && <div className="flex gap-2 justify-between">
//       <button disabled={!spoted.prev} className="btn btn-primary btn-color-white" onClick={() => spotlightService.spotV2(spoted.prev)}>назад</button>
//       <button disabled={!spoted.next} className="btn btn-primary btn-color-white" onClick={() => spotlightService.spotV2(spoted.next)}>вперед</button>
//     </div>}
//   </div>
// }

function OnboardingSection({stateKey, children}: {stateKey: GuideStates; children: JSX.Element}) {
  const {t} = useTranslation()
  const [state, setState] = useState<OnboadringState>(undefined)
  const activeKey = state && Object.keys(state.states).find((key) => state.states[key] === false)

  useEffect(() => {
    const stateSub = onboardingService.state$.subscribe(setState)
    return () => stateSub.unsubscribe()
  }, [])

  return (
    state && (
      <div className="onboarding-section">
        <div className="w-full flex gap-3 items-center">
          <div className="onboarding-checkpoint">
            {state.states[stateKey] && <img src={checkIcon} draggable={false} />}
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span className="color-gray-300 text-[13px] font-normal">Шаг {stateIndex[stateKey]}</span>
            <h5 className={clsx("text-[15px] mb-0 font-medium", state.states[stateKey] && "color-gray-300")}>
              {t(stateLabel[stateKey] as any)}
            </h5>
          </div>
        </div>
        {activeKey === stateKey && children}
      </div>
    )
  )
}

export function OnboardingBanner({className = "onboarding-banner"}: {className?: string}) {
  const {t} = useTranslation()
  const [state, setState] = useState<OnboadringState>(undefined)
  const completedCount = state && Object.keys(state.states).filter((key) => state.states[key]).length

  useEffect(() => {
    const stateSub = onboardingService.state$.subscribe(setState)
    return () => stateSub.unsubscribe()
  }, [])

  return (
    state && (
      <div className={className}>
        <Progress
          percent={(completedCount * 100) / 4}
          size={48}
          status="normal"
          type="circle"
          format={(percent) => {
            if (percent === 100) return "4/4"
            if (percent >= 75) return "3/4"
            if (percent >= 50) return "2/4"
            if (percent >= 25) return "1/4"
            if (percent >= 0) return "0/4"
          }}
          style={{fontWeight: 600}}
          trailColor="var(--color-gray-100)"
          strokeColor="var(--color-primary)"
          success={{strokeColor: "var(--color-primary)"}}
          strokeWidth={12}
          strokeLinecap="butt"
        />
        <div className="flex flex-col gap-2">
          <h5 className="text-[15px] font-medium mb-1">{t("onboarding.banner.title")}</h5>
          <span className="color-primary text-[13px]">{t("onboarding.banner.completed", {count: completedCount})}</span>
        </div>
      </div>
    )
  )
}

export default function TourWrapper() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {isMobile} = useContext(ConfigContext)
  const [onboardingState, setOnboardingState] = useState<OnboadringState>(undefined)

  const createOrder = () => {
    firstValueFrom(
      authService.profile$.pipe(
        switchMap((profile) => {
          if (isProd) trackAmplitudeData("Create Order")
          return orderService
            .post({
              rental_point: profile.points.length > 0 ? profile.points[0] : null
            })
            .then((res) => navigate(`/orders/${res.id}/all`))
        })
      )
    )
  }

  useEffect(() => {
    const profileSub = authService.profile$.subscribe((profile) => {
      if (!profile) return
      const key = `onboarding_${profile.id}`
      const stateStr = localStorage.getItem(key)
      const state: OnboadringState = stateStr ? JSON.parse(stateStr) : defaultState
      onboardingService.setState(state)
    })
    return () => profileSub.unsubscribe()
  }, [])

  useEffect(() => {
    const stateSub = onboardingService.state$.subscribe(setOnboardingState)
    return () => stateSub.unsubscribe()
  }, [])

  if (isMobile) return <></>
  if (onboardingState && onboardingState.hidden) return <></>
  return (
    onboardingState &&
    !onboardingState.hidden && (
      <div className="onboard-aside hidden md:block">
        <div className="static-header border-bottom flex justify-between items-center" style={{height: 81}}>
          <h2 className="text-2xl font-bold mb-0">{t("onboarding.header")}</h2>
          <div className="modal-remove" onClick={() => onboardingService.hide()} style={{position: "unset"}}>
            <Icon icon="close" />
          </div>
        </div>

        <div className="onboarding-wrapper">
          <OnboardingBanner />

          <OnboardingSection stateKey="product">
            <Link to="/groups/add" className="btn onboarding-action">
              <Icon className="font-semibold" icon="add" />
              <span className="text-[13px] font-medium">{t("onboarding.sections.group")}</span>
            </Link>
          </OnboardingSection>

          <OnboardingSection stateKey="document">
            <Link to="/config/documents/add" className="btn onboarding-action">
              <span className="text-[13px] font-medium">{t("onboarding.sections.document")}</span>
            </Link>
          </OnboardingSection>

          <OnboardingSection stateKey="order">
            <button onClick={createOrder} className="btn onboarding-action">
              <span className="text-[13px] font-medium">{t("onboarding.sections.order")}</span>
            </button>
          </OnboardingSection>

          <OnboardingSection stateKey="metrics">
            <Link to="/reports" onClick={() => onboardingService.activate("metrics")} className="btn onboarding-action">
              <span className="text-[13px] font-medium">{t("onboarding.sections.metrics")}</span>
            </Link>
          </OnboardingSection>
        </div>

        <ModalComponent component={<OnboardingIntroModal />} removable={false} show={!onboardingState.intro} />
      </div>
    )
  )
}
