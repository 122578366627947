import {HTMLAttributes} from "react"
import "./tabs.scss"
import clsx from "clsx"
import React from "react"

export interface TabPanelConfig {
  key: string | number
  label: string | JSX.Element | ((config: TabPanelConfig) => JSX.Element)
  active?: boolean
  disabled?: boolean
  onClick?: () => void
  isMobile?: boolean
}

export interface TabConfig extends HTMLAttributes<HTMLDivElement> {
  items: TabPanelConfig[]
  onClick?: (TabPanelConfig) => void
  activeTab?: string | number
  rightChildren?: JSX.Element
  isMobile?: boolean
  tabClassNames?: string
}

export const TabHeader = (config: TabPanelConfig) => {
  const {label, active, disabled, onClick} = config
  const className = clsx("tab-nav-link px-3 py-3 md:px-5 md:py-4 text-nowrap", {
    disabled,
    active
  })

  if (typeof label === "function") return <span onClick={onClick}>{label(config)}</span>
  return (
    <span className={className} onClick={onClick}>
      {label}
    </span>
  )
}

export const TabPanel = ({
  isMobile,
  className,
  style,
  items,
  activeTab,
  onClick,
  rightChildren,
  tabClassNames,
  ...rest
}: TabConfig) => {
  return (
    <div className={clsx("flex items-end", className)}>
      <div
        className={clsx("tab scrollbar-hide border-bottom flex-1 px-0 pt-0 md:px-4 md:pt-2", tabClassNames)}
        style={style}>
        <div className={`tab-nav gap-2 md:gap-8 ${isMobile && "ml-3 py-3"}`}>
          {items.map((item) => (
            <TabHeader
              {...item}
              key={item.key}
              active={activeTab && item.key === activeTab}
              onClick={onClick ? () => onClick(item) : undefined}
              isMobile={isMobile}
              {...rest}
            />
          ))}
        </div>
      </div>

      {rightChildren}
    </div>
  )
}
