import moment from "moment-timezone"
import {useTranslation} from "react-i18next"
import notificationService from "src/services/notification.service"
import {useNavigate} from "react-router-dom"
import Avatar from "src/utils/genereteAvatar"
import maintenanceService from "src/services/maintenance/maintenance.service"
import MaintenanceFinishModal from "src/pages/maintenance/maintenance-finish.modal"
import modalService from "src/components/modal/global/modal.service"

const NotificationItem = ({item, notifications, index, markRead, onHide}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const currentDate = moment(item.created_at).locale("ru").format("DD MMM YYYY")
  const previousDate =
    index > 0
      ? moment(notifications.results[index - 1].created_at)
          .locale("ru")
          .format("DD MMM YYYY")
      : null
  const isToday = moment(item.created_at).isSame(moment(), "day")
  const showDateDivider = !previousDate || currentDate !== previousDate

  const displayDate = isToday ? t("notification.today") : currentDate

  const getPage = async (model: string, id: number, objID: number, readed: boolean) => {
    switch (model) {
      case "orderrequest":
        navigate(`orders/${objID}/all`)
        if (!readed) {
          await markRead(id, false).then(() => notificationService.getCount())
        }
        onHide()
        break
      case "inventorymaintenance":
        onHide()
        if (!readed) {
          markRead(id, false).then(() => notificationService.getCount())
        }
        navigate("maintenance")
        try {
          const inventoryId = item.extra?.data?.inventory_id
          const fetchedInventory = await maintenanceService.getListInventory(inventoryId)
          const fetchedMaintenance = await maintenanceService.getListInventoryMaintenance(inventoryId, objID)

          if (fetchedInventory && fetchedMaintenance) {
            modalService.open({
              props: {backdrop: true, dialogClassName: "max-w-fit"},
              component: (
                <MaintenanceFinishModal
                  inventory={fetchedInventory}
                  maintenance={fetchedMaintenance}
                  onSubmit={(data) => maintenanceService.finish(fetchedInventory.id, fetchedMaintenance.id, data)}
                />
              )
            })
          }
        } catch (error) {
          console.error(error)
        }
        break
      default:
        console.warn("Unknown model:", model)
    }
  }
  const getTitleByModel = (model) => {
    switch (model) {
      case "orderrequest":
        return {
          title: `${t("notification.rental_exeed")}!`,
          styleLine: "bg-alert"
        }
      case "inventorymaintenance":
        return {
          title: `${t("notification.maintenance_required")}!`,
          styleLine: "bg-warning"
        }
      default:
        return {
          title: t("notification.notifications"),
          styleLine: "bg-primary"
        }
    }
  }

  const {title, styleLine} = getTitleByModel(item?.model)
  return (
    <div key={`${item}-${index}`} className="cursor-pointer">
      {showDateDivider && (
        <div className="flex items-center text-xs text-gray-400">
          <div className="flex-grow border-t border-gray-100"></div>
          <span className="px-2">{displayDate}</span>
          <div className="flex-grow border-t border-gray-100"></div>
        </div>
      )}
      <div
        className={`p-3 flex w-full ${!showDateDivider && index !== 0 ? "border-t border-gray-100" : ""}`}
        onClick={() => getPage(item.model, item.id, item.object_id, item.readed)}>
        <div className={`h-6 w-0.5 ${styleLine}`}></div>
        <div className="pl-3.5 flex-1 ">
          <div className="flex gap-2 flex-wrap">
            <div className="text-sm font-semibold text-black">{title}</div>
            <div className="text-sm text-gray-400">{item.body}</div>
            <div className="text-sm text-gray-400">
              {item.extra?.data?.unique_id || item.extra?.data?.inventory_unique_id}
            </div>
          </div>
          <div className="flex gap-2 items-center mt-2">
            {item.extra?.data?.client_name && <Avatar name={item.extra?.data?.client_name} />}
            <div className="text-xs text-gray-400">{item.extra?.data?.client_name}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-end relative justify-end">
          {!item.readed ? (
            <div className="top-0 right-0 absolute w-2 h-2 bg-red-500 rounded-full ml-4"></div>
          ) : (
            <div></div>
          )}
          <div className="flex justify-end text-xs text-gray-400 mt-3">
            {moment(item.created_at).locale("ru").format("DD MMM HH:mm")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationItem
