import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlDate} from "src/components/shared/inputs/form-control-date"
import {CustomFieldChoiceList} from "src/pages/settings/custom-field/field-choice"
import {useTranslation} from "react-i18next"
import moment from "moment/moment"
import {FieldError, useFormContext} from "react-hook-form"

type PassportErrors = {
  birth_date?: FieldError
  issue_date?: FieldError
  issue_date_end?: FieldError
}

type FormErrors = {
  passport__individual?: PassportErrors
}
export default function ClientIndividualPassportComponent() {
  const {t} = useTranslation()
  const rules = {required: true}
  const {formState} = useFormContext()

  const errors = formState.errors as FormErrors
  return (
    <>
      <div className="grid sm:grid-cols-2 gap-4">
        <FormControlMask
          label={t("clients.form.document.individual.iin")}
          placeholder="100000000000"
          name="passport__individual.iin"
          mask="999999999999"
          rootclassname="col"
          className="form-control"
          required={rules.required}
          params={rules}
        />
        <div className="flex flex-col gap-1">
          <FormControlMask
            label={t("clients.form.document.individual.birth_date")}
            placeholder="DD-MM-YYYY"
            name="passport__individual.birth_date"
            mask="99-99-9999"
            rootclassname="col"
            className={`form-control ${errors.passport__individual?.birth_date ? "border-red-500" : ""}`}
            params={{
              validate: {
                validDate: (value) =>
                  !value ||
                  (moment(value, "DD-MM-YYYY", true).isValid() &&
                    moment(value, "DD-MM-YYYY").isSameOrBefore(moment())) ||
                  "Дата не может быть в будущем или неверный формат"
              }
            }}
          />
          {errors.passport__individual?.birth_date && (
            <p className="text-alert text-sm">{(errors.passport__individual?.birth_date as FieldError)?.message}</p>
          )}
        </div>

        <FormControl
          label={t("clients.form.document.individual.document_number")}
          placeholder={t("clients.form.document.individual.placeholder.document_number")}
          name="passport__individual.document_number"
          rootclassname="col"
          className="form-control"
        />
        <FormControl
          label={t("clients.form.document.individual.issuer_manual")}
          placeholder={t("clients.form.document.individual.placeholder.issuer_manual")}
          name="passport__individual.issuer_manual"
          rootclassname="col"
          className="form-control"
        />
        <div>
          <FormControlMask
            label={t("clients.form.document.individual.issue_date")}
            placeholder="DD-MM-YYYY"
            name="passport__individual.issue_date"
            mask="99-99-9999"
            rootclassname="col"
            className={`form-control ${errors.passport__individual?.issue_date ? "border-red-500" : ""}`}
            params={{
              validate: {
                validDate: (value) =>
                  !value || moment(value, "DD-MM-YYYY", true).isValid() || "Неправильный формат даты"
              }
            }}
          />
          {errors.passport__individual?.issue_date && (
            <p className="text-alert text-sm">{(errors.passport__individual?.issue_date as FieldError)?.message}</p>
          )}
        </div>

        <div>
          <FormControlMask
            label={t("clients.form.document.individual.issue_date_end")}
            placeholder="DD-MM-YYYY"
            name="passport__individual.issue_date_end"
            mask="99-99-9999"
            rootclassname="col"
            className={`form-control ${errors.passport__individual?.issue_date_end ? "border-red-500" : ""}`}
            params={{
              validate: {
                validDate: (value) =>
                  !value || moment(value, "DD-MM-YYYY", true).isValid() || "Неправильный формат даты"
              }
            }}
          />
          {errors.passport__individual?.issue_date_end && (
            <p className="text-alert text-sm">{(errors.passport__individual?.issue_date_end as FieldError)?.message}</p>
          )}
        </div>
      </div>
    </>
  )
}
