import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {OrderRequestInventoryModel} from "src/models/manager/order/order-request-inventory"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import {OrderRequestDeliveryModel} from "src/models/manager/order/order-request-delivery.model"

export class ManageOrderInventoriesService extends ApiAbstract {
  constructor() {
    super("v1/crm/requests")
  }

  public async listInventories(orderId: number): Promise<OrderRequestInventoryModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/inventories`))
    return response.data
  }
  public async listGroups(orderId: number): Promise<InventoryGroupModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/groups`))
    return response.data
  }
  public async listSets(orderId: number): Promise<InventorySetModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/sets`))
    return response.data
  }
  public async createDelivery(orderId: number, payload: any): Promise<OrderRequestDeliveryModel> {
    const response = await axios.post(this.getUrl(`${orderId}/deliveries`), payload)
    return response.data
  }
  public async deleteDelivery(orderId: number, id: any): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${orderId}/deliveries/${id}`))
    return response.data
  }
  public async listDeliveries(orderId: number): Promise<OrderRequestDeliveryModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/deliveries`))
    return response.data
  }

  public async bulkCreate(orderId: number, payload: any): Promise<OrderRequestInventoryModel> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_create`), payload, {
      params: {skip_error_handling: true}
    })
    return response.data
  }
  public async bulkUpdate(orderId: number, payload: any): Promise<OrderRequestInventoryModel> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_update`), payload, {
      params: {skip_error_handling: true}
    })
    return response.data
  }
  public async bulkDelete(orderId: number, payload: any): Promise<OrderRequestInventoryModel> {
    const response = await axios.post(this.getUrl(`${orderId}/inventories/bulk_delete`), payload)
    return response.data
  }
  public async postSet(orderId: number, payload: any): Promise<Partial<OrderRequestInventoryModel>[]> {
    const response = await axios.post(this.getUrl(`${orderId}/sets`), payload)
    return response.data
  }
  public async postGroup(orderId: number, payload: any): Promise<Partial<OrderRequestInventoryModel>[]> {
    const response = await axios.post(this.getUrl(`${orderId}/groups`), payload)
    return response.data
  }
  public async periodChange(orderId: number, payload: any): Promise<Partial<OrderRequestInventoryModel>[]> {
    const response = await axios.post(this.getUrl(`${orderId}/period`), payload)
    return response.data
  }
  public async get(id: number): Promise<any> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
}

export default new ManageOrderInventoriesService()
