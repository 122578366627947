import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map, of} from "rxjs"
import appService from "src/services/app.service"
import {RentalPointModel} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {useContext} from "react"
import {ConfigContext} from "src"

type RemovalKeys = "listOptions" | "getValue" | "getOptionLabel" | "getOptionValue"
const rentalPoints: Observable<RentalPointModel[]> = appService.getConstant("RENTAL_POINTS")

export function SharedRentalPointSelect(config: Omit<FormSelectAsyncConfig<RentalPointModel>, RemovalKeys>) {
  const {t} = useTranslation()
  const {profile, constants} = useContext(ConfigContext)

  return (
    profile && (
      <FormSelectAsync<RentalPointModel>
        {...config}
        listOptions={() =>
          of(constants.RENTAL_POINTS.filter((point) => profile.is_superuser || profile.points.includes(point.id)))
        }
        placeholder={config.placeholder || t("common.select.rental_point")}
        getValue={(val: number | number[]) =>
          firstValueFrom(
            rentalPoints.pipe(
              map((list) => {
                return Array.isArray(val)
                  ? list.filter((o) => val.map((id) => +id).includes(o.id))
                  : list.find((o) => o.id === +val)
              })
            )
          )
        }
        getOptionLabel={(option: RentalPointModel) => (option ? String(option.name) : null)}
        getOptionValue={(option: RentalPointModel) => (option ? String(option.id) : null)}
      />
    )
  )
}
