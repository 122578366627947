import {InputHTMLAttributes, useEffect, useRef} from "react"

export interface ControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean
}

export function ControlCheckbox(config: ControlConfig) {
  const ref = useRef()
  const params = (({indeterminate, ...o}) => o)(config)

  useEffect(() => {
    if (!ref.current) return
    ;(ref.current as any).indeterminate = config.indeterminate
  }, [config.indeterminate])

  return <input ref={ref} {...params} type="checkbox" />
}
