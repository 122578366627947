import {useEffect, useMemo, useRef, useState} from "react"
import Icon from "src/components/shared/components/material-icon"
import moment from "moment-timezone"
import {EMPTY_LIST, ListModel} from "src/models/common"
import notificationService from "src/services/notification.service"
import {useTranslation} from "react-i18next"
import useOnScreen from "src/hooks/useOnScreen"
import {useNavigate} from "react-router-dom"
import {Notification} from "src/models/manager/notification/notification"
import NotificationItem from "./item"
import NotificationActions from "./actions"

const NotificationPopUp = ({onHide}) => {
  const [notifications, setNotifications] = useState<ListModel<Notification>>(EMPTY_LIST)
  const [showArchived, setShowArchived] = useState(false)
  const [page, setPage] = useState(1)

  const loadMoreRef = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(loadMoreRef)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const notificationIds = notifications.results.map((item) => item.id)
  const start_at = useMemo(() => moment().subtract(1, "months").startOf("month").toISOString(), [])

  const fetchNotifications = async (params: any = {}) => {
    const _params = {start_at, page: 1, ...params}
    const response: any = await notificationService.list(_params)
    setNotifications((prevNotification) =>
      _params.page === 1
        ? response
        : {
            ...response,
            results: [...prevNotification.results, ...response.results]
          }
    )
  }

  const handleClickArchive = async () => {
    const newShowArchived = !showArchived
    setShowArchived(newShowArchived)
    resetPage()
    await fetchNotifications({archived: newShowArchived, page: 1})
  }

  const markRead = async (id: number, readAll: boolean) => {
    const payload = {
      notification_ids: Array.isArray(id) ? notificationIds : [id],
      read_all: readAll
    }
    await notificationService.post(payload)
    setNotifications((prev) => ({
      ...prev,
      results: prev.results.map((n) => ({
        ...n,
        readed: readAll ? true : Array.isArray(id) ? id.includes(n.id) : n.id === id ? true : n.readed
      }))
    }))
  }

  const resetPage = () => {
    setPage(1)
  }

  useEffect(() => {
    fetchNotifications({archived: showArchived, page})
  }, [page, showArchived])

  useEffect(() => {
    if (isOnScreen && notifications.next !== false && !!notifications.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [isOnScreen, showArchived])

  const handleHide = () => {
    onHide()
    resetPage()
  }

  const handleClose = () => {
    navigate("orders/all")
    onHide()
  }

  return (
    <div className="w-[500px] h-[600px] overflow-y-scroll font-sans">
      <NotificationActions
        notifications={notifications}
        handleHide={handleHide}
        markRead={markRead}
        notificationIds={notificationIds}
        page={page}
        fetchNotifications={fetchNotifications}
        handleClickArchive={handleClickArchive}
        setNotifications={setNotifications}
        showArchived={showArchived}
      />
      {notifications.results.length > 0 ? (
        notifications.results.map((item, index) => (
          <NotificationItem
            key={`${item.id}-${index}`}
            item={item}
            notifications={notifications}
            index={index}
            markRead={markRead}
            onHide={onHide}
          />
        ))
      ) : (
        <div className="px-4 pt-3 flex flex-col gap-7">
          {showArchived ? (
            <div>
              <p className="text-black font-medium">{t("notification.emptyArchive")}</p>
              <span className="text-gray-400">{t("notification.noArchivedNotifications")}</span>
            </div>
          ) : (
            <div>
              <p className="text-black font-medium">{t("notification.noNewNotifications")}</p>
              <span className="text-gray-400">{t("notification.allUpToDate")}</span>
            </div>
          )}
          <button
            className="w-full btn text-gray-400 hover:text-gray-500 border border-gray-100 hover:border-gray-200"
            onClick={handleClose}>
            <Icon icon={"open_in_new"} /> <span className="ml-1.5">{t("notification.goToRentals")}</span>
          </button>
        </div>
      )}
      <div ref={loadMoreRef}></div>
    </div>
  )
}

export default NotificationPopUp
