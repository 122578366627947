import axios from "axios"
import {BehaviorSubject, Observable, of, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from "src/abstract/api.abstract"
import {DeviceModel, PositionModel, TripModel} from "src/models/geolocation"
import authService from "./auth.service"
import {getAPI} from "src/interceptor"

export class GeolocationService extends ApiAbstract {
  constructor() {
    super("v1/crm/integrations/geolocation")
  }

  private _refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined)

  public devices$: Observable<DeviceModel[]> = authService.logged$.pipe(
    switchMap((logged) =>
      logged
        ? this._refresh$.pipe(
            switchMap(() => getAPI<DeviceModel[]>(axios.get(this.getUrl("devices"), {params: {skip_loader: true}})))
          )
        : of([])
    ),
    shareReplay(1)
  )

  public loadDevices = () => this._refresh$.next()

  async listHistory(deviceId: number, params?: any): Promise<PositionModel[]> {
    const response = await axios.get<PositionModel[]>(this.getUrl(`devices/${deviceId}/positions`), {params})
    return response.data
  }

  async listTrips(params?: any): Promise<TripModel[]> {
    const response = await axios.get<TripModel[]>(this.getUrl("trips"), {
      params
    })
    return response.data
  }

  async createDevice(payload: any): Promise<undefined> {
    const response = await axios.post<undefined>(this.getUrl("devices"), payload)
    this.loadDevices()
    return response.data
  }
}

const geolocationService = new GeolocationService()
export default geolocationService
