import {RentFilter} from "src/pages/settings/settings-rent"
import {OrderStatusColor} from "src/components/shared/constants"

export enum LANG {
  RU = "ru",
  EN = "en"
}

export enum CustomFieldType {
  STRING = "string",
  HTML = "html",
  INTEGER = "integer",
  DECIMAL = "decimal",
  BOOLEAN = "boolean",
  DATE = "date",
  DATETIME = "datetime",
  COLOR = "color",
  CLIENT = "client",
  INVENTORY = "inventory",
  PRODUCT = "product",
  SET = "set"
}

export enum PriceRounding {
  NONE = "none",
  DEFAULT = "default",
  UP = "up",
  DOWN = "down"
}

export enum OrderSplit {
  CREATED_AT = "created_at",
  RENT_START = "rent_start",
  RENT_FACT_START = "rent_fact_start",
  RENT_END = "rent_end",
  RENT_FACT_END = "rent_fact_end"
}

export enum OrderInterface {
  DEFAULT = "default",
  SIMPLE = "simple"
}

export enum InventoryPriority {
  COMPANY = "COMPANY",
  SUBLEASE = "SUBLEASE",
  ALL = "ALL"
}

export enum ProductType {
  INVENTORTY = "inventory",
  GROUP = "group",
  SALE = "sale"
}

export type CustomFieldOption = Record<string, Array<{[key: string]: string | number; cnt: number}>>

export const optionlessFieldTypes: CustomFieldType[] = [
  CustomFieldType.BOOLEAN,
  CustomFieldType.COLOR,
  CustomFieldType.CLIENT,
  CustomFieldType.INVENTORY,
  CustomFieldType.PRODUCT,
  CustomFieldType.SET,
  CustomFieldType.HTML
]

export const defaultCustomField: CustomFieldModel = {
  inventory_group: [],
  inventory_set: [],
  inventory: [],
  service: [],
  category: [],
  client: [],
  client_document: [],
  order: [],
  order_inventory: [],
  order_service: []
}

export interface CustomField {
  name: string
  label: string
  required: boolean
  table: boolean
  filter: boolean
  type: CustomFieldType
  options: any[]
  defaultValue: string
}

export interface CustomFieldModel {
  inventory_group: CustomField[]
  inventory_set: CustomField[]
  inventory: CustomField[]
  service: CustomField[]
  category: CustomField[]
  client: CustomField[]
  client_document: CustomField[]
  order: CustomField[]
  order_inventory: CustomField[]
  order_service: CustomField[]
}

export interface PenaltyOption {
  name: string
  duration: string
  // value: number
}

export interface SettingConfig extends Record<OrderStatusColor, string> {
  name: string
  address: string
  currency: string
  language: LANG
  type: string
  reservation: boolean
  min_order_duration: string
  max_order_duration: string
  penalty: boolean
  penalty_after: boolean
  penalty_default: string
  creation_percent: number
  reservation_percent: number
  issue_percent: number
  recievement_percent: number
  single_issue: boolean
  single_recievement: boolean
  order_client: boolean
  order_inventory_occupation: boolean
  order_inventory_discount: boolean
  order_agent: boolean
  order_interface: OrderInterface
  order_duration: string
  order_deposit: boolean
  order_default_filter: RentFilter
  workshift: boolean
  price_rounding: PriceRounding
  smsc_login: string
  smsc_password: string
  show_client_document: boolean
  custom_fields: CustomFieldModel
  penalty_options: PenaltyOption[]
  bonus_default_amount: number
  bonus_default_percent: number
  bonus_default_usage: number
  rent_inventory_priority: InventoryPriority
  metrics_order_split: OrderSplit
  metrics_order_statuses: number[]
  metrics_order_payment_statuses: number[]
  rent_product_types: ProductType[]
  tax: boolean
  tax_included: boolean
  tax_percent: number
  tg_channel_link: string
  tg_channel_message_template: string
  phone_format: string
}
