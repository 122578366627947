import {useContext, useEffect, useMemo, useState} from "react"
import {MapContext} from "./map"
import {ImageField} from "src/components/shared/image-loader/image"
import geolocationService from "src/services/geolocation.service"
import {TripModel} from "src/models/geolocation"
import {Outlet, useNavigate, useParams} from "react-router"
import Duration from "src/utils/duriation"
import Icon from "src/components/shared/components/material-icon"
import i18n from "src/i18n"
import {useTranslation} from "react-i18next"

const distanceCalc = (val: number) => {
  if (+val >= 1000) return `${(+val / 1000).toFixed(2)} ${i18n.t("gps.formats.km")}`
  if (+val >= 1) return `${Math.floor(+val)} ${i18n.t("gps.formats.m")}`
  return `${(+val * 100).toFixed(0)} ${i18n.t("gps.formats.cm")}`
}

function TripCardComponent({trip}: {trip: TripModel}) {
  const {deviceMap} = useContext(MapContext)
  const {t} = useTranslation()
  const device = useMemo(() => deviceMap[trip.device_id], [trip.device_id, deviceMap])
  const navigate = useNavigate()

  const onFocus = () => navigate(`/geolocation/${trip.device_id}/trips/${trip.id}`)

  return (
    device && (
      <div className="flex gap-5 items-center p-5 border-b border-gray-100 cursor-pointer" onClick={onFocus}>
        <ImageField src={device.inventory.image} className="w-12 h-12 rounded-md" />
        <div className="flex flex-col gap-1 w-full">
          <div className="font-medium text-base mb-1">{device.name}</div>

          <div className="flex gap-1 items-end text-[13px]">
            <span className="font-medium">{t("gps.attributes.distance")}</span>
            <span className="flex-1 border-b border-dashed border-gray-200"></span>
            <span>{distanceCalc(+trip.distance)}</span>
          </div>
          <div className="flex gap-1 items-end text-[13px]">
            <span className="font-medium">{t("gps.attributes.avg_speed")}</span>
            <span className="flex-1 border-b border-dashed border-gray-200"></span>
            <span>
              {Number(trip.speed).toFixed(2)} {t("gps.formats.km")}/{t("gps.formats.hour")}
            </span>
          </div>
          <div className="flex gap-1 items-end text-[13px]">
            <span className="font-medium">{t("gps.attributes.duration")}</span>
            <span className="flex-1 border-b border-dashed border-gray-200"></span>
            <Duration time={trip.duration} />
          </div>
        </div>
      </div>
    )
  )
}

export function TripListComponent() {
  const {t} = useTranslation()
  const {deviceId} = useParams()
  const [trips, setTrips] = useState<TripModel[]>([])
  const [tripMap, setTripMap] = useState<Record<number, TripModel>>({})
  const navigate = useNavigate()
  const onBack = () => navigate("/geolocation")

  useEffect(() => {
    geolocationService.listTrips({device: deviceId}).then((list) => {
      setTrips(list)
      setTripMap(list.reduce((p, c) => ({...p, [c.id]: c}), {}))
    })
    return () => setTrips([])
  }, [deviceId])

  return (
    <>
      <div className="flex gap-2 items-center border-b border-gray-100 p-5 font-semibold">
        <Icon onClick={onBack} className="cursor-pointer text-2xl" icon="chevron_left" />
        <h3 className="text-[22px]">{t("gps.trips")}</h3>
      </div>
      <div className="flex flex-col justify-stretch">
        {trips.map((trip) => (
          <TripCardComponent key={trip.id} trip={trip} />
        ))}
      </div>
      <Outlet context={{tripMap}} />
    </>
  )
}
