import Icon from "../../../../components/shared/components/material-icon"
import {FormDropzone} from "../../../../components/shared/inputs/drag-drop/form-dropzone"
import modalService from "../../../../components/modal/global/modal.service"
import {ConfirmModal} from "../../../../components/modal/global/confirmModal"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import orderImageService from "../../../../services/order-requests/order-images.service"
import {OrderImageModel, OrderMediaType} from "../../../../models/manager/order/order-image.model"

export default function StateDataComponent({
  medias,
  type,
  onDelete
}: {
  medias: OrderImageModel[]
  type: OrderMediaType
  onDelete: (fileId: number, mediaId?: number) => void
}) {
  const {id} = useParams<{id: string}>()
  const {t} = useTranslation()

  const handleOnDelete = (fileId: number, mediaId?: number) => {
    const onConfirm = () => onDelete(fileId, mediaId)
    modalService.open({
      component: (
        <ConfirmModal
          message={t("common.confirm.delete")}
          confirm_text={t("common.delete")}
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  const onSubmit = async (files: File[]): Promise<OrderImageModel[]> => {
    return Promise.all(
      files.map((file) => {
        const formData = new FormData()
        formData.append("resources", file)
        formData.append("type", type.toString())
        return orderImageService.post(id, formData)
      })
    )
  }

  return (
    <div className="flex flex-col gap-5 px-4 pt-5 bg-white mt-[6px]">
      <div className="flex flex-col gap-3 pb-5">
        <FormDropzone<OrderImageModel[]>
          containerLabel={<p className="font-normal text-sm text-gray-400">Фото/видео фиксация до аренды</p>}
          medias={medias
            .filter((media) => media.type === +type)
            .flatMap((media) =>
              media.resources.map(({id, file}) => ({
                fileId: id,
                src: file,
                type: /\.(mp4|webm)$/.test(file) ? "video" : "image",
                mediaId: media.id
              }))
            )}
          onDelete={handleOnDelete}
          onSubmit={onSubmit}
          customContent={
            <div className="relative flex flex-col justify-center items-center gap-[10px] ">
              <div className="flex gap-[10px] items-center text-primary font-medium text-[13px]">
                <Icon icon="add_photo_alternate" />
                <p>Загрузите фото/видео</p>
              </div>
              <p className={"font-normal text-[13px] text-gray-test-350"}>Ограничение по объему файлов 500МБ</p>
              <input type="file" id="fileInput" accept="image/*,video/*" className="hidden h-full w-full absolute" />
            </div>
          }
          downloadBtnNeed={false}
          replaceContent={true}
          imgContainerClasses="w-20 h-20 items-center gap-4"
          navigateBack={true}
        />
      </div>
    </div>
  )
}
