import {OrderStatusColor} from "src/components/shared/constants"

export enum MaintenanceType {
  ONETIME = 0,
  PERIODIC = 1
}

export enum MaintenancePeriodType {
  INTERVAL = 0,
  DISTANCE = 1
}

export interface BaseMaintenanceModel {
  id: number
  name: string
  description: string
  comment: string
  state: any
  interval: string
  inventory: number
  fact_maintenance_date: string
  maintenance_date: string
  time_threshold: string
  request_inventory: any
  responsible: number
  total_distance: number
}

export interface ScheduleModel {
  id: number
  start_at: string
  end_at: string
  completed: boolean
  order_request: {
    id: number
    status: number
    status_color: OrderStatusColor
    payment_status: number
    unique_id: number
    price: number
    price_discount: number
    time_exceed: boolean
  }
}

export interface InventoryModel {
  id: number
  group: number
  name: string
  unique_id: string
  category: number
  image: string
  extra: {}
  sublease_user: number
}

export interface MaintenanceModel extends BaseMaintenanceModel {
  _id?: number | string
}

export interface InventoryMaintenanceModel {
  id: number
  group: number
  name: string
  unique_id: string
  category: number
  image: any
  state: any
  extra: any
  maintenances: BaseMaintenanceModel[]
  washed: number
  tires: number
  type: MaintenanceType
  period_type: MaintenancePeriodType
}
