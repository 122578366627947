import axios from "axios"
import {
  Centrifuge,
  Subscription,
  ConnectedContext,
  DisconnectedContext,
  JoinContext,
  LeaveContext,
  PublicationContext,
  SubscribedContext,
  SubscriptionErrorContext,
  SubscriptionState
} from "centrifuge"
import {toast, ToastContent, ToastOptions} from "react-toastify"
import authService from "./auth.service"
import {firstValueFrom} from "rxjs"
import {isProd} from "src/utils/isProd"
import notificationService from "./notification.service"
import {hasNotification} from "src/utils/detectNotificationAccess"

const endpointApi = process.env.REACT_APP_API_BACKEND
const host = window.location.host

const subdomainPattern = /(wss?:\/\/)([^.]+)\.(.*)/

const wssHost = isProd
  ? `wss://${host.replace("localhost:3000", endpointApi)}`.replace(subdomainPattern, "$1$2.api.$3")
  : `wss://${host.replace("localhost:3000", "api.dev.yume.cloud")}`

const cent = new Centrifuge(`${wssHost}/connection/websocket`, {
  getToken: async () => {
    const profile = await firstValueFrom(authService.profile$)
    if (!profile) return Promise.reject()

    const response = await axios.post("v1/crm/communication/connect/", {}, {params: {skip_error_handling: true}})
    const {token} = await response.data
    return token
  }
})
const subscribtions: Record<string, Subscription> = {}

export function connect(
  onConnect: (ctx: ConnectedContext) => void = (ctx) => console.log("Connected to Centrifugo:", ctx),
  onDisconnect: (ctx: DisconnectedContext) => void = (ctx) => console.log("Disconnected from Centrifugo:", ctx)
): void {
  cent.connect()
  cent.on("connected", onConnect)
  cent.on("disconnected", onDisconnect)
}

export function disconnect(): void {
  cent.disconnect()
}

export function newSubsription(
  channel: string = "notification:yume",
  subscription_api: string = "/v1/crm/communication/subscribe/",
  onPublication: (ctx: PublicationContext) => void = (ctx) => {
    let [message, option]: [ToastContent, ToastOptions] = ["", {}]
    if (ctx && ctx.data && ctx.data.type === "new_order_request") {
      message = "Новая заявка на аренду"
      option = {}
    }
    if (ctx && ctx.data && ctx.data.type === "notification") {
      message = ctx.data?.data?.body
      option = {}
    }

    // toast(message, option)

    if (hasNotification() && Notification.permission === "granted") {
      const notificationData = ctx.data
      const notification = new Notification("Новое сообщение", {
        body: notificationData.body || "У вас новое уведомление.",
        icon: "/logo192.png",
        tag: notificationData.id
      })

      notification.onclick = () => {
        window.focus()
      }
    }

    // notificationService.getCount()
  },
  onSubscribed: (ctx: SubscribedContext) => void = (ctx) => console.log(`Subscribed to ${ctx.channel}`, ctx),
  onJoin: (ctx: JoinContext) => void = (ctx) => console.log(`Joined ${ctx.channel}:`, ctx.info.user),
  onLeave: (ctx: LeaveContext) => void = (ctx) => console.log(`Leaved ${ctx.channel}:`, ctx.info.user),
  onError: (err: SubscriptionErrorContext) => void = (err) => console.error(`Subscription error on ${err.channel}`, err)
) {
  if (channel in subscribtions && subscribtions[channel].state === SubscriptionState.Subscribing) return
  if (channel in subscribtions && subscribtions[channel].state === SubscriptionState.Subscribed) return

  if (!(channel in subscribtions)) {
    subscribtions[channel] = cent.newSubscription(channel, {
      getToken: async () => {
        const profile = await firstValueFrom(authService.profile$)
        if (!profile) return Promise.reject()

        const response = await axios.post(subscription_api, {}, {params: {skip_error_handling: true}})
        const {token} = await response.data
        return token
      }
    })
  }

  subscribtions[channel].on("publication", onPublication)
  subscribtions[channel].on("subscribed", onSubscribed)
  subscribtions[channel].on("error", onError)
  subscribtions[channel].subscribe()

  subscribtions[channel].on("join", onJoin)
  subscribtions[channel].on("leave", onLeave)
}

export function unsubsribe(channel: string = "notification:yume") {
  if (!(channel in subscribtions)) return
  if (subscribtions[channel].state === SubscriptionState.Unsubscribed) return
  subscribtions[channel].unsubscribe()
}

export default cent
