import moment from "moment"
import {InputHTMLAttributes} from "react"
import {RegisterOptions, useFormContext} from "react-hook-form"
import {isNil} from "src/utils/isNil"
import {RangePicker} from "../antd-custom"

interface FormControlConfig extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  rootclassname?: string
  name_start: string
  name_end: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  allowClear?: boolean
  onChange?: (value: [moment.Moment, moment.Moment] | null) => void
}

export function FormControlDaterange(config: FormControlConfig) {
  const {watch, reset} = useFormContext()
  const values = watch()
  const start = values[config.name_start] ? moment(values[config.name_start]) : undefined
  const end = values[config.name_end] ? moment(values[config.name_end]) : undefined

  const onChange = (days: [moment.Moment, moment.Moment] | null) => {
    if (isNil(days)) {
      reset({
        ...values,
        [config.name_start]: null,
        [config.name_end]: null
      })
      return
    }
    const start = days[0]
    const end = days[1]
    reset({
      ...values,
      [config.name_start]: start.format("YYYY-MM-DD"),
      [config.name_end]: end.format("YYYY-MM-DD")
    })
    if (config.onChange) config.onChange(days)
  }

  return (
    <div className={config.rootclassname || ""}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <RangePicker
        className={config.className}
        style={config.style}
        allowClear={config.allowClear || false}
        value={[start, end]}
        onChange={onChange}
      />
    </div>
  )
}
