import modalService from "../modal/global/modal.service"
import workshiftService, {WorkshiftModel} from "src/services/workshift.service"
import {ConfirmModal} from "../modal/global/confirmModal"
import {useContext, useEffect, useState} from "react"
import {WorkshiftReceipt} from "./workshift-receipt"
import onReceiptPrint from "src/services/printer"
import {createRoot} from "react-dom/client"
import html2canvas from "html2canvas"
import toDateTime from "src/utils/date-time"
import {ConfigContext} from "src"

export default function WorkshiftDetail() {
  const {settings} = useContext(ConfigContext)
  const [workshift, setWorkshift] = useState<WorkshiftModel>()

  const onDownload = (canvas: HTMLCanvasElement, shift: WorkshiftModel) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.setAttribute("style", "display: none")
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      a.href = url
      a.download = `Отчет смены ${toDateTime(shift.start_at)} - ${toDateTime(shift.end_at)} (${shift.profile})`
      a.click()
      URL.revokeObjectURL(url)
    })
  }

  const onEnd = () => {
    const onConfirm = () =>
      workshiftService
        .end()
        .then(() => workshiftService.get(workshift.id))
        .then((shift) => {
          const element = createRoot(document.getElementById("receipt-wrapper"))
          element.render(<WorkshiftReceipt settings={settings} type="download" workshift={shift} />)

          setTimeout(() => {
            html2canvas(document.getElementById("capture"), {scale: 2}).then(async (canvas) => {
              onReceiptPrint(canvas, (canvas) => onDownload(canvas, shift))
              element.unmount()
            })
          }, 100)
        })

    modalService.closeModal()
    modalService.open({
      component: (
        <ConfirmModal
          message="Вы уверены, что хотите завершить текущую смену."
          delete={true}
          confirm_text="Завершить"
          onConfirm={onConfirm}
        />
      )
    })
  }

  useEffect(() => {
    workshiftService.getWorkshift()
    const sub = workshiftService.workshift$.subscribe(setWorkshift)
    return () => sub.unsubscribe()
  }, [])

  return (
    workshift && (
      <div className="flex flex-col">
        <div className="flex w-full justify-center mb-3">
          <WorkshiftReceipt
            type="download"
            settings={settings}
            workshift={workshift}
            paddingWidth={8}
            className="shadow"
          />
        </div>

        <div className="btn btn-primary btn-color-white" onClick={onEnd}>
          Завершить смену
        </div>
      </div>
    )
  )
}
