import axios from "axios"
import {CrudInterface} from "../interfaces/crud.interface"
import {ListModel} from "../models/common"
import {ApiAbstract} from "../abstract/api.abstract"
import {ServiceModel} from "src/models/manager/order/order-request-service-new"

export class ManageServicesService extends ApiAbstract implements CrudInterface<ServiceModel> {
  constructor() {
    super("v1/crm/services")
  }

  public async list(params?: any): Promise<ListModel<ServiceModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async get(id: number): Promise<ServiceModel> {
    const response = await axios.get(this.getUrl(id))
    return response.data
  }
  public async post(payload: any): Promise<ServiceModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any, params?: any): Promise<ServiceModel> {
    const response = await axios.patch(this.getUrl(id), payload, {params})
    return response.data
  }
  public async delete(id: number): Promise<ServiceModel> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async createTarif(serviceId: number, payload: any): Promise<ServiceModel> {
    const response = await axios.post(this.getUrl(`${serviceId}/tarifs`), payload)
    return response.data
  }
  public async updateTarif(serviceId: number, tarifId: number, payload: any): Promise<ServiceModel> {
    const response = await axios.patch(this.getUrl(`${serviceId}/tarifs/${tarifId}`), payload)
    return response.data
  }
  public async deleteTarif(serviceId: number, id: number): Promise<ServiceModel> {
    const response = await axios.delete(this.getUrl(`${serviceId}/tarifs/${id}`))
    return response.data
  }
}

const servicesService = new ManageServicesService()
export default servicesService
