/**
 * Конвертирует количество дней (включая дробную часть) в строку формата "ДД ЧЧ:ММ:СС".
 *
 * @param {number} totalDays - Общее количество дней, где целая часть представляет полные дни,
 * а дробная часть соответствует дополнительным часам, минутам и секундам.
 *
 * @returns {string} Строка с форматом "ДД ЧЧ:ММ:СС", представляющая полные дни, часы, минуты и секунды.
 *
 * @example
 * // Конвертирует 2.567 дней в строку
 * const intervalString = daysToTimeString(2.567);
 * // intervalString === "2 13:36:28"
 */

export function daysToTimeString(totalDays: number): string {
  const days = Math.floor(totalDays)
  const remainingDays = totalDays - days
  const hours = Math.floor(remainingDays * 24)
  const minutes = Math.floor((remainingDays * 1440) % 60)
  const seconds = Math.floor((remainingDays * 86400) % 60)

  return `${days} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
}
