import {useMemo} from "react"
import {useSearchParams} from "react-router-dom"

export default function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams()
  const keys: string[] = useMemo(() => Object.keys(Object.fromEntries(searchParams)), [searchParams])
  const query: Record<string, any> = useMemo(
    () =>
      Object.fromEntries(
        keys.map((key) => [
          key,
          searchParams.get(key).split(",").length === 1 ? searchParams.get(key) : searchParams.get(key).split(",")
        ])
      ),
    [searchParams, keys]
  )
  const setParams = (params) => setSearchParams(params, {replace: false, relative: "route"})
  return {searchParams: query, setSearchParams: setParams}
}
