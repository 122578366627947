import axios from "axios"
import {ApiAbstract} from "../../abstract/api.abstract"
import {OrderImageModel} from "../../models/manager/order/order-image.model"

export class ManageOrderImageService extends ApiAbstract {
  constructor() {
    super("v1/crm/requests")
  }

  public async fetch(orderId: string, params?: any): Promise<OrderImageModel[]> {
    const response = await axios.get(this.getUrl(`${orderId}/media`), {params})
    return response.data
  }
  public async post(orderId: string, payload: any): Promise<OrderImageModel> {
    const response = await axios.post(this.getUrl(`${orderId}/media`), payload)
    return response.data
  }
  public async deleteMedia(orderId: number, mediaId: number): Promise<OrderImageModel[]> {
    const response = await axios.delete(this.getUrl(`${orderId}/media/${mediaId}`))
    return response.data
  }
  public async deleteFile(orderId: number, mediaId: number, fileId: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`${orderId}/media/${mediaId}/files/${fileId}`))
    return response.data
  }
}

const orderImageService = new ManageOrderImageService()
export default orderImageService
