import {ImageField} from "src/components/shared/image-loader/image"
import {IntegrationModel} from "src/models/integration"
import modalService from "src/components/modal/global/modal.service"
import {useNavigate} from "react-router"
import integrationService from "src/services/integration"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import {WazzupGuideModal} from "./wazzup/guide"
import "./styles.scss"

export function IntegrationConnection({integration}: {integration: IntegrationModel}) {
  const {t} = useTranslation()
  const form = useForm({defaultValues: {}})
  const navigate = useNavigate()
  const fields = integration.extra ? integration.extra.fields : []
  const fieldsMap = integration.extra ? integration.extra.specs.reduce((p, c) => ({...p, [c.name]: c}), {}) : {}

  const onInstall = form.handleSubmit(async (payload) => {
    await integrationService.connect(integration.code, {extra: payload})
    const link = integration.extra?.link
    navigate(link || `/integrations/${integration.code}`)
    modalService.closeAllModal()
  })

  const onCancel = () => modalService.closeModal()

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-bold mb-3">{t("integration.connect_integration")}</h4>

        <div className="card-bio flex gap-2 justify-between items-center">
          <div className="flex items-center gap-4">
            <ImageField
              draggable={false}
              src={integration.icon}
              className="w-12 h-12 rounded-lg overflow-hidden object-contain"
            />

            <div className="flex gap-2 flex-col">
              <div className="text-lg font-bold">{integration.name}</div>
              {integration.comment && <div className="color-gray-400">{integration.comment}</div>}
            </div>
          </div>

          {integration.extra && "is_yume_cloud" in integration.extra && integration.extra["is_yume_cloud"] && (
            <span className="color-primary fw-semibold text-nowrap">{t("integration.from_yume")}</span>
          )}
        </div>

        {integration.code === "wazzup" && <WazzupGuideModal />}

        {fields.map((key) => (
          <FormControl
            className="form-control"
            key={key}
            name={key}
            placeholder={fieldsMap[key].label}
            params={{required: true, ...fieldsMap[key].params}}
          />
        ))}

        <div className="flex gap-2 justify-end">
          <button className="btn btn-card" onClick={onCancel}>
            {t("common.cancel")}
          </button>
          <button className="btn btn-primary btn-color-white" onClick={onInstall} disabled={!form.formState.isValid}>
            {t("common.install")}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
