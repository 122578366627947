import moment from "moment"
import {useEffect, useState} from "react"
import modalService from "../modal/global/modal.service"
import {WorkshiftModel} from "src/services/workshift.service"
import WorkshiftDetail from "./workshift-detail"
import Icon from "src/components/shared/components/material-icon"
import {Tooltip} from "antd"
import {useTranslation} from "react-i18next"

export default function WorkshiftTimer({workshift, collapse}: {workshift: WorkshiftModel; collapse: boolean}) {
  const [duration, setDuration] = useState(
    moment.duration(moment.now() - moment(workshift.start_at).valueOf(), "milliseconds").humanize()
  )
  const {t} = useTranslation()

  useEffect(() => {
    const interval = setInterval(
      () =>
        setDuration(moment.duration(moment.now() - moment(workshift.start_at).valueOf(), "milliseconds").humanize()),
      1000 * 60
    )
    return () => clearInterval(interval)
  })

  const onModal = () => modalService.open({component: <WorkshiftDetail />})

  const content = (
    <div className="menu-profile mb-2" onClick={onModal}>
      <div className="menu-profile-icon">
        <Icon icon="schedule" className="text-base color-white" />
      </div>
      <div className="menu-profile-name">{t("aside.workshift", {duration})}</div>
    </div>
  )

  return (
    workshift &&
    workshift.start_at &&
    (collapse ? (
      <Tooltip placement="right" title={duration}>
        {content}
      </Tooltip>
    ) : (
      content
    ))
  )
}
