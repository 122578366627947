import i18n from "i18next"

export enum CarWashState {
  NOT_WASHED = 0,
  WASHED = 1
}

export enum CarTireState {
  UNDEFINED = 0,
  SUMMER = 1,
  WINTER = 2
}

export enum MaintenanceState {
  NOT_VERIFIED = 0,
  GOOD = 1,
  BAD = 2
}

type WashLabel = {
  value: CarWashState
  label: string
}

type TireLabel = {
  value: CarTireState
  label: string
}

let washedLabels: WashLabel[] = []
let tiresLabels: TireLabel[] = []

const CarWashStateLabel: Record<CarWashState, string> = {
  [CarWashState.NOT_WASHED]: "maintenance.selects.washed.not_washed",
  [CarWashState.WASHED]: "maintenance.selects.washed.washed"
}

const CarTireStateLabel: Record<CarTireState, string> = {
  [CarTireState.UNDEFINED]: "maintenance.selects.tires.summer",
  [CarTireState.SUMMER]: "maintenance.selects.tires.summer",
  [CarTireState.WINTER]: "maintenance.selects.tires.winter"
}

i18n.on("loaded", () => {
  washedLabels = [
    {value: CarWashState.NOT_WASHED, label: i18n.t("maintenance.selects.washed.not_washed")},
    {value: CarWashState.WASHED, label: i18n.t("maintenance.selects.washed.washed")}
  ]

  tiresLabels = [
    {value: CarTireState.SUMMER, label: i18n.t("maintenance.selects.tires.summer")},
    {value: CarTireState.WINTER, label: i18n.t("maintenance.selects.tires.winter")}
  ]
})

export {washedLabels, tiresLabels, CarWashStateLabel, CarTireStateLabel}
