import {MaintenanceType} from "src/models/manager/maintenance/maintenance.model"
import {OptionChoose} from "../option-choose"

export default function MaintenanceTypeSelector({
  value,
  onChange,
  options
}: {
  value: MaintenanceType
  onChange: (value: MaintenanceType) => void
  options: Array<{
    label: string
    value: MaintenanceType
    hint: string
  }>
}) {
  return (
    <OptionChoose<MaintenanceType>
      onChange={onChange}
      options={options}
      value={value}
      textClassName="font-medium text-test-black"
      hintClassName="font-normal text-sm text-gray-test-350"
    />
  )
}
