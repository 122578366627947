import {ActionMeta, Props} from "react-select"
import {useFormContext, Controller, RegisterOptions} from "react-hook-form"
import {colourStyles} from "./form-select-color-style"
import {useState} from "react"
import {isNil} from "src/utils/isNil"
import CustomSelect from "./select/select"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"

export interface FormSelectConfig<T> extends Props {
  label?: string
  name: string
  params?: RegisterOptions<any, any>
  options: T[]
  rootclassname?: string
  modalProps?: ExtendedModalConfig

  disabled?: boolean
}

export default function FormSelect<T>(config: FormSelectConfig<T>) {
  const {control} = useFormContext()
  const [selectedOption, setSelectedOption] = useState<T | T[]>(null)
  const onModal = config.modalProps ? () => modalService.open(config.modalProps) : undefined

  return (
    <div className={config.className}>
      <Controller
        name={config.name}
        control={control}
        rules={config.params}
        render={({field: {onBlur, value, onChange, ref}, fieldState, formState}) => (
          <CustomSelect
            isClearable
            isSearchable
            name={config.name}
            styles={colourStyles(!isNil(fieldState.error))}
            {...config}
            ref={ref}
            value={config.value ?? selectedOption ?? value}
            onChange={(val: T | T[], action: ActionMeta<any>) => {
              setSelectedOption(val)
              if (Array.isArray(val)) {
                onChange(val.map((obj) => config.getOptionValue(obj)))
              } else {
                onChange(config.getOptionValue(val))
              }
              if (!isNil(config.onChange)) config.onChange(val, action)
            }}
            onBlur={onBlur}
            isDisabled={config.disabled}
            options={config.options}
            onModal={onModal}
          />
        )}
      />
    </div>
  )
}
