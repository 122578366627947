import {useContext, useEffect} from "react"
import {MapContext} from "./map"
import {useOutletContext, useParams} from "react-router"
import geolocationService from "src/services/geolocation.service"
import {TripModel} from "src/models/geolocation"
import moment from "moment"

function haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const toRadians = (degrees) => degrees * (Math.PI / 180)

  const R = 6371 // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in kilometers
  return distance
}

function getZoomLevel(distance: number): number {
  if (distance > 20000) return 1
  if (distance > 10000) return 2
  if (distance > 5000) return 3
  if (distance > 2500) return 4
  if (distance > 1000) return 5
  if (distance > 500) return 6
  if (distance > 250) return 7
  if (distance > 100) return 8
  if (distance > 50) return 9
  if (distance > 25) return 10
  if (distance > 12) return 11
  if (distance > 6) return 12
  if (distance > 3) return 13
  if (distance > 1.5) return 14
  if (distance > 0.75) return 15
  if (distance > 0.3) return 16
  return 17
}

export function DeviceTripComponent() {
  const {tripId} = useParams()
  const {mapRef, setCurrentDeviceId} = useContext(MapContext)
  const {tripMap} = useOutletContext<{tripMap: Record<number, TripModel>}>()

  useEffect(() => {
    if (!mapRef.current) return

    const trip = tripMap[+tripId]
    if (!trip) return
    setCurrentDeviceId(undefined)

    const start = moment(trip.start.fixtime).format("YYYY-MM-DD HH:mm:ss+00:00")
    const end = moment(trip.end.fixtime).format("YYYY-MM-DDTHH:mm:ss+00:00")

    geolocationService.listHistory(trip.device_id, {start_at: start, end_at: end}).then((positions) => {
      if (mapRef.current && mapRef.current.getLayer("route")) mapRef.current.removeLayer("route")
      if (mapRef.current && mapRef.current.getSource("route")) mapRef.current.removeSource("route")

      mapRef.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: positions.map((p) => [p.longitude, p.latitude])
          }
        }
      })

      mapRef.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#a165fd",
          "line-width": 4
        }
      })

      if (positions.length > 0) {
        const lat1 = positions.reduce((p, c) => (p > c.latitude ? p : c.latitude), positions[0].latitude)
        const lat2 = positions.reduce((p, c) => (p < c.latitude ? p : c.latitude), positions[0].latitude)
        const long1 = positions.reduce((p, c) => (p > c.longitude ? p : c.longitude), positions[0].longitude)
        const long2 = positions.reduce((p, c) => (p < c.longitude ? p : c.longitude), positions[0].longitude)

        const zoom = getZoomLevel(haversineDistance(lat1, long1, lat2, long2))
        mapRef.current.flyTo({
          center: [(long1 + long2) / 2, (lat1 + lat2) / 2],
          zoom
        })
      }
    })
  }, [tripId, mapRef, tripMap, setCurrentDeviceId])

  return <></>
}
