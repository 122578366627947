import {PropsWithChildren, useEffect, useState} from "react"
import {SharedRentalPointSelect} from "src/components/shared/components/select/rental-point"
import authService, {ProfileModel} from "src/services/auth.service"
import {StylesConfig} from "react-select"

export function AsyncRentalPoint({
  name,
  label,
  params,
  placeholder,
  isSearchable,
  styles,
  className
}: PropsWithChildren<{
  name: string
  label?: string
  params?: any
  placeholder?: string
  isSearchable?: boolean
  styles?: StylesConfig
  className?: string
}>): JSX.Element {
  const [profile, setProfile] = useState<ProfileModel>(null)

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  return (
    profile &&
    profile.is_superuser && (
      <SharedRentalPointSelect
        className={className ? className : "flex-1"}
        label={label}
        name={name}
        isSearchable={isSearchable || true}
        isClearable={true}
        params={params}
        placeholder={placeholder}
        styles={styles}
      />
    )
  )
}
