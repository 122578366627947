import {ImageField} from "src/components/shared/image-loader/image"
import guide1 from "src/assets/images/integrations/wazzup_guide_1.png"
import guide2 from "src/assets/images/integrations/wazzup_guide_2.png"
import guide3 from "src/assets/images/integrations/wazzup_guide_3.png"

export function WazzupGuideModal() {
  return (
    <div className="flex gap-3 flex-col">
      <div className="flex gap-3 flex-col">
        <div className="flex flex-col gap-2">
          <h4 className="mb-2 text-xl font-semibold">Как начать пользоваться?</h4>
          <a
            className="text-[14px] font-medium text-primary ml-2"
            href="https://wazzup24.ru/?utm_p=3dPlUn"
            target="_blank"
            rel="noreferrer">
            1) Зарегистрируйтесь в Wazzup
          </a>
          <div className="text-[14px] font-medium ml-2">2) Тестируйте сервис бесплатно в течение трех дней</div>
          <a
            className="text-[14px] font-medium text-primary ml-2"
            href="https://wazzup24.ru/pricing/?utm_p=3dPlUn&utm_medium=crm&utm_campaign=marketplace6"
            target="_blank"
            rel="noreferrer">
            3) Выберите и оплатите
          </a>
        </div>

        <hr className="text-gray-100" />

        <div className="flex flex-col gap-4">
          <h4 className="mb-0 text-xl font-semibold">Как добавить API ключ?</h4>
          <ImageField
            src={guide1}
            className="w-full object-contain rounded-lg shadow border border-gray-100"
            draggable={false}
          />
          <ImageField
            src={guide2}
            className="w-full object-contain rounded-lg shadow border border-gray-100"
            draggable={false}
          />
          <ImageField
            src={guide3}
            className="w-full object-contain rounded-lg shadow border border-gray-100"
            draggable={false}
          />
        </div>
      </div>
    </div>
  )
}
