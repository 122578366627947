import {useState, useEffect} from "react"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {ModalComponent} from "./modal.v2"

export const GlobalModal = () => {
  const [modals, setModals] = useState<Array<[string, boolean, ModalConfig]>>([])

  useEffect(() => {
    if (!modalService.modals$) return
    if (!modalService.modals$.value) return
    const sub = modalService.modals$.subscribe(setModals)
    return () => sub.unsubscribe()
  }, [])

  return (
    <>
      {modals.map(([key, show, params]) => (
        <ModalComponent key={key} index={key} show={show} {...params} />
      ))}
    </>
  )
}
