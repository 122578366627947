import {useEffect, useState} from "react"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {Popover} from "antd"
import clsx from "clsx"

function MaintenanceExtraPopover<T extends number>({
  initialState,
  options,
  label,
  icon,
  render,
  onSubmit
}: {
  initialState: T
  options: T[]
  label: Record<T, string>
  icon?: string | Record<T, string>
  render: (state: T, open?: boolean) => JSX.Element
  onSubmit: (value: T) => Promise<any>
}) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<T>(initialState)

  const onSave = async () => {
    setOpen(false)
    await onSubmit(state)
  }

  useEffect(() => setState(initialState), [initialState])

  return (
    <Popover
      trigger="click"
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
        if (!open && state !== initialState) setState(initialState)
      }}
      className="flex gap-2 items-center"
      arrow={false}
      overlayInnerStyle={{padding: "0", overflow: "hidden"}}
      placement="bottom"
      content={
        <div className="flex flex-col">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => setState(option)}
              className={clsx("flex gap-2 p-3 items-center justify-between border-b border-gray-100 last:border-none", {
                "text-primary": state === option
              })}>
              <div className="flex items-center gap-2">
                {icon && <Icon icon={typeof icon === "string" ? icon : icon[option]} />}
                <span>{t(label[option] as any)}</span>
              </div>
              <span
                className={clsx(
                  "flex items-center justify-center w-5 h-5 rounded-full border",
                  state === option ? "bg-primary border-primary" : "border-gray-300"
                )}>
                {state === option && <span className="w-2.5 h-2.5 bg-white rounded-full"></span>}
              </span>
            </button>
          ))}
          <button onClick={onSave} className="p-3 bg-primary text-white rounded-b-lg rounded-t-none">
            {t("maintenance.save_changes")}
          </button>
        </div>
      }>
      {render(state, open)}
    </Popover>
  )
}

export default MaintenanceExtraPopover
