import {useForm, FormProvider} from "react-hook-form"
import {FormControl} from "src/components/shared/inputs/form-control"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {FormControlDuration} from "src/components/shared/inputs/form-control-duration"

type EditProps = {
  mode: "edit"
  time: number | string
  name?: string
  id?: number
}

type AddProps = {
  mode: "add"
  name?: never
  id?: never
  time?: never
}
type CommonProps = {
  mode: "edit" | "add"
  withName?: boolean
  onPost?: (payload: any) => void
}
type Props = CommonProps & (EditProps | AddProps)
type FormValues = {
  time: moment.Duration | string
  name?: string
}

export const ConfigRentalDurationAddOrEditComponent = ({mode, time, ...props}: Props) => {
  const form = useForm<FormValues>({
    defaultValues:
      mode === "edit"
        ? {
            time: moment.duration(time, "seconds"),
            name: props.name
          }
        : {
            time: "1 00:00:00",
            name: null
          }
  })
  const {handleSubmit} = form
  const {t} = useTranslation()

  const title = t(mode === "add" ? "configuration.lease_terms.header_add" : "configuration.lease_terms.header_edit")
  const buttonTitle = t(mode === "add" ? "common.add" : "common.save")

  const onSubmit = handleSubmit((payload) => props.onPost(payload))

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xl font-semibold">{title}</div>

      <FormProvider {...form}>
        <div className="flex-col flex gap-4">
          {props.withName && (
            <FormControl
              label={t("common.input.name")}
              className="form-control"
              type="text"
              rootclassname="col"
              name="name"
              params={{required: true}}
              required
              placeholder="Введите название периода"
            />
          )}
          <FormControlDuration name="time" rootclassname="col" className="form-control" params={{required: true}} />

          <div className="flex gap-2 justify-end">
            <button
              onClick={onSubmit}
              disabled={!form.formState.isValid}
              style={{width: "max-content"}}
              className="btn btn-primary btn-color-white">
              {buttonTitle}
            </button>
          </div>
        </div>
      </FormProvider>
    </div>
  )
}
