import {useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useDebounce} from "src/hooks/useDebounce"
import useQueryParams from "src/hooks/useQuertParams"
import {ListParams} from "src/models/common"
import {Outlet, useNavigate} from "react-router"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import metricsService from "src/services/metrics/products-parts.service"
import {InventoryGroupEarningAggregateModel} from "src/models/manager/metrics/payback-product"
import {toPrice} from "src/utils/price"
import {toPercent} from "src/utils/percent"
import CardWrapper from "src/components/shared/components/card"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useTranslation} from "react-i18next"
import Duration from "src/utils/duriation"
import {Segmented} from "src/components/shared/antd-custom"
import {CardSection} from "src/components/shared/components/card/card-content"

export default function InventoryPaybackWrapper() {
  const navigate = useNavigate()
  const page = useCurrentRoute()
  const {t} = useTranslation()
  const [general, setGeneral] = useState<InventoryGroupEarningAggregateModel>()
  const {searchParams} = useQueryParams()
  const form = useForm<ListParams>({
    defaultValues: {page: 1, pageSize: 10, search: "", ...searchParams}
  })
  const {watch, reset} = form
  const values = watch()

  const searchDebounce = useDebounce(watch("search"), 500)
  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  const listPaybackGeneral = (params: any) => metricsService.listInventoryGroupPaybackGeneral(params).then(setGeneral)

  const sectionDataConfig: CardSection[] = general
    ? [
        {
          title: t("metrics.inventory.payback.general.title_common"),
          values: [
            {
              label: t("metrics.inventory.payback.general.group_count"),
              value: <div className="flex gap-2 flex-col">{general.group_cnt}</div>
            },
            {
              label: t("metrics.inventory.payback.general.inventory_count"),
              value: <div className="flex gap-2 flex-col">{general.inventory_cnt}</div>
            }
          ]
        },
        {
          title: t("metrics.inventory.payback.general.title_finance"),
          values: [
            {
              label: t("metrics.inventory.payback.general.buy_price_sum"),
              value: <div className="flex gap-2 flex-col">{toPrice(general.buy_price_sum)}</div>
            },
            {
              label: t("metrics.inventory.payback.general.earning_sum"),
              value: <div className="flex gap-2 flex-col">{toPrice(general.earning_sum)}</div>
            },
            {
              label: t("metrics.inventory.payback.general.overdue_sum"),
              value: <div className="flex gap-2 flex-col">{toPrice(general.overdue_sum)}</div>
            }
          ]
        },
        {
          title: t("metrics.inventory.payback.general.title_payback"),
          values: [
            {
              label: t("metrics.inventory.payback.general.efficency_sum"),
              value: <div className="flex gap-2 flex-col">{toPercent(general.efficency_sum)}</div>
            },
            {
              label: t("metrics.inventory.payback.general.efficency_sum"),
              value: (
                <div className="flex gap-2 flex-col">
                  <Duration time={general.payback_duration_avg} />
                </div>
              )
            }
          ]
        }
      ]
    : []

  useEffect(() => {
    listPaybackGeneral(values)

    const sub = watch((params, {name}) => {
      if (name !== "search") listPaybackGeneral(params)
    })
    return () => {
      sub.unsubscribe()
      setGeneral(undefined)
    }
  }, [])

  return (
    <FormProvider {...form}>
      <div className="flex gap-2 mb-3">
        <SharedCategorySelect
          className="flex-1 min-w-48"
          placeholder={t("common.select.category")}
          name="category"
          isClearable={true}
          isSearchable={false}
        />
        <FormControlCheckbox
          name="sublease"
          className="form-check-input"
          label={t("metrics.inventory.payback.filter.sublease")}
        />
        <FormControlCheckbox
          name="deleted"
          className="form-check-input"
          label={t("metrics.inventory.payback.filter.archived")}
        />
        <div className="flex-1"></div>
        <FormControl
          rootclassname="min-w-48"
          icon="search"
          name="search"
          className="form-control"
          placeholder={t("common.input.search")}
        />
      </div>

      <CardWrapper cardSectionData={sectionDataConfig} />

      <Outlet
        context={{
          title: (
            <Segmented
              value={page}
              className="bg-gray-50 self-center rounded-md mr-auto"
              onChange={(option) => navigate(option)}
              style={{
                background: "var(--color-bg-light)",
                padding: 4,
                borderRadius: 6
              }}
              options={[
                {
                  label: t("metrics.inventory.payback.pages.group"),
                  value: "group",
                  className: "p-1 font-medium text-black"
                },
                {
                  label: t("metrics.inventory.payback.pages.inventory"),
                  value: "inventory",
                  className: "p-1 font-medium text-black"
                }
              ]}
            />
          )
        }}
      />
    </FormProvider>
  )
}
