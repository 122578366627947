import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {InventoryTarifTimePeriod} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import secondsToHuman from "src/utils/secondsToHuman"
import modalService, {ExtendedModalConfig} from "../../../modal/global/modal.service"
import GenericEntityService from "../../../../services/generic-entity.service"
import {ConfigRentalDurationAddOrEditComponent} from "../../../../pages/config/config-rental-duration-add-or-edit"

type RemovalKeys = "listOptions" | "getValue" | "getOptionLabel" | "getOptionValue" | "placeholder"
const timePeriod: Observable<InventoryTarifTimePeriod[]> = appService.getConstant("TARIF_TIME_PERIODS")

export interface SharedTimePeriodProps extends Omit<FormSelectAsyncConfig<InventoryTarifTimePeriod>, RemovalKeys> {
  addOption?: boolean
}

export function SharedTimePeriodSelect(config: SharedTimePeriodProps) {
  const {t} = useTranslation()
  const service = new GenericEntityService<InventoryTarifTimePeriod>("v1/crm/time_periods")
  const onCreate = (payload) => {
    service.post(payload).then(() => {
      appService.loadConstants()
    })
  }

  const modalProps: ExtendedModalConfig = {
    text: "Новый период",
    className: "gap-2",
    props: {dialogClassName: "w-[640px]"},
    component: (
      <ConfigRentalDurationAddOrEditComponent
        withName
        mode="add"
        onPost={(payload) => {
          onCreate(payload)
          modalService.closeModal()
        }}
      />
    )
  }

  return (
    <FormSelectAsync<InventoryTarifTimePeriod>
      {...config}
      modalProps={config.addOption && modalProps}
      listOptions={() => timePeriod}
      placeholder={t("common.select.placeholder.period")}
      getValue={(id: number) => firstValueFrom(timePeriod.pipe(map((list) => list.find((obj) => obj.id === +id))))}
      getOptionLabel={(option: InventoryTarifTimePeriod) =>
        option ? `${option.name} (${secondsToHuman(option.time)})` : null
      }
      getOptionValue={(option: InventoryTarifTimePeriod) => (option ? String(option.id) : null)}
    />
  )
}
