import {useContext, useEffect, useMemo} from "react"
import {DeviceModel, PositionAttribute} from "src/models/geolocation"
import Icon from "src/components/shared/components/material-icon"
import toDateTime from "src/utils/date-time"
import {MapContext} from "./map"
import {Link} from "react-router-dom"
import {Tooltip} from "antd"
import i18n from "src/i18n"
import {useTranslation} from "react-i18next"

const attributeLabel: Record<PositionAttribute, string> = {
  [PositionAttribute.batteryLevel]: i18n.t("gps.attributes.battery"),
  [PositionAttribute.distance]: i18n.t("gps.attributes.total_distance"),
  [PositionAttribute.totalDistance]: i18n.t("gps.attributes.total_distance"),
  [PositionAttribute.motion]: i18n.t("gps.attributes.speed")
}

const attributeFormatter: Record<PositionAttribute, (val: string | number) => string> = {
  [PositionAttribute.batteryLevel]: (val) => `${Number(val).toFixed(0)} %`,
  [PositionAttribute.distance]: (val) => `${Number(val).toFixed(0)} m`,
  [PositionAttribute.totalDistance]: (val: number) => {
    if (+val >= 1000) return `${(+val / 1000).toFixed(2)} km`
    if (+val >= 1) return `${Math.floor(+val)} m`
    return `${(+val * 100).toFixed(0)} cm`
  },
  [PositionAttribute.motion]: (val) => `${Number(val).toFixed(0)} км/час`
}

const attrs = [PositionAttribute.batteryLevel, PositionAttribute.totalDistance]

export function CurrentDeviceComponent() {
  const {t} = useTranslation()
  const {mapRef, deviceMap, currentDeviceId, setCurrentDeviceId} = useContext(MapContext)
  const currentDevice: DeviceModel = useMemo(
    () => (currentDeviceId ? deviceMap[currentDeviceId] : undefined),
    [deviceMap, currentDeviceId]
  )

  const attributes: Array<{key: string; content: string | number}> = useMemo(
    () =>
      currentDevice
        ? Object.keys(currentDevice.position.attributes)
            .filter((k: PositionAttribute) => attrs.includes(k))
            .map((k: PositionAttribute) => ({
              key: attributeLabel[k],
              content: attributeFormatter[k](currentDevice.position.attributes[k])
            }))
        : [],
    [currentDevice]
  )

  useEffect(() => {
    if (!mapRef.current) return
  }, [])

  return (
    currentDevice && (
      <div className="absolute rounded-lg shadow-lg bg-white left-[50%] bottom-16 -translate-x-[50%] w-[400px] min-h-32 z-20">
        <div className="flex gap-2 justify-between items-start p-4 border-b-2 border-gray-100">
          <div className="flex gap-1 flex-col">
            <h2 className="mb-0 text-primary font-medium text-base overflow line-clamp-1">
              {currentDevice.name} ({currentDevice.inventory.name})
            </h2>
            <div className="text-[14px]">
              {currentDevice.uniqueid} | {currentDevice.inventory.unique_id}
            </div>
          </div>
          <Icon
            icon="close"
            className="cursor-pointer text-base font-extrabold"
            onClick={() => setCurrentDeviceId(undefined)}
          />
        </div>
        <div className="flex flex-col gap-2 p-4 border-b-2 border-gray-100">
          <div className="flex gap-1 items-end text-[14px]">
            {/* <div className="font-medium">{t("gps.attributes.km")}</div> */}
            <div className="font-medium">{t("gps.attributes.distance")}</div>
            <div className="flex-1 border-b border-dashed border-gray-200"></div>
            <div>{toDateTime(currentDevice.position.fixtime)}</div>
          </div>
          {attributes.map((attr) => (
            <div className="flex gap-1 items-end text-[14px]" key={attr.key}>
              <div className="font-medium">{attr.key}</div>
              <div className="flex-1 border-b border-dashed border-gray-200"></div>
              <div>{attr.content}</div>
            </div>
          ))}
          <div className="flex gap-1 items-end text-[14px]">
            <div className="font-medium">{t("gps.attributes.speed")}</div>
            <div className="flex-1 border-b border-dashed border-gray-200"></div>
            <div>
              {Number(currentDevice.position.speed).toFixed(2)} {t("gps.formats.km")}/{t("gps.formats.hour")}
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-center items-center px-4 py-3">
          <Tooltip placement="top" title="Перейти к инвентарю">
            <Link
              to={`/groups/${currentDevice.inventory.group}/inventories/${currentDevice.inventory.id}`}
              className="btn btn-card">
              <Icon icon="inventory_2" className="text-xl" />
            </Link>
          </Tooltip>
          <Tooltip placement="top" title="Поездки">
            <Link to={`/geolocation/${currentDevice.id}/trips`} className="btn btn-card">
              <Icon icon="route" className="text-xl" />
            </Link>
          </Tooltip>
        </div>
      </div>
    )
  )
}
