import {useContext, useEffect, useMemo, useState} from "react"
import {firstValueFrom, map, of} from "rxjs"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {LabelModel} from "src/models/label"
import {CustomField, CustomFieldModel, CustomFieldType, optionlessFieldTypes} from "src/models/setting-config"
import fields, {FieldParams} from "./utils"
import {chunkArray} from "src/utils/chunk-array"
import tenantService, {TenantLimitModel} from "src/services/tenant.service"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {CustomFieldModal} from "./field-modal"
import {ConfigContext} from "src"
import {useTranslation} from "react-i18next"

export function CustomFieldChoice(config: {type: CustomFieldType; params: FieldParams}) {
  if (config.params.options && config.params.options.length > 0 && !optionlessFieldTypes.includes(config.type)) {
    return (
      <FormSelectAsync<LabelModel>
        className="col"
        name={config.params.name}
        label={config.params.label}
        placeholder={config.params.placeholder}
        required={config.params.required}
        params={{required: config.params.required}}
        listOptions={() =>
          of(
            config.params.options.map((item: string) => ({
              id: item,
              label: item
            }))
          )
        }
        getValue={(val: string) => firstValueFrom(of({id: val, label: val}))}
        getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
        isClearable={true}
        isSearchable={false}
      />
    )
  }
  return fields(config.params)[config.type]
}

export function CustomFieldChoiceList(props: {className?: string; prefix?: string; type: keyof CustomFieldModel}) {
  // const limited = !useMemo(() => limits && limits.custom_fields ? limits.custom_fields : false, [limits])
  const {settings, profile} = useContext(ConfigContext)
  const customFields = useMemo(() => (settings ? settings.custom_fields[props.type] : []), [props.type, settings])
  const fields = chunkArray(customFields, 4)
  const prefix = props.prefix || ""
  const {t} = useTranslation()

  const onCustomField = () => {
    modalService.open({
      props: {size: "lg"},
      component: <CustomFieldModal type={props.type} />
    })
  }

  return (
    <div className={props.className || ""}>
      {customFields.length > 0 && (
        <div className="flex flex-col gap-3 mb-3">
          {fields.map((chunk: CustomField[], index: number) => (
            <div className="flex flex-wrap gap-3" key={index}>
              {chunk.map((field: CustomField) => (
                <CustomFieldChoice
                  key={field.name}
                  type={field.type}
                  params={{
                    name: `${prefix}extra.${field.name}`,
                    label: field.label,
                    placeholder: field.label,
                    required: field.required,
                    params: {required: field.required},
                    className: "form-control",
                    rootclassname: "col",
                    options: field.options
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      )}
      {profile && profile.is_superuser && (
        <button className="btn btn-card gap-2 w-full" onClick={onCustomField}>
          <span>{t("settings.custom-fields.add")}</span>
          <Icon icon="add" />
        </button>
      )}
    </div>
  )
}
