import {useContext, useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {ImageField} from "src/components/shared/image-loader/image"
import Table from "src/components/shared/antd-custom/table"
import {ConfigContext} from "src/index"
import {ColumnsType} from "antd/es/table"
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable"
import {DndContext, DragEndEvent} from "@dnd-kit/core"
import {restrictToVerticalAxis} from "@dnd-kit/modifiers"
import SortableRow from "src/components/shared/antd-custom/table-sortable-row"
import {CategoryModel} from "src/models/manager/constants"
import GenericEntityService from "src/services/generic-entity.service"
import {FormControl} from "src/components/shared/inputs/form-control"
import Icon from "src/components/shared/components/material-icon"
import {isNil} from "src/utils/isNil"

const service = new GenericEntityService<CategoryModel>("v1/crm/categories")

interface FieldProps {
  category: CategoryModel
  onUpdate: (id: number, payload: any) => Promise<CategoryModel>
}

const NameField = ({category, onUpdate}: FieldProps) => {
  const form = useForm({defaultValues: {name: category.name}})

  const {setValue, handleSubmit} = form

  const onSubmit = handleSubmit((payload) =>
    onUpdate(category.id, payload).catch(() => setValue("name", category.name))
  )

  return (
    <FormProvider {...form}>
      <FormControl
        name="name"
        placeholder="Название"
        rootclassname="flex-1"
        className="form-control"
        params={{required: true}}
        onBlurCapture={onSubmit}
      />
    </FormProvider>
  )
}

const SlugField = ({category, onUpdate}: FieldProps) => {
  const form = useForm({defaultValues: {slug: category.slug}})

  const {setValue, handleSubmit} = form

  const onSubmit = handleSubmit((payload) =>
    onUpdate(category.id, {
      slug: isNil(payload.slug) ? null : payload.slug
    }).catch(() => setValue("slug", category.slug))
  )

  return (
    <FormProvider {...form}>
      <FormControl
        name="slug"
        placeholder="Slug"
        rootclassname="flex-1"
        className="form-control"
        params={{pattern: {value: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: ""}}}
        onBlurCapture={onSubmit}
      />
    </FormProvider>
  )
}

const WidgetCategoryList = () => {
  const {integrationMap} = useContext(ConfigContext)
  const [list, setList] = useState<CategoryModel[]>([])
  const form = useForm<any>({defaultValues: {page: 1, pageSize: 10}})
  const {watch} = form
  const values = watch()
  const integration = integrationMap["widget"]

  const listCategories = () => service.fetch().then(setList)

  const onUpdate = async (id: number, payload: any) => {
    return service.patch(id, payload)
  }

  const columns: ColumnsType<CategoryModel> = [
    {
      width: 48,
      key: "sort"
    },
    {
      width: "1",
      title: "Продукты",
      className: "p-2",
      key: "name",
      render: (_, category) => <NameField category={category} onUpdate={onUpdate} />
    },
    {
      width: 400,
      title: "Slug (обязателен для показа на сайте)",
      className: "p-2",
      key: "slug",
      render: (_, category) => <SlugField category={category} onUpdate={onUpdate} />
    }
  ]

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (!over || !over.id) return
    if (!values.page) return
    if (!values.pageSize) return

    if (active.id !== over.id) {
      setList((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id)
        const overIndex = previous.findIndex((i) => i.id === over.id)

        service.patch(active.id as number, {order: overIndex + 1})
        return arrayMove(previous, activeIndex, overIndex)
      })
    }
  }

  useEffect(() => {
    listCategories()
  }, [])

  return (
    integration && (
      <FormProvider {...form}>
        <div className="flex flex-col gap-3">
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={list} strategy={verticalListSortingStrategy}>
              <Table rowKey="id" columns={columns} dataSource={list} components={{body: {row: SortableRow}}} />
            </SortableContext>
          </DndContext>
        </div>
      </FormProvider>
    )
  )
}

export default WidgetCategoryList
