import {ModalProps} from "react-bootstrap/esm/Modal"
import {BehaviorSubject} from "rxjs"
import {isNil} from "src/utils/isNil"
import keygen from "src/utils/keygen"

export interface ModalConfig {
  component: JSX.Element | JSX.Element[]
  onHide?: () => void
  props?: ModalProps
  removable?: boolean
  closeButton?: boolean
}

export interface ExtendedModalConfig extends ModalConfig {
  text?: string
  className?: string
}

export class ModalService {
  modals$: BehaviorSubject<Array<[string, boolean, ModalConfig]>> = new BehaviorSubject<
    Array<[string, boolean, ModalConfig]>
  >([])

  public open(config: ModalConfig) {
    if (!this.modals$) return
    if (!this.modals$.value) return

    const uniqueId = keygen()
    this.modals$.next([...this.modals$.value, [uniqueId, true, config]])
  }

  public closeModal(id?: string) {
    if (!this.modals$) return
    if (!this.modals$.value) return

    if (!isNil(id)) {
      const modals = this.modals$.value.filter((modal) => modal[0])
      const newModals: Array<[string, boolean, ModalConfig]> = modals.map((modal) =>
        modal[0] === id ? [modal[0], false, modal[2]] : modal
      )
      this.modals$.next(newModals)
    } else {
      const length = this.modals$.value.length
      this.modals$.next(
        this.modals$.value
          .filter((modal) => modal[0])
          .map((modal, index) => (index !== length - 1 ? modal : [modal[0], false, modal[2]]))
      )
    }

    setTimeout(() => this.modals$.next(this.modals$.value.filter((modal) => modal[1])), 500)
  }

  public closeAllModal() {
    if (!this.modals$) return
    if (!this.modals$.value) return
    this.modals$.next([])
  }
}

const modalService = new ModalService()
export default modalService
