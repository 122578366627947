import moment from "moment"

export function getMinMaxDate(values: string[]): [Date, Date] {
  const dates = values.map((date) => moment(date).toDate())

  const minDate = dates.reduce((a, b) => (a < b ? a : b))
  const maxDate = dates.reduce((a, b) => (a > b ? a : b))

  return [minDate, maxDate]
}
