import {useTranslation} from "react-i18next"
import {CustomFieldChoiceList} from "src/pages/settings/custom-field/field-choice"
import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {FormControl} from "src/components/shared/inputs/form-control"

export default function ClientLegalPassportComponent() {
  const {t} = useTranslation()
  return (
    <>
      <div className="grid sm:grid-cols-2 gap-4">
        <FormControlMask
          name="passport__legal.bin"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.bin")}
          placeholder={t("clients.form.document.legal.bin")}
          mask="999999999999"
          required
          params={{required: true}}
        />
        <FormControl
          name="passport__legal.address"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.address")}
          placeholder={t("clients.form.document.legal.placeholder.address")}
          required
          params={{required: true}}
        />
        <FormControl
          name="passport__legal.director"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.director")}
          placeholder={t("clients.form.document.legal.placeholder.director")}
          required
          params={{required: true}}
        />
        <FormControl
          name="passport__legal.iban"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.iban")}
          placeholder={t("clients.form.document.legal.placeholder.iban")}
          required
          params={{required: true}}
        />
        <FormControl
          name="passport__legal.bank"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.bank")}
          placeholder={t("clients.form.document.legal.placeholder.bank")}
          required
          params={{required: true}}
        />
        <FormControlMask
          name="passport__legal.bik"
          rootclassname="col"
          className="form-control"
          label={t("clients.form.document.legal.bik")}
          placeholder={t("clients.form.document.legal.bik")}
          required
          params={{required: true, maxLength: 8}}
          mask="99999999"
        />
      </div>
    </>
  )
}
