/// <reference types="w3c-web-usb" />

import qz, {PrintData} from "qz-tray"
import {cut, printImage, reset, sendBytes, setCharacterStyle} from "src/services/printer/driver"
import {connectQZ, selectPrinter} from "../qz-tray"

function makeDivisible(number: number) {
  const remainder = number % 24
  const result = remainder === 0 ? number : number + (24 - remainder)
  return result
}

const onReceiptPrint = async (canvas: HTMLCanvasElement, onCancel?: (canvas: HTMLCanvasElement) => void) => {
  let device: USBDevice
  const devices = await navigator.usb.getDevices()

  if (devices.filter((d) => [1155, 1046].includes(d.vendorId)).length > 0) {
    device = devices[0]
  } else {
    try {
      device = await navigator.usb.requestDevice({filters: [{}]})
    } catch (e) {
      if (onCancel) onCancel(canvas)
      return
    }
  }

  await device.open()
  await device.selectConfiguration(1)
  await device.claimInterface(0)
  await reset(device)

  await setCharacterStyle(device, {
    smallFont: false,
    emphasized: false,
    underline: false,
    doubleWidth: false,
    doubleHeight: false
  })
  const ctx = canvas.getContext("2d")!
  const height = makeDivisible(canvas.height)
  const imageData: number[][] = []
  const canvasData = ctx.getImageData(0, 0, canvas.width, height)

  for (let y = 0; y < height; y++) {
    imageData.push([])
    for (let x = 0; x < canvas.width; x++) {
      const idx = y * (canvas.width * 4) + x * 4
      const r = canvasData.data[idx]
      const g = canvasData.data[idx + 1]
      const b = canvasData.data[idx + 2]
      const alpha = canvasData.data[idx + 3]
      imageData[y][x] = alpha !== 0 && (r < 255 || g < 255 || b < 255) ? 1 : 0
    }
  }

  await printImage(device, imageData, 24)
  await cut(device)
}

export const onReceiptPrintV2 = async (command: Uint8Array) => {
  let device: USBDevice
  const devices = await navigator.usb.getDevices()

  if (devices.filter((d) => [1155, 1046].includes(d.vendorId)).length > 0) {
    device = devices[0]
  } else {
    try {
      device = await navigator.usb.requestDevice({filters: [{}]})
    } catch (e) {
      return
    }
  }

  try {
    await device.open()
    await device.selectConfiguration(1)
    await device.claimInterface(0)
    await reset(device)
    await sendBytes(device, command)
  } catch (e) {
    connectQZ()
    setTimeout(async () => {
      const printer = await selectPrinter()
      const config = qz.configs.create(printer, {encoding: "Cp866"})
      const data: PrintData[] = [
        {
          type: "raw",
          format: "command",
          flavor: "hex",
          data: command
        }
      ]
      qz.print(config, data)
    }, 200)
  }
}

export default onReceiptPrint
