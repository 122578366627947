import {ModalProps} from "react-bootstrap/Modal"
import ModalComponent from "./modal.v2"

export interface CustomModalConfig extends ModalProps {
  children: JSX.Element | JSX.Element[]
  removeIcon?: boolean
}

export const CustomModal = ({removeIcon = true, ...config}: CustomModalConfig) => {
  return (
    <ModalComponent
      // {...config.props}
      props={{centered: true, ...config}}
      component={config.children}
      show={config.show}
      onHide={config.onHide}
      removable={true}
    />
  )
}
