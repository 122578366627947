import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import modalService from "src/components/modal/global/modal.service"
import {FormControlPassword} from "src/components/shared/inputs/form-control-password"

interface PasswordForm {
  password: string
  re_password: string
}

export default function UserStaffPasswordModal(config: {onSubmit: (paylaod: PasswordForm) => Promise<any>}) {
  const {t} = useTranslation()
  const form = useForm<PasswordForm>()
  const isValid = form.formState.isValid && form.watch("password") === form.watch("re_password")
  const password = form.watch("password") || ""

  const onSubmit = form.handleSubmit((payload) => {
    config.onSubmit(payload).then(() => modalService.closeModal())
  })

  return (
    <FormProvider {...form}>
      <FormControlPassword
        label={t("users.password_form.password")}
        placeholder={t("users.password_form.password")}
        rootclassname="col mb-3"
        params={{required: true, minLength: 8}}
        className="form-control"
        name="password"
      />
      <FormControlPassword
        label={t("users.password_form.re_password")}
        placeholder={t("users.password_form.re_password")}
        rootclassname="col mb-3"
        params={{required: true, minLength: 8}}
        className="form-control"
        name="re_password"
      />
      {password.length < 8 && <div className="text-xs color-red mb-2">{t("users.password_form.hint")}</div>}
      <div className="flex justify-end">
        <button className="btn btn-primary btn-color-white" onClick={onSubmit} disabled={!isValid}>
          {t("common.save")}
        </button>
      </div>
    </FormProvider>
  )
}
