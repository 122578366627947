import Table from "../../../components/shared/antd-custom/table"
import {FormProvider, useForm} from "react-hook-form"
import {useCallback, useEffect, useState} from "react"
import {EMPTY_LIST, ListModel, ListParams} from "../../../models/common"
import {useTranslation} from "react-i18next"
import {ColumnsType} from "antd/es/table"
import toDate from "../../../utils/date"
import PaginationFormComponent from "../../../components/shared/pagination/pagination-form"
import tenantService, {Subscription} from "src/services/tenant.service"
import paymentsService from "./payments.serivce"

export interface ProfilePayments {
  id: number
  subscription: number
  period: number
  price: string
  start_at: string
  end_at: string
  extra: {}
  payment_method: string
  status: string
}
export default function TransactionHistory() {
  const form = useForm<ListParams>({
    defaultValues: {
      page: 1,
      pageSize: 10
    }
  })
  const [list, setList] = useState<ListModel<ProfilePayments>>(EMPTY_LIST)
  const {setValue, watch} = form
  const [subscriptions, setSubscriptions] = useState<Subscription[]>()
  const values = watch()
  const {t} = useTranslation()

  const columns: ColumnsType<ProfilePayments> = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (id) => id
    },
    {
      title: t("common.select.tarif"),
      dataIndex: "subscription",
      key: "subscription",
      render: (subscription) => {
        const currentSub = subscriptions?.find((sub) => sub.id === subscription)
        return currentSub?.name
      }
    },
    {
      title: "Дата приобретения",
      dataIndex: "start_at",
      key: "start_at",
      render: (start_at) => toDate(start_at)
    },
    {
      title: "Период подписки",
      dataIndex: "period",
      key: "period",
      render: (period, data) => {
        const currentSub = subscriptions?.find((sub) => sub?.id === data?.subscription)
        const currentPeriod = currentSub?.periods?.find((subPeriod) => subPeriod?.id === period)
        return `${currentPeriod?.months} ${t("common.times_abb.month")} ${currentPeriod?.days} ${t("common.times_abb.day")}`
      }
    },
    {
      title: "Оплаченная сумма",
      dataIndex: "price",
      key: "price",
      render: (price) => Math.trunc(price) + " KZT"
    }
  ]

  const onLoad = useCallback(
    async (params: any): Promise<void> => paymentsService.getPayments(params).then(setList),
    [setValue]
  )
  useEffect(() => {
    onLoad(values)
  }, [])

  useEffect(() => {
    const sub = tenantService.subscriptions$.subscribe(setSubscriptions)
    return () => sub.unsubscribe()
  }, [])

  return (
    <FormProvider {...form}>
      <Table
        title={() => <p className="font-bold text-xl text-test-black p-1">История транзакций</p>}
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={list?.results}
        className="w-full bg-white rounded-xl md:shadow md:border-gray-100"
      />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}
