import {useNavigate, useOutletContext, useParams} from "react-router"
import {Link} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import modalService from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"
import {DeliveryStatus, OrderRequestDeliveryDetailModel} from "src/models/manager/order/order-request-delivery.model"
import deliveryService from "src/services/delivery.service"
import toDateTime from "src/utils/date-time"

const urlRegex = /\b(?:https?:\/\/|www\.)\S+\b/g

function DeliveryLink({place = "issue_place"}: {place: "issue_place" | "delivery_place"}) {
  const {delivery} = useOutletContext<{
    delivery: OrderRequestDeliveryDetailModel
  }>() || {delivery: undefined}

  if (!delivery[place]) return <></>

  const name = delivery[place].name
  const lng = delivery[place].longitude
  const lat = delivery[place].latitude
  const links = name.match(urlRegex)
  const link = +lat === 0 && +lng === 0 ? `https://2gis.kz/search/${name}` : `https://2gis.kz/geo/${lng},${lat}`

  if (links && links.length > 0) {
    return (
      <div className="flex gap-2">
        {links.map((href) => (
          <a href={href} className="text-primary font-medium flex gap-1 items-center" target="_blank" rel="noreferrer">
            <Icon icon="open_in_new" />
            <span className="underline">{href}</span>
          </a>
        ))}
      </div>
    )
  }

  return (
    <a href={link} className="text-primary font-medium flex gap-1 items-center" target="_blank" rel="noreferrer">
      <Icon icon="open_in_new" />
      <span className="underline">{name}</span>
    </a>
  )
}

function DeliveryDotComponent({status, last = false}: {status: DeliveryStatus; last?: boolean}) {
  const {delivery} = useOutletContext<{
    delivery: OrderRequestDeliveryDetailModel
  }>() || {delivery: undefined}

  if (!delivery) return <></>

  if (delivery.status > status || (last && delivery.status === status)) {
    return (
      <>
        <div className="w-5 h-5 bg-accept rounded-full p-[2px]">
          <Icon icon="check" className="text-white text-base leading-4" />
        </div>
        {!last && <div className="absolute w-1 bg-accept bg-opacity-30 h-[calc(100%_+_4px)] left-2 top-5"></div>}
      </>
    )
  }

  if (delivery.status === status) {
    return (
      <>
        <div className="w-5 h-5 bg-white rounded-full border-[6px] border-primary"></div>
        {!last && <div className="absolute w-1 bg-primary bg-opacity-30 h-[calc(100%_+_4px)] left-2 top-5"></div>}
      </>
    )
  }

  return (
    <>
      <div className="w-5 h-5 bg-white rounded-full border-[6px] border-gray-300"></div>
      {!last && <div className="absolute w-1 bg-gray-300 bg-opacity-30 h-[calc(100%_+_4px)] left-2 top-5"></div>}
    </>
  )
}

export function DeliveryDetailMainComponent() {
  const {orderId, id} = useParams()
  const {delivery, getDelivery} = useOutletContext<{
    delivery: OrderRequestDeliveryDetailModel
    getDelivery: () => Promise<void>
  }>() || {delivery: undefined, getDelivery: () => Promise.reject()}
  const navigate = useNavigate()

  const onBack = () => navigate("/deliveries")

  const onFinish = async () => {
    const onConfirm = async () => {
      await deliveryService.finish(+orderId, +id)
      await getDelivery()
    }
    modalService.open({
      component: (
        <ConfirmModal
          message="Вы выдали товары клиенту"
          confirm_text="Завершить заказ"
          onConfirm={onConfirm}
          delete={true}
        />
      )
    })
  }

  return (
    delivery && (
      <div className="flex flex-col gap-6">
        <HeaderContent>
          <div className="header-content flex gap-2 justify-between items-center w-full">
            <Icon icon="chevron_left" className="min-w-4 min-h-4 cursor-pointer text-2xl leading-4" onClick={onBack} />
            <div className="text-lg font-semibold justify-self-center">Заказ №{delivery.id}</div>
            <div className="min-w-8 min-h-8"></div>
          </div>
        </HeaderContent>
        <div className="relative flex gap-3 justify-start items-start">
          <DeliveryDotComponent status={DeliveryStatus.PENDING} />
          <div className="flex flex-col gap-3 flex-1">
            <div className="font-medium text-[18px]">Товар ожидает в пункте выдачи</div>
            <div className="flex flex-col gap-2">
              {delivery.worker_detail && (
                <div className="flex gap-1 items-center">
                  <div className="text-gray-400">Номер менеджера:</div>
                  <div className="text-primary font-medium">
                    {delivery.worker_detail.first_name} {delivery.worker_detail.last_name}
                  </div>
                </div>
              )}
              <div className="flex gap-1 items-center">
                <div className="text-gray-400">Ожидаемое время:</div>
                <div className="text-gray-400 font-medium">{toDateTime(delivery.pickup_date)}</div>
              </div>
              <div className="flex gap-1 items-center">
                <div className="text-gray-400">Адрес:</div>
                <DeliveryLink place="issue_place" />
              </div>
            </div>
            {delivery.status === DeliveryStatus.PENDING && (
              <Link to="checkout" className="btn btn-primary btn-color-white">
                Забрать заказ
              </Link>
            )}
          </div>
        </div>
        <div className="relative flex gap-3 justify-start items-start">
          <DeliveryDotComponent status={DeliveryStatus.IN_PROGRESS} />
          <div className="flex flex-col gap-3 flex-1">
            <div className="font-medium text-[18px]">Доставка в пути</div>
            <div className="flex gap-1 items-center">
              <div className="text-gray-400">Адрес:</div>
              <DeliveryLink place="delivery_place" />
            </div>
          </div>
        </div>
        <div className="relative flex gap-3 justify-start items-start">
          <DeliveryDotComponent status={DeliveryStatus.DONE} last />
          <div className="flex flex-col gap-3 flex-1">
            <div className="font-medium text-[18px]">Доставлено клиенту</div>
            {delivery.status === DeliveryStatus.IN_PROGRESS && (
              <button className="btn btn-primary btn-color-white" onClick={onFinish}>
                Заказ доставлен
              </button>
            )}
          </div>
        </div>
      </div>
    )
  )
}
