import {useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useParams} from "react-router"
import {useNavigate} from "react-router-dom"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import modalService from "src/components/modal/global/modal.service"
import {FormControlCheckbox} from "src/components/shared/inputs/form-control-checkbox"
import {FormControl} from "src/components/shared/inputs/form-control"
import {InventoryTarifTimePeriod} from "src/models/manager/constants"
import {InventorySetItemTarifModel} from "src/models/manager/inventory/inventory-set.model"
import appService from "src/services/app.service"
import inventorySetService from "src/services/inventory/inventory-set.service"
import {isNil} from "src/utils/isNil"
import payloadFilter from "src/utils/payload.filter"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {CustomFieldChoiceList} from "../../settings/custom-field/field-choice"
import {ImageInputField} from "src/components/shared/components/image-field"
import Icon from "src/components/shared/components/material-icon"
import {ProductSetPriceTable} from "./price-table"
import keygen from "src/utils/keygen"
import HeaderContent from "src/components/header/header-content"
import {useTranslation} from "react-i18next"

export interface InventorySetFormData {
  id?: number
  name: string
  static: boolean
  unique_id: string
  category: number
  time_period: number
  extra: any
  image: string | File
  deleted: boolean

  tarifs?: Record<string, InventorySetItemTarifModel>
  items?: Array<{
    id: number
    group: number
    count: number
    _id: number | string
  }>
  prices?: Array<{
    id: number
    name: string
    period: number
    _id: number | string
  }>
}

const timePeriod = appService.getConstant("TARIF_TIME_PERIODS")

export const ProductSetEditComponent = ({mode = "create"}: {mode: "edit" | "create"}) => {
  const {id} = useParams()
  const {t} = useTranslation()
  const form = useForm<InventorySetFormData>({
    defaultValues: {
      tarifs: {},
      static: false,
      items: [{id: undefined, group: undefined, count: 1, _id: keygen("")}]
    }
  })
  const values = form.watch()
  const navigate = useNavigate()

  const getSet = () =>
    Promise.all([
      inventorySetService.get(+id),
      inventorySetService.listPrices(+id),
      inventorySetService.listItems(+id),
      inventorySetService.listTarifs(+id)
    ]).then(([_set, _prices, _items, _tarifs]) => {
      const tarifForm = Object.fromEntries(
        _tarifs.map((tarif) => [`${tarif.inventory_set}_${tarif.inventory_set_price}`, tarif])
      )

      form.reset({
        ..._set,
        prices: _prices.map((price) => ({...price, _id: price.id})),
        items: _items.map((item) => ({...item, _id: item.id})),
        tarifs: tarifForm
      })
    })

  const savePhoto = async (setId: number, image: string | File) => {
    if (!(image instanceof File)) return
    const formData = new FormData()
    formData.append("image", image)
    await inventorySetService.patch(setId, formData)
  }

  const onPost = async (payload: InventorySetFormData) => {
    const image = payload.image
    delete payload.image
    const inventorySet = await inventorySetService.post(payload)

    const keys = Object.keys(payload.tarifs)
    const create = keys.filter(
      (key) => key.split("_").some((chunk) => Number.isNaN(+chunk)) || isNil(payload.tarifs[key].id)
    )
    const dict = {}

    await Promise.all([
      ...payload.items
        .filter((obj) => obj.id === undefined)
        .map((itemPayload) =>
          inventorySetService.createItem(inventorySet.id, itemPayload).then((res) => (dict[itemPayload._id] = res.id))
        ),
      ...payload.prices
        .filter((obj) => obj.id === undefined)
        .map((pricePayload) =>
          inventorySetService
            .createPrice(inventorySet.id, pricePayload)
            .then((res) => (dict[pricePayload._id] = res.id))
        )
    ])

    const tarifCreate = create.map((key) => {
      const [itemKey, priceKey] = key.split("_")
      return {
        inventory_set: Number.isNaN(+itemKey) ? dict[itemKey] : +itemKey,
        inventory_set_price: Number.isNaN(+priceKey) ? dict[priceKey] : +priceKey,
        ...payload.tarifs[key]
      }
    })

    await inventorySetService.bulkCreateTarifs(inventorySet.id, {
      tarifs: tarifCreate
    })
    await savePhoto(inventorySet.id, image)
    navigate(`/inventory-sets/${inventorySet.id}`)
  }

  const onPatch = async (payload: InventorySetFormData) => {
    const keys = Object.keys(payload.tarifs)

    const create = keys.filter(
      (key) => key.split("_").some((chunk) => Number.isNaN(+chunk)) || isNil(payload.tarifs[key].id)
    )
    const update = keys.filter(
      (key) => key.split("_").every((chunk) => !Number.isNaN(+chunk)) && !isNil(payload.tarifs[key].id)
    )

    const dict = {}

    await Promise.all([
      ...payload.items
        .filter((obj) => !isNil(obj.id))
        .map((itemPayload) => inventorySetService.patchItem(+id, itemPayload.id, itemPayload)),
      ...payload.prices
        .filter((obj) => !isNil(obj.id))
        .map((pricePayload) => inventorySetService.patchPrice(+id, pricePayload.id, pricePayload)),
      ...payload.items
        .filter((obj) => obj.id === undefined)
        .map((itemPayload) =>
          inventorySetService.createItem(+id, itemPayload).then((res) => (dict[itemPayload._id] = res.id))
        ),
      ...payload.prices
        .filter((obj) => obj.id === undefined)
        .map((pricePayload) =>
          inventorySetService.createPrice(+id, pricePayload).then((res) => (dict[pricePayload._id] = res.id))
        )
    ])

    const tarifCreate = create.map((key) => {
      const [itemKey, priceKey] = key.split("_")
      return {
        inventory_set: Number.isNaN(+itemKey) ? dict[itemKey] : +itemKey,
        inventory_set_price: Number.isNaN(+priceKey) ? dict[priceKey] : +priceKey,
        ...payload.tarifs[key]
      }
    })

    const tarifUpdate = update.map((key) => {
      const [itemKey, priceKey] = key.split("_")
      return {
        inventory_set: Number.isNaN(+itemKey) ? dict[itemKey] : +itemKey,
        inventory_set_price: Number.isNaN(+priceKey) ? dict[priceKey] : +priceKey,
        ...payload.tarifs[key]
      }
    })

    await Promise.all([
      inventorySetService.bulkCreateTarifs(+id, {tarifs: tarifCreate}),
      inventorySetService.bulkUpdateTarifs(+id, {tarifs: tarifUpdate})
    ])

    const image = payload.image
    delete payload.image
    delete payload.items
    delete payload.prices
    await inventorySetService.patch(+id, payload)
    await savePhoto(+id, image)
    await getSet()
  }

  const onArchive = () => {
    const onConfirm = () => inventorySetService.archive(+id).then(getSet)

    modalService.open({
      component: (
        <ConfirmModal
          delete={!values.deleted}
          confirm_text={t(values.deleted ? "common.archive.return" : "common.archive.archive")}
          message={t(values.deleted ? "common.archive.to_return.set" : "common.archive.to_archive")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const onDelete = () => {
    const onConfirm = () => inventorySetService.delete(+id).then(() => navigate("/inventory-sets"))

    modalService.open({
      component: (
        <ConfirmModal
          delete={true}
          message={t("common.confirm.delete")}
          confirm_text={t("common.delete")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const onSubmit = form.handleSubmit(async (payload) => {
    const mapRequests = {
      edit: async () => await onPatch(payloadFilter(payload, {})),
      create: async () => await onPost(payloadFilter(payload, {}))
    }
    await mapRequests[mode]()
  })

  const onBack = () => navigate(-1)

  useEffect(() => {
    if (mode === "edit") {
      getSet()
      return
    }
    timePeriod.subscribe((periods: InventoryTarifTimePeriod[]) => {
      form.reset({
        ...form.watch(),
        prices: periods.map((p) => ({period: p.id, _id: keygen("")}))
      })
    })
  }, [id])

  const submitButtonLabel = mode === "edit" ? "common.save" : "set.edit.create"
  const title = mode === "edit" ? "set.edit.header_edit" : "set.edit.header_add"

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <div className="flex items-center justify-between gap-2 header-content">
          <div className="flex items-center gap-2">
            <Icon onClick={onBack} className="cursor-pointer text-2xl" icon="chevron_left" />
            <div className="text-2xl font-semibold">{t(title)}</div>
          </div>
          {id && (
            <div className="flex gap-2">
              <button className="btn btn-red btn-color-white gap-2" onClick={onDelete}>
                <Icon icon="delete" />
                <span className="font-medium text-[13px]">{t("common.delete")}</span>
              </button>
              <button className="btn btn-card gap-2" onClick={onArchive}>
                <Icon icon="archive" />
                <span className="font-medium text-[13px]">
                  {t(form.watch("deleted") === true ? "common.archive.return" : "common.archive.archive")}
                </span>
              </button>
            </div>
          )}
        </div>
      </HeaderContent>

      <div className="flex gap-3 mb-3">
        <ImageInputField name="image" />
        <div className="col">
          <div className="flex gap-3">
            <FormControlCheckbox
              rootclassname="mb-3"
              name="static"
              label={t("set.edit.static_price")}
              placeholder={t("common.checkbox.price")}
              className="form-check-input m-0"
            />
          </div>
          <div className="flex gap-3">
            <FormControl
              rootclassname="col"
              className="form-control"
              label={t("common.input.name")}
              placeholder={t("common.input.placeholder.name")}
              name="name"
              params={{required: true}}
              required
            />
            <SharedCategorySelect
              name="category"
              className="col"
              label={t("common.select.category")}
              placeholder={t("common.select.placeholder.category")}
              required={true}
              params={{required: true}}
              isClearable={false}
              isSearchable={false}
              addOption
            />
            <FormControl
              rootclassname="col"
              className="form-control"
              label={t("common.input.unique_id")}
              placeholder={t("common.input.placeholder.unique_id")}
              name="unique_id"
              required
              params={{required: true}}
            />
          </div>
        </div>
      </div>

      <CustomFieldChoiceList type="inventory_set" className="mb-3" />

      <ProductSetPriceTable />

      <div className="flex justify-end mb-3">
        <button onClick={onSubmit} disabled={!form.formState.isValid} className="btn btn-primary text-white">
          {t(submitButtonLabel)}
        </button>
      </div>
    </FormProvider>
  )
}
