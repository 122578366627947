import {ColumnsType} from "antd/es/table"
import {useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useForm, useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ImageField} from "src/components/shared/image-loader/image"
import Icon from "src/components/shared/components/material-icon"
import Table from "src/components/shared/antd-custom/table"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {EMPTY_LIST, ListModel, ListParams} from "src/models/common"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import inventoriesService from "src/services/inventory/inventory.service"
import {OrderSaleContext} from "../context"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import {TableRowSelection} from "antd/es/table/interface"
import {SaleInventoryModel} from "src/models/manager/order/order-sale.model"
import {isNil} from "src/utils/isNil"
import {ConfigContext} from "src"

interface SaleInventoryParams extends ListParams {
  group: number
}

export function SaleInventoryList() {
  const {groups, onGroupAdd, onGroupRemoval, onInventorySelect, invCnt} = useContext(OrderSaleContext)
  const saleForm = useFormContext()
  const form = useForm<SaleInventoryParams>({
    defaultValues: {page: 1, pageSize: 10, status: 0, group: undefined}
  })
  const {setValue, watch} = form
  const [list, setList] = useState<ListModel<InventoryModel>>(EMPTY_LIST)
  const {t} = useTranslation()
  const {constantsMap} = useContext(ConfigContext)
  const values = watch()

  const inventories: SaleInventoryModel[] = useMemo(
    () =>
      Object.keys(saleForm.watch("_inventories"))
        .map((key) => saleForm.watch(`_inventories.${key}`))
        .filter((obj) => !isNil(obj)),
    [saleForm]
  )
  const groupCountMap: Record<number, number> = useMemo(
    () =>
      Object.fromEntries(
        groups.map((group) => [group.id, inventories.filter((inv) => inv.inventory.group === group.id).length])
      ),
    [groups, inventories]
  )
  const selectedRowKeys: number[] = useMemo(() => inventories.map((obj) => obj.inventory.id), [inventories])

  const columns: ColumnsType<InventoryModel> = [
    {
      width: 240,
      title: t("groups.inventories.name"),
      key: "name",
      dataIndex: "name",
      render: (name, inventory) => (
        <div className="flex gap-2 items-center">
          <ImageField src={inventory.image} style={{width: 48, height: 48, borderRadius: 8}} />
          <div className="flex items-center gap-2">
            <span className="font-medium">{name}</span>
            {inventory.sublease_user && (
              <div className="btn px-2 py-1 btn-color-primary btn-primary-10">{t("groups.inventories.sublease")}</div>
            )}
          </div>
        </div>
      ),
      sorter: true
    },
    {
      width: 96,
      title: t("groups.inventories.unique_id"),
      dataIndex: "unique_id",
      key: "unique_id",
      render: (unique_id) => unique_id,
      sorter: true
    },
    {
      width: 96,
      title: t("groups.inventories.rental_point"),
      dataIndex: "rental_point",
      key: "rental_point",
      render: (rental_point) => constantsMap.RENTAL_POINTS[rental_point]?.name,
      sorter: true
    }
  ]

  const onGroupToggle = (groupId: number) => () => setValue("group", groupId)

  const listInventories = async (params: any) =>
    inventoriesService
      .list(params)
      .then(setList)
      .catch((e) => e.response.status === 404 && setValue("page", 1))

  useEffect(() => {
    const sub = watch(listInventories)
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    if (groups.length > 0) setValue("group", groups[0].id)
  }, [groups])

  const rowSelection: TableRowSelection<InventoryModel> = {
    selectedRowKeys,
    onChange: (keys: number[]) => console.log(keys),
    columnWidth: 48,
    columnTitle: <></>,
    renderCell: (val: boolean, inventory: InventoryModel) => (
      <ControlCheckbox
        className="form-check-input"
        checked={val}
        onClick={(e) => e.stopPropagation()}
        onChange={() => onInventorySelect(inventory)}
      />
    )
  }

  return (
    <FormProvider {...form}>
      <div className="text-2xl font-bold mb-3">{t("sale.edit.title.inventory")}</div>

      <div className="color-gray-400 mb-2">
        <span
          dangerouslySetInnerHTML={{
            __html: t("sale.edit.selected_inventory", {count: invCnt})
          }}></span>
      </div>

      <div className="sale-tab border-bottom mb-3">
        <div className="sale-tab-nav">
          {groups.map((group) => (
            <div
              key={group.id}
              className={`sale-tab-nav-link flex gap-2 text-nowrap color-gray-500 ${values.group === group.id ? "active" : ""}`}
              onClick={onGroupToggle(group.id)}>
              <span className="text-sm font-medium">{group.name}</span>
              <span className="sale-tab-nav-badge">{groupCountMap[group.id]}</span>
              <button className="btn btn-card p-1" onClick={(e) => onGroupRemoval(e, group)}>
                <Icon icon="close" className="text-sm font-semibold" />
              </button>
            </div>
          ))}
          <div className="sale-tab-nav-link color-black flex gap-2 text-nowrap" onClick={onGroupAdd}>
            <Icon icon="add" />
            <span className="text-sm font-medium">{t("sale.edit.add_group")}</span>
          </div>
        </div>
      </div>

      <Table rowKey={(obj) => obj.id} columns={columns} dataSource={list.results} rowSelection={rowSelection} />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}
