import Table from "src/components/shared/antd-custom/table"
import {ColumnsType} from "antd/es/table"
import moment from "moment"
import {SetStateAction, useContext, useEffect, useState} from "react"
import {ORDER_STATUS_NAME} from "src/components/shared/constants"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {OrderRequestListModel} from "src/models/manager/order/order-request-list.model"
import {invertColor} from "src/utils/color/color-invert"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import {OrderStatus, PaymentStatus} from "../constants"
import {OrderCardDocument} from "./order-document"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {Popover} from "antd"
import {Link, useNavigate, useOutletContext} from "react-router-dom"
import {ClientModel} from "src/models/manager/clients/client.model"
import {toast} from "react-toastify"
import {ConfigContext} from "src"
import clsx from "clsx"
import {TableRowSelection} from "antd/es/table/interface"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import {OutletContextData} from "./orders"
import "./orders.scss"

function OrderStatusComponent({order}: {order: OrderRequestListModel}) {
  const {t} = useTranslation()
  const {settings} = useContext(ConfigContext)
  const [color, setColor] = useState<string>("")
  const isNew = moment().diff(moment(order.created_at)) < 60 * 60 * 1000 && order.status === OrderStatus.REQUEST

  useEffect(() => {
    if (order && order.status_color) setColor(settings[order.status_color])
  }, [settings, order])

  return (
    color && (
      <div
        className="btn py-2 px-3 flex gap-1 items-center font-medium text-nowrap"
        style={{background: color, color: invertColor(color)}}>
        {t(ORDER_STATUS_NAME[order.status_color])}
        {isNew && <div className="btn btn-red btn-color-white text-xs font-medium p-1 py-0">NEW</div>}
      </div>
    )
  )
}

function OrderInventories({order}: {order: OrderRequestListModel}) {
  return (
    <>
      <div className="flex flex-col gap-1">
        {order.inventories.map((inventory) => (
          <div
            key={inventory.id}
            style={{lineHeight: "20px"}}
            className={clsx(
              "text-[13px] color-gray-500",
              inventory.started === true && inventory.returned === false && "text-alert",
              inventory.returned === true && "text-accept"
            )}>
            - {inventory.inventory_name} ({inventory.inventory_unique_id})
          </div>
        ))}
      </div>

      {order.services.length > 0 && (
        <div className="flex flex-col gap-1 mt-2">
          {order.services.map((service) => (
            <div key={service.id} className="text-[13px] color-gray-500">
              - {service.service} ({service.worker})
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default function OrderListTableTabComponent({list = EMPTY_LIST}: {list: ListModel<OrderRequestListModel>}) {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {
    selection = false,
    selected = [],
    setSelected = (val: SetStateAction<number[]>) => {}
  } = useOutletContext<OutletContextData>() || {}

  const onPhoneCopy = (e, client: ClientModel) => {
    e.stopPropagation()
    navigator.clipboard.writeText(String(client.phone))
    toast(t("common.toast.tel_number_copied"))
  }

  const columns: ColumnsType<OrderRequestListModel> = [
    {
      width: 120,
      title: "№",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (_, order) => <span className="font-semibold text-nowrap">{order.unique_id || order.id}</span>
    },
    {
      width: 160,
      title: t("orders.list.status"),
      dataIndex: "status",
      key: "rent",
      fixed: "left",
      render: (_, request) => <OrderStatusComponent order={request} />
    },
    {
      width: 148,
      title: t("orders.list.rent_start"),
      dataIndex: "rent_start",
      key: "rent_start",
      render: (rent_start, record: OrderRequestListModel) => (
        <Popover
          content={
            !!record.rent_fact_start && (
              <span className="text-nowrap">
                {t("orders.main.rent_fact_start")} - {toDateTime(record.rent_fact_start)}
              </span>
            )
          }>
          <span className="text-nowrap cursor-pointer">{toDateTime(rent_start)}</span>
        </Popover>
      )
    },
    {
      width: 148,
      title: t("orders.list.rent_end"),
      dataIndex: "rent_end",
      key: "rent_end",
      render: (rent_end, record: OrderRequestListModel) => (
        <Popover
          content={
            !!record.rent_fact_end && (
              <span className="text-nowrap">
                {t("orders.main.rent_fact_end")} - {toDateTime(record.rent_fact_end)}
              </span>
            )
          }>
          <span className="text-nowrap cursor-pointer">{toDateTime(rent_end)}</span>
        </Popover>
      )
    },
    {
      width: 224,
      title: t("orders.list.client"),
      dataIndex: "client",
      key: "client",
      render: (client) =>
        client ? (
          <Popover
            trigger="hover"
            overlayInnerStyle={{padding: 6}}
            className="flex gap-2 items-center"
            content={
              <div className="flex gap-1 flex-col">
                <Link
                  to={`/clients/${client.id}`}
                  onClick={(e) => e.stopPropagation()}
                  className="btn btn-gray-50 text-start py-1">
                  {t("orders.list.go_client")}
                </Link>
                <button className="btn btn-gray-50 text-start py-1" onClick={(e) => onPhoneCopy(e, client)}>
                  {t("orders.list.copy_phone_number")}
                </button>
              </div>
            }>
            <Icon className="text-2xl" icon="groups" />
            <div className="flex flex-col gap-1">
              <div className="font-semibold text-sm">{client.name}</div>
              <div className="font-normal text-sm">{client.phone}</div>
            </div>
          </Popover>
        ) : (
          <div className="flex gap-2 items-center">
            <Icon className="text-2xl" icon="groups" />
            <div className="font-semibold text-sm">{t("orders.list.no_client")}</div>
          </div>
        )
    },
    // {
    //   width: 440,
    //   title: t('orders.list.inventories'),
    //   dataIndex: 'inventories',
    //   key: 'inventories',
    //   render: (_, order) => <OrderInventories order={order} />,
    // },
    {
      width: 148,
      title: t("orders.list.price"),
      dataIndex: "price_discount",
      key: "price_discount",
      render: (price_discount) => <span className="font-semibold">{toPrice(price_discount)}</span>
    },
    {
      width: 148,
      title: t("orders.list.debt"),
      dataIndex: "price_discount",
      key: "price_discount",
      render: (_, order) =>
        [PaymentStatus.PENDING, PaymentStatus.PARTLY_PAID].includes(order.payment_status) && (
          <div className="color-red font-medium">{toPrice(+order.price_discount - +order.paid_amount)}</div>
        )
    },
    {
      width: 148,
      title: t("orders.list.exceed_amount"),
      dataIndex: "penalty_amount",
      key: "penalty_amount",
      render: (amount) => <div className="color-primary font-medium">{toPrice(amount)}</div>
    },
    // {
    //   width: 80,
    //   key: 'document',
    //   fixed: 'right',
    //   render: (_, order) => <OrderCardDocument order={order} view="table" />
    // },
    {
      width: 48,
      key: "document",
      align: "center",
      fixed: "right",
      className: "border-l border-gray-100 w-16 cursor-pointer p-0 hover:bg-gray-100",
      onHeaderCell: () => ({style: {borderLeft: "none"}}),
      onCell: (data, index) => ({
        style: {borderLeft: "1px solid var(--color-gray-100)"},
        onClick: (e) => {
          e.stopPropagation()
        }
      }),
      render: (_, order) => <OrderCardDocument order={order} view="table" />
    }
  ]

  const rowSelection: TableRowSelection<OrderRequestListModel> = {
    selectedRowKeys: selected,
    onChange: (keys: number[]) => setSelected(keys),
    columnWidth: 48,
    columnTitle: (
      <ControlCheckbox
        className="form-check-input"
        checked={selected.length !== 0 && selected.length === list.results.length}
        indeterminate={selected.length > 0 && selected.length !== list.results.length}
        onChange={() => setSelected((prev) => (prev.length === 0 ? list.results.map((obj) => obj.id) : []))}
      />
    ),
    renderCell: (val: boolean, inventory: OrderRequestListModel) => (
      <ControlCheckbox
        className="form-check-input"
        checked={val}
        onClick={(e) => e.stopPropagation()}
        onChange={() =>
          setSelected((prev) =>
            prev.includes(inventory.id) ? prev.filter((k) => k !== inventory.id) : [...prev, inventory.id]
          )
        }
      />
    )
  }

  return (
    <>
      <Table
        rowSelection={selection ? rowSelection : undefined}
        rowKey={(obj) => obj.id}
        columns={columns}
        dataSource={list.results}
        onNavigate={(order) => navigate(`/orders/${order.id}/all`)}
        expandable={{
          indentSize: 0,
          expandRowByClick: true,
          expandIcon: ({expanded, record, onExpand}) => (
            <div
              className={clsx("transition-all", expanded ? "rotate-180" : "rotate-0")}
              onClick={(e) => onExpand(record, e)}>
              <Icon icon="expand_more" />
            </div>
          ),
          expandedRowRender: (order: OrderRequestListModel) => <OrderInventories order={order} />
        }}
      />
      <PaginationFormComponent count={list.count} />
    </>
  )
}
