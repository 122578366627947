export default function payloadFilter(data: any, entity: any = {}, mode: "post" | "patch" = "patch") {
  const payload = {...data}

  Object.keys(payload).forEach((key) => {
    // const nil = payload[key] === undefined || payload[key] === ''
    const nil = mode === "patch" ? payload[key] === undefined : payload[key] === undefined || payload[key] === ""
    const isNotChanged = entity ? false : entity && payload[key] === entity[key]

    if (nil || isNotChanged) {
      delete payload[key]
    }
  })
  return payload
}
