import clsx from "clsx"
import {useTranslation} from "react-i18next"
import modalService from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"
import {isNil} from "src/utils/isNil"
import React from "react"

export interface ConfirmModalConfig {
  id?: string
  title?: string
  message: string | React.ReactNode
  onConfirm: () => void

  onCancel?: () => void
  delete?: boolean
  confirm_text?: string
  cancel_text?: string
}

export const ConfirmModal = (config: ConfirmModalConfig) => {
  const onHide = () => modalService.closeModal(config.id)
  const {t} = useTranslation()

  const title = config.title || t("common.confirm.title")
  const onConfirm = () => {
    config.onConfirm()
    onHide()
  }

  const onCancel = () => {
    if (!isNil(config.onCancel)) config.onCancel()
    onHide()
  }

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <div
        className={clsx(
          "flex items-center justify-center w-12 h-12 bg-opacity-20 rounded-lg",
          config.delete ? "bg-alert" : "bg-primary"
        )}>
        <Icon icon="info_i" className={clsx("font-semibold text-2xl", config.delete ? "text-alert" : "text-primary")} />
      </div>

      <div className="flex gap-3 flex-col items-center">
        <div className="font-bold text-2xl">{title}</div>
        {config.message && <div className="font-medium text-base text-center">{config.message}</div>}
      </div>

      <div className="flex flex-col gap-3 w-full">
        <button
          className={clsx(
            "btn btn-color-white w-full text-base font-semibold",
            config.delete ? "btn-red" : "btn-primary"
          )}
          onClick={onConfirm}>
          {config.confirm_text || "OK"}
        </button>
        <button className="btn btn-card w-full text-base font-semibold" onClick={onCancel}>
          {config.cancel_text || t("common.cancel")}
        </button>
      </div>
    </div>
  )
}
