import {useEffect, useState} from "react"
import Table from "src/components/shared/antd-custom/table"
import {ImageField} from "src/components/shared/image-loader/image"
import {FormProvider, useForm} from "react-hook-form"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {InventorySetModel} from "src/models/manager/inventory/inventory-set.model"
import {ManageInventoryGroupService} from "src/services/inventory/inventory-group.service"
import {ManageInventorySetService} from "src/services/inventory/inventory-set.service"
import {ManageServicesService} from "src/services/services"
import {ServiceModel} from "src/models/manager/order/order-request-service-new"
import {useDebounce} from "src/hooks/useDebounce"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useTranslation} from "react-i18next"

interface IModalContentProps {
  service: ManageInventorySetService | ManageInventoryGroupService | ManageServicesService
  getProduct: () => Promise<any>
}

const ModalContent = ({service, getProduct}: IModalContentProps) => {
  const {t} = useTranslation()
  const form = useForm({
    defaultValues: {page: 1, pageSize: 10, shareable: true, search: ""}
  })
  const {watch, setValue, reset} = form
  const values = watch()
  const [list, setList] = useState<ListModel<InventoryGroupModel | InventorySetModel | ServiceModel>>(EMPTY_LIST)
  const searchDebounce = useDebounce(watch("search", ""), 500)

  const getList = async (
    params: any = {
      page: values.page,
      pageSize: values.pageSize,
      shareable: true
    }
  ) => {
    service
      .list(params)
      .then(setList)
      .catch((e) => e.response?.status === 404 && setValue("page", 1))
  }

  const updateList = (product: any, add: boolean) => {
    if (product.id === undefined) return
    const payload = {published: add}

    service
      .patch(product.id, payload)
      .then(() => getList({page: values.page, pageSize: values.pageSize, shareable: true}))
      .then(() => getProduct())
  }

  const columns = [
    {
      title: "Название",
      key: "image",
      render: (_, {name, image}) => (
        <div className="flex gap-2 items-center">
          <ImageField className="w-12 h-12" src={image} />
          <div className="font-medium">{name}</div>
        </div>
      )
    }
  ]

  useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

  useEffect(() => {
    getList()
    const sub = watch((params, {name}) => {
      if (name !== "search") getList(params)
    })
    return () => sub.unsubscribe()
  }, [watch])

  return (
    <FormProvider {...form}>
      <div className="flex justify-end mb-3">
        <FormControl
          icon="search"
          name="search"
          className="form-control"
          rootclassname="min-w-56"
          placeholder={t("common.input.search")}
        />
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={list.results}
        onChange={(pagination) => setValue("page", pagination.current)}
        onAdd={(data) => (e) => {
          e.preventDefault()
          updateList(data, true)
        }}
      />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}

export default ModalContent
