import {useContext, useEffect, useState} from "react"
import {CustomField, CustomFieldModel, CustomFieldOption} from "src/models/setting-config"
import {chunkArray} from "src/utils/chunk-array"
import {CustomFieldFilterChoice} from "./field-filter-choice"
import {ConfigContext} from "src"

interface CustomFieldProps {
  type: keyof CustomFieldModel
  prefix: string
  manualOptions?: CustomFieldOption
  clearable?: boolean
  header?: JSX.Element
  onChange?: (val: any, key: string) => void
  className?: string
  filter?: any
}

export function CustomFieldFilterList(props: CustomFieldProps) {
  const {settings} = useContext(ConfigContext)
  const [customFields, setCustomFields] = useState<CustomField[]>([])
  const fields = chunkArray(customFields, 4)

  useEffect(() => {
    if (!settings) return
    setCustomFields(settings.custom_fields[props.type]?.filter((field) => field?.filter === true))
  }, [settings, props.type])

  const filterAvailable = customFields.length > 0

  return (
    filterAvailable && (
      <div className={props.className || ""}>
        {props.header}
        <div className="flex flex-col gap-3">
          {fields.map((chunk: CustomField[], index: number) => (
            <div className="flex flex-wrap gap-3" key={index}>
              {chunk.map((field: CustomField) => (
                <CustomFieldFilterChoice
                  key={field.name}
                  type={field.type}
                  field={`extra__${field.name}`}
                  onChange={props.onChange ? (val) => props.onChange(val, field.name) : undefined}
                  filter={props.filter}
                  params={{
                    name: `${props.prefix}${field.name}`,
                    placeholder: field.label,
                    rootclassname: "col"
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  )
}
