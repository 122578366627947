import {CSSProperties, useId} from "react"
import {ImageField} from "../image-loader/image"
import {useFormContext} from "react-hook-form"

export function ImageInputField({
  name,
  style = {objectFit: "contain", width: 160, height: 160, borderRadius: 8},
  containerStyle = {width: "max-content", borderRadius: 8}
}: {
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
}) {
  const imageId = useId()
  const {setValue, watch} = useFormContext()
  const image = watch(name)

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) setValue(name, file)
  }

  return (
    <>
      <input id={imageId} hidden type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
      <label
        htmlFor={imageId}
        className="bg-white rounded-lg cursor-pointer shadow border border-gray-100"
        style={containerStyle}>
        {image instanceof File ? (
          <ImageField src={URL.createObjectURL(image)} style={style} />
        ) : (
          <ImageField src={image} style={style} />
        )}
      </label>
    </>
  )
}
