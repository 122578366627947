import {MaintenanceType} from "src/models/manager/maintenance/maintenance.model"
import {useTranslation} from "react-i18next"
import CustomSelect from "src/components/shared/inputs/select/select"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {FormControl} from "src/components/shared/inputs/form-control"

export default function DateOrDistanceSelector({
  selectedType,
  onChange,
  options,
  maintenanceType,
  doToday
}: {
  selectedType: string
  onChange: (value: {label: string; value: string}) => void
  options: Array<{label: string; value: string}>
  maintenanceType: MaintenanceType
  doToday: boolean
}) {
  const {t} = useTranslation()
  return (
    <div className="flex gap-3 justify-between">
      <CustomSelect
        onChange={onChange}
        options={options}
        label="Тип обслуживания"
        rootclassname="flex-1"
        className="form-control-number h-[48px]"
        styles={colourStyles(false)}
        value={options.find((option) => option.value === selectedType)}
      />

      {selectedType === "date" && !doToday && (
        <FormControlDatetime
          name="maintenance_date"
          rootclassname="flex-1"
          label={
            maintenanceType === MaintenanceType.PERIODIC
              ? `${t("maintenance.planned_date")} ${t("maintenance.first")} ${t("maintenance.maintenance_abr")}`
              : t("maintenance.planned_date")
          }
          className="form-control"
          required={true}
          params={{required: true}}
          datetimeConfig={{
            minuteStep: 1,
            showTime: {format: "HH:mm"},
            allowClear: false
          }}
        />
      )}

      {selectedType === "distance" && (
        <FormControl
          name="total_distance"
          rootclassname="flex-1"
          className="form-control"
          placeholder="Пробег"
          label="Пробег"
          addonright={t("maintenance.km")}
          disabled={maintenanceType === MaintenanceType.PERIODIC && !doToday}
        />
      )}
    </div>
  )
}
