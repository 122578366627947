import axios from "axios"
import {ApiAbstract} from "../../../abstract/api.abstract"
import {ListModel} from "../../../models/common"
import {ProfilePayments} from "./transaction-history"

export class PaymentsService extends ApiAbstract {
  constructor() {
    super("v1/tenant/account")
  }

  public async getPayments(payload): Promise<ListModel<ProfilePayments>> {
    const response = await axios.get(this.getUrl("payments"), payload)
    return response.data
  }
}

const paymentsService = new PaymentsService()
export default paymentsService
