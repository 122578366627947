import {FormProvider, useForm} from "react-hook-form"
import modalService from "src/components/modal/global/modal.service"
import {ArticleModel} from "src/models/manager/website"
import {FormControl} from "src/components/shared/inputs/form-control"
import {ImageInputField} from "src/components/shared/components/image-field"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"
import {useState} from "react"
import {FormControlCkeditor} from "src/components/shared/inputs/form-control-ckeditor"
import imageUploadService from "src/services/image.service"
import websiteService from "src/services/website.service"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {useTranslation} from "react-i18next"

function ArticleEditLanguageKk() {
  const {t} = useTranslation()
  return (
    <>
      <div className="flex mb-3">
        <ImageInputField
          name="extra.image_kk"
          style={{
            minHeight: 240,
            maxHeight: 480,
            objectFit: "contain",
            width: "100%",
            borderRadius: 12
          }}
          containerStyle={{width: "100%"}}
        />
      </div>

      <FormControl
        name="extra.title_kk"
        rootclassname="col mb-3"
        className="form-control"
        params={{required: true}}
        required={true}
        label={t("website.articles.name_kk")}
        placeholder={t("website.articles.name")}
      />
      <FormControlCkeditor
        name="extra.body_kk"
        rootclassname="col mb-3"
        className="form-control"
        label={t("website.articles.description")}
      />
    </>
  )
}

function ArticleEditLanguageRu() {
  const {t} = useTranslation()

  return (
    <>
      <div className="flex mb-3">
        <ImageInputField
          name="extra.image_ru"
          style={{
            minHeight: 240,
            maxHeight: 480,
            objectFit: "contain",
            width: "100%",
            borderRadius: 12
          }}
          containerStyle={{width: "100%"}}
        />
      </div>

      <FormControl
        name="extra.title_ru"
        rootclassname="col mb-3"
        className="form-control"
        params={{required: true}}
        required={true}
        label={t("website.articles.name_ru")}
        placeholder={t("website.articles.name")}
      />
      <FormControlCkeditor
        name="extra.body_ru"
        rootclassname="col mb-3"
        className="form-control"
        label={t("website.articles.description")}
      />
    </>
  )
}

export default function ArticleEdit({article, postSubmit}: {article?: ArticleModel; postSubmit: () => void}) {
  const [lang, setLang] = useState<"kk" | "ru">("ru")
  const {t} = useTranslation()
  const form = useForm<ArticleModel>({defaultValues: article})
  const {handleSubmit, watch, formState} = form
  const values = watch()

  const onCancel = () => modalService.closeModal()

  const onDelete = () => {
    const onConfirm = () =>
      websiteService.deleteArtice(article.id).then(() => {
        modalService.closeAllModal()
        postSubmit()
      })
    modalService.open({
      component: (
        <ConfirmModal
          message={t("website.articles.delete_sure")}
          delete={true}
          confirm_text={t("common.delete")}
          onConfirm={onConfirm}
        />
      )
    })
  }

  const onSubmit = handleSubmit(async (payload) => {
    if (payload.extra.image_ru instanceof File) {
      const {image_url} = await imageUploadService.post(payload.extra.image_ru as File)
      payload.extra.image_ru = image_url
    }
    if (payload.extra.image_kk instanceof File) {
      const {image_url} = await imageUploadService.post(payload.extra.image_kk as File)
      payload.extra.image_kk = image_url
    }
    if (article) {
      await websiteService.updateArtice(article.id, payload)
    } else {
      await websiteService.createArtice(payload)
    }
    modalService.closeAllModal()
    postSubmit()
  })

  const tabsConfig: TabConfig = {
    items: [
      {key: "ru", label: t("website.articles.ru")},
      {key: "kk", label: t("website.articles.kk")}
    ],
    onClick: (config: TabPanelConfig) => setLang(config.key as "kk" | "ru"),
    activeTab: lang,
    style: {marginBottom: 12}
  }

  return (
    <FormProvider {...form}>
      <div className="flex flex-col">
        <FormControl
          name="title"
          rootclassname="col mb-3"
          className="form-control"
          params={{required: true}}
          required={true}
          label={t("website.articles.name")}
          placeholder={t("website.articles.name")}
        />
        <FormControl
          name="outer_link"
          rootclassname="col"
          className="form-control"
          label={t("website.articles.source_link")}
          placeholder="https://"
        />

        <TabPanel {...tabsConfig} />

        {lang === "kk" ? <ArticleEditLanguageKk /> : <ArticleEditLanguageRu />}

        <div className="flex items-center justify-end gap-2 my-3">
          <button className="btn btn-red-10 btn-color-red font-medium gap-2" onClick={onDelete}>
            {t("common.delete")}
          </button>
          <button className="btn btn-gray-100 btn-color-black font-medium gap-2" onClick={onCancel}>
            {t("common.canceling")}
          </button>
          <button
            className="btn btn-primary btn-color-white font-medium gap-2"
            disabled={!formState.isValid}
            onClick={onSubmit}>
            <span>{values.id ? t("common.save") : t("common.create_and_save")}</span>
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
