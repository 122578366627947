import {useTranslation} from "react-i18next"
import {FormControl} from "src/components/shared/inputs/form-control"
import {SharedProfileSelect} from "src/components/shared/components/select/profile"
import React from "react"

export default function PeriodicMaintenanceFields({selectedType}: {selectedType: string}) {
  const {t} = useTranslation()
  return (
    <>
      <div className="flex gap-3 justify-between">
        <FormControl
          name="name"
          className="form-control"
          rootclassname="flex-1"
          placeholder={t("common.input.placeholder.name")}
          label={t("common.input.name")}
        />
        {selectedType === "date" ? (
          <FormControl
            type="number"
            name="interval"
            label={t("maintenance.maintenance_periodical")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            params={{required: true}}
            addonright={t("common.times_count.days")}
          />
        ) : (
          <FormControl
            type="number"
            name="interval_distance"
            label={t("maintenance.maintenance_periodical")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            addonright={t("maintenance.km")}
          />
        )}
      </div>

      <div className="flex gap-3 justify-between">
        <SharedProfileSelect
          menuPortalTarget={document.body}
          className="flex-1"
          name="responsible"
          label={t("maintenance.responsible")}
          placeholder={t("common.select.user")}
          listParams={{is_staff: true}}
          isClearable={false}
          isSearchable={false}
        />
        {selectedType === "date" ? (
          <FormControl
            name="time_threshold"
            type="number"
            label={t("maintenance.notify_before")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            addonright={t("common.times_count.days")}
          />
        ) : (
          <FormControl
            name="notify_distance"
            type="number"
            label={t("maintenance.notify_before")}
            rootclassname="flex-1"
            className="form-control form-control-number"
            number_only={+true}
            number_decimal={+false}
            addonright={t("maintenance.km")}
          />
        )}
      </div>
    </>
  )
}
