import {useCallback, useEffect, useState} from "react"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {ColumnsType} from "antd/es/table"
import Table from "src/components/shared/antd-custom/table"
import toDateTime from "src/utils/date-time"
import {toPrice} from "src/utils/price"
import {UserSalaryTypeEarningModel, salaryNameMap} from "src/models/manager/profile/statistics"
import {FormProvider, useFormContext} from "react-hook-form"
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import metricsService from "src/services/metrics/products-parts.service"
import {RequestStatus} from "src/components/shared/components/request-link"
import {useTranslation} from "react-i18next"

export default function ProfileRentReportComponent({userId}: {userId: number}) {
  const {t} = useTranslation()
  const [list, setList] = useState<ListModel<UserSalaryTypeEarningModel>>(EMPTY_LIST)
  const form = useFormContext()
  const {watch, setValue} = form
  const values = watch()

  const columns: ColumnsType<UserSalaryTypeEarningModel> = [
    {
      width: 48,
      title: "№",
      key: "request_id",
      dataIndex: "request_id",
      render: (request_id) => request_id
    },
    {
      width: 240,
      title: t("profile.table.dates"),
      key: "dates",
      dataIndex: "dates",
      render: (dates) => (dates ? dates.map((date) => toDateTime(date)).join(", ") : undefined),
      sorter: true
    },
    {
      width: 120,
      title: t("profile.table.type"),
      key: "type",
      dataIndex: "type",
      render: (type) => (
        <span className="btn btn-primary-20 btn-color-primary font-semibold p-1 px-2">{t(salaryNameMap[type])}</span>
      ),
      sorter: true
    },
    {
      width: 160,
      title: t("profile.table.earning"),
      key: "earning",
      dataIndex: "earning",
      render: (earning) => toPrice(earning),
      sorter: true
    },
    {
      width: 160,
      title: t("profile.table.status"),
      key: "status",
      render: (_, profile) => <RequestStatus requestId={profile.request_id} requestStatus={profile.request_status} />
    }
  ]

  const listStats = useCallback(
    (_params) => {
      metricsService
        .listUserSalaryTypeEarningList(userId, _params)
        .then(setList)
        .catch((e) => e.response.status === 404 && setValue("page", 1))
    },
    [userId]
  )

  useEffect(() => {
    listStats(values)
    const sub = watch((_params) => listStats(_params))
    return () => sub.unsubscribe()
  }, [userId])

  return (
    <FormProvider {...form}>
      <Table rowKey={(obj) => `${obj.request_id}_${obj.type}`} columns={columns} dataSource={list.results} />
      <PaginationFormComponent count={list.count} className="w-full" />
    </FormProvider>
  )
}
