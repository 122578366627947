import {useContext, useMemo} from "react"
import {CustomField} from "src/models/setting-config"
import {CustomFieldFilterChoice} from "./field-filter-choice"
import {ConfigContext} from "src"

export function CustomFieldSetFilterList({prefix, filter = {}}: {prefix: string; filter: any}) {
  const {settings} = useContext(ConfigContext)
  const customFields = useMemo(() => {
    if (!settings) return []
    return settings.custom_fields?.inventory?.filter((field) => field?.filter === true)
  }, [settings])

  const filterAvailable = customFields.length > 0

  return (
    filterAvailable && (
      <div className="flex gap-2" style={{position: "relative"}}>
        {customFields.map((field: CustomField) => (
          <CustomFieldFilterChoice
            key={field.name}
            type={field.type}
            filter={filter}
            field={`extra__${field.name}`}
            selectorProps={{menuPortalTarget: document.body}}
            params={{
              name: `${prefix}${field.name}`,
              placeholder: field.label,
              rootclassname: "col"
            }}
          />
        ))}
      </div>
    )
  )
}
