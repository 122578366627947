import {CategoryModel} from "src/models/manager/constants"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import {FormControl} from "../../inputs/form-control"
import modalService, {ExtendedModalConfig} from "src/components/modal/global/modal.service"
import GenericEntityService from "src/services/generic-entity.service"
import {useTranslation} from "react-i18next"
import appService from "src/services/app.service"
import {useContext} from "react"
import {ConfigContext} from "src"
import FormSelect, {FormSelectConfig} from "../../inputs/form-select"
import {useFormContext} from "react-hook-form"

type RemovalKeys = "getOptionLabel" | "getOptionValue" | "modalProps" | "options"

interface CategorySelectConfig extends Omit<FormSelectConfig<CategoryModel>, RemovalKeys> {
  addOption?: boolean
}

export function SharedCategorySelect(config: CategorySelectConfig) {
  const {constants, constantsMap} = useContext(ConfigContext)
  const {t} = useTranslation()
  const service = new GenericEntityService<CategoryModel>("v1/crm/categories")
  const form = useFormContext()
  const value = form.watch(config.name)

  const onCreate = async (payload) => {
    payload.comment = payload.comment || null
    const res = await service.post(payload)
    form.setValue(config.name, res.id)
    appService.loadConstants()
  }

  const modalProps: ExtendedModalConfig = {
    text: "Новая категория",
    className: "gap-2",
    props: {dialogClassName: "w-[480px]", backdrop: true},
    component: (
      <ConfigAddOrEdit<CategoryModel>
        onSubmit={(inventory) => onCreate(inventory).then(() => modalService.closeModal())}
        buttonTitle="Создать и добавить"
        title={t("configuration.category.header_add")}
        mode="add">
        <FormControl
          name="name"
          label={t("common.input.name")}
          className="form-control"
          rootclassname="col"
          params={{required: true}}
          required
        />
      </ConfigAddOrEdit>
    )
  }

  return (
    <FormSelect<CategoryModel>
      {...config}
      isSearchable
      modalProps={config.addOption && modalProps}
      options={constants.INVENTORY_CATEGORIES}
      value={constantsMap.INVENTORY_CATEGORIES[+value]}
      getOptionLabel={(option: CategoryModel) => (option ? String(option.name) : null)}
      getOptionValue={(option: CategoryModel) => (option ? String(option.id) : null)}
    />
  )
}
