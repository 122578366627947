import {lazy} from "react"
import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "src/components/custom-suspense"
import SettingsComponent from "src/pages/settings/settings"

const SettingsMainComponent = lazy(() => import("src/pages/settings/settings-main"))
const SettingsRentComponent = lazy(() => import("src/pages/settings/settings-rent"))
const SettingsInterfaceComponent = lazy(() => import("src/pages/settings/settings-interface"))
const SettingsPenaltyComponent = lazy(() => import("src/pages/settings/settings-penalty"))
const SettingsStateComponent = lazy(() => import("src/pages/settings/state/settings-state"))
const SettingsManagersComponent = lazy(() => import("src/pages/settings/settings-managers"))
const SettingsCustomFieldComponent = lazy(() => import("src/pages/settings/custom-field"))
const SettingsBonusComponent = lazy(() => import("src/pages/settings/settings-bonus"))
const SettingsNotificationComponent = lazy(() => import("src/pages/settings/settings-notifications"))
const SettingsColorsComponent = lazy(() => import("src/pages/settings/settings-colors"))

// additional
const SettingsWazzupComponent = lazy(() => import("src/pages/settings/wazzup"))

export const settingRoutes: RouteObject[] = [
  {
    path: "settings",
    element: <SettingsComponent />,
    children: [
      {path: "", element: <Navigate to="main" replace={true} />},
      {
        path: "main",
        element: (
          <CustomSuspense>
            <SettingsMainComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rent",
        element: (
          <CustomSuspense>
            <SettingsRentComponent />
          </CustomSuspense>
        )
      },
      {
        path: "interface",
        element: (
          <CustomSuspense>
            <SettingsInterfaceComponent />
          </CustomSuspense>
        )
      },
      {
        path: "penalty",
        element: (
          <CustomSuspense>
            <SettingsPenaltyComponent />
          </CustomSuspense>
        )
      },
      {
        path: "inventory-state",
        element: (
          <CustomSuspense>
            <SettingsStateComponent />
          </CustomSuspense>
        )
      },
      {
        path: "order-status",
        element: (
          <CustomSuspense>
            <SettingsColorsComponent />
          </CustomSuspense>
        )
      },
      {
        path: "custom-fields",
        element: (
          <CustomSuspense>
            <SettingsCustomFieldComponent />
          </CustomSuspense>
        )
      },
      {
        path: "bonus",
        element: (
          <CustomSuspense>
            <SettingsBonusComponent />
          </CustomSuspense>
        )
      },
      {
        path: "notifications",
        element: (
          <CustomSuspense>
            <SettingsNotificationComponent />
          </CustomSuspense>
        )
      },
      {
        path: "managers",
        element: (
          <CustomSuspense>
            <SettingsManagersComponent />
          </CustomSuspense>
        )
      },
      {
        path: "wazzup",
        element: (
          <CustomSuspense>
            <SettingsWazzupComponent />
          </CustomSuspense>
        )
      },
      {path: "*", element: <Navigate to="main" replace={false} />}
    ]
  }
]
