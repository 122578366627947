import {Popover} from "antd"
import {useEffect, useState} from "react"
import {useFormContext} from "react-hook-form"
import {OrderRequestAmountModel} from "src/models/manager/order/order-request-list.model"
import orderService from "src/services/order-requests/order.service"
import {toPrice} from "src/utils/price"
import {useTranslation} from "react-i18next"

export default function OrderListAmountComponent({filter}: {filter: any}) {
  const {t} = useTranslation()
  const [amount, setAmount] = useState<OrderRequestAmountModel>(undefined)
  const form = useFormContext()
  const values = form.watch()

  useEffect(() => {
    orderService.getAmount({...filter, ...values}).then(setAmount)
    const sub = form.watch((params, {name}) => {
      if (name !== "search") orderService.getAmount({...filter, ...params}).then(setAmount)
    })
    return () => {
      sub.unsubscribe()
      setAmount(undefined)
    }
  }, [filter])

  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        amount ? (
          <div className="flex gap-2 flex-col">
            <div className="flex items-end justify-between gap-2">
              <span className="font-medium">{t("orders.list.rent_sum")}</span>
              <span className="border-b border-dashed border-gray-100 flex-1 min-w-12"></span>
              <span className="font-medium">{toPrice(amount.price_discount_sum)}</span>
            </div>
            <div className="flex items-end justify-between gap-2">
              <span className="font-medium">{t("orders.list.taxes")}</span>
              <span className="border-b border-dashed border-gray-100 flex-1 min-w-12"></span>
              <span className="font-medium">{toPrice(amount.tax_amount_sum)}</span>
            </div>
            <div className="flex items-end justify-between gap-2 text-accept">
              <span className="font-medium">{t("orders.list.pay_amount")}</span>
              <span className="border-b border-dashed border-gray-100 flex-1 min-w-12"></span>
              <span className="font-medium">{toPrice(amount.paid_amount_sum)}</span>
            </div>
            <div className="flex items-end justify-between gap-2 text-alert">
              <span className="font-medium">{t("orders.list.debts_amount")}</span>
              <span className="border-b border-dashed border-gray-100 flex-1 min-w-12"></span>
              <span className="font-medium">{toPrice(amount.debt_amount_sum)}</span>
            </div>
          </div>
        ) : (
          <div className="spinner-border spinner-border-sm color-gray-300"></div>
        )
      }>
      <div className="flex items-center justify-start bg-white shadow p-3 rounded-lg border border-gray-100 text-center cursor-pointer text-nowrap">
        {amount ? (
          <span className="font-medium">
            {t("orders.list.rent_sum")} - {toPrice(amount.price_discount_sum)}
          </span>
        ) : (
          <span className="spinner-border spinner-border-sm color-gray-300"></span>
        )}
      </div>
    </Popover>
  )
}
