import {Dispatch, SetStateAction} from "react"
import {Pagination} from "antd"
import {ListParams} from "src/models/common"
import {useTranslation} from "react-i18next"

export default function PaginationComponent(config: {
  params: [ListParams, Dispatch<SetStateAction<ListParams>>]
  count: number
  component?: JSX.Element
}) {
  const {t} = useTranslation()
  const [params, setParams] = config.params

  return (
    <div className="flex justify-between items-center my-3">
      {config.component || <div>{t("common.pagination.all", {count: config.count})}</div>}
      <Pagination
        current={Number(params.page)}
        pageSize={Number(params.pageSize)}
        onChange={(page, pageSize) => {
          setParams({
            ...params,
            page: params.pageSize !== pageSize ? 1 : page,
            pageSize
          })
        }}
        onShowSizeChange={(_, pageSize) => setParams({...params, page: 1, pageSize: pageSize})}
        total={config.count}
        className="shadow"
      />
    </div>
  )
}
