import {useContext, useEffect} from "react"
import {FormProvider, useFieldArray, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {firstValueFrom} from "rxjs"
import {SharedInventoryStateSelect} from "src/components/shared/components/select/inventory-state"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {BaseInventoryModel} from "src/models/manager/inventory/inventory.model"
import {DetailOrderRequestModel} from "src/models/manager/order/order-request-detail.model"
import modalService from "src/components/modal/global/modal.service"
import appService from "src/services/app.service"
import inventoryInventorizationService from "src/services/inventory/inventory-inventorization.service"
import orderService from "src/services/order-requests/order.service"
import {ConfigContext} from "../../../../index"
import Icon from "../../../../components/shared/components/material-icon"
import {OrderStatus} from "../../constants"
import {OrderContext} from "../context/order-context"
import HeaderContent from "../../../../components/header/header-content"
import {useNavigate} from "react-router-dom"

interface InventorizationForm {
  request_inventory: number
  inventory: BaseInventoryModel
  state: number
  body: string
  extra: {
    total_distance: number
  }
}

interface InventorizationArrayForm {
  datetime: string
  inventories: InventorizationForm[]
}

export function OrderInventorizationRouteWrapper() {
  const {order, backCheckouts, updateOrder} = useContext(OrderContext)
  const rentEnd = async (datetime?: string) => {
    const payload = {
      status: OrderStatus.COMPLETED,
      order_inventories: backCheckouts,
      datetime: null
    }
    if (!!datetime && datetime !== "") payload.datetime = datetime
    await orderService.onAction(order.id, payload)
    // setDocumentModal(true)
    updateOrder()
  }

  return <OrderInventorizationModal ids={backCheckouts} order={order} onSubmit={rentEnd} />
}

export default function OrderInventorizationModal(config: {
  order: DetailOrderRequestModel
  ids: number[]
  onSubmit: (datetime: string) => Promise<any>
}) {
  const form = useForm<InventorizationArrayForm>()
  const {formState, reset, control, handleSubmit, watch} = form
  const {t} = useTranslation()
  const order = config.order
  const {tenant, isMobile, showMobileMenu, hideMobileMenu} = useContext(ConfigContext)
  const navigate = useNavigate()

  const inventoriesCommentForm = useFieldArray({
    control: control,
    name: "inventories",
    rules: {required: true}
  })

  const onSubmit = handleSubmit(async ({inventories, datetime}) => {
    const states = await firstValueFrom(appService.getConstant("INVENTORY_STATE_STATUS"))
    const payload = {
      request_id: order.id,
      inventories: inventories.map((obj: InventorizationForm) => {
        if (!obj.body) {
          obj.body = states.find((state) => state.id === Number(obj.state)).name
        }
        if (obj.extra && obj.extra.total_distance) {
          obj.extra.total_distance = +obj.extra.total_distance
        }
        return {...obj, inventory: obj.inventory.id}
      })
    }

    inventoryInventorizationService.bulkCreate(payload).then(() => {
      config.onSubmit(datetime)
      if (isMobile) {
        navigate(-1)
      } else {
        modalService.closeAllModal()
      }
    })
  })

  useEffect(() => {
    const filter = {received: false, id__in: config.ids.join(","), type: 0}
    orderService.listInventories(order.id, filter).then((_list) =>
      reset({
        inventories: _list.map((inv) => ({
          inventory: inv.inventory,
          request_inventory: inv.id
        }))
      })
    )
  }, [config.ids, reset, order.id])

  useEffect(() => {
    if (isMobile) hideMobileMenu()
    return () => showMobileMenu()
  }, [formState.isValid, isMobile, t])

  return (
    tenant && (
      <FormProvider {...form}>
        {isMobile && (
          <HeaderContent>
            <div className={"gap-1 py-5 header-content border-0 border-b border-gray-100"}>
              <Icon icon={"chevron_left"} className={"text-2xl"} onClick={() => navigate(-1)} />
              <p className={"font-bold text-xl text-test-black text-center"}>{t("orders.inventorization.header")}</p>
            </div>
          </HeaderContent>
        )}

        <div className="mb-4 flex gap-2 md:items-center items-start md:rounded-none rounded-xl justify-between md:flex-row flex-col md:bg-transparent bg-white md:shadow-none shadow md:p-0 p-4">
          <h5 className="text-xl mb-0 font-bold">{t("orders.inventorization.header")}</h5>
          <SharedInventoryStateSelect
            className="w-full"
            required={true}
            name="state"
            onChange={(val: any) =>
              reset({
                ...watch(),
                inventories: inventoriesCommentForm.fields.map((obj) => ({
                  ...obj,
                  state: val.id
                }))
              })
            }
            isClearable={false}
            isSearchable={false}
          />
        </div>

        {inventoriesCommentForm.fields.map((inv, index) => (
          <div
            key={inv.id}
            className="flex gap-2 md:mb-0 mb-3 md:flex-nowrap md:flex-row flex-col flex-wrap md:bg-transparent bg-white md:p-0 p-4 md:rounded-none rounded-xl md:shadow-none shadow">
            {isMobile ? (
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-[16px] text-test-black">{inv.inventory.name}</p>
                  <p className="font-normal text-[13px] text-gray-400">{inv.inventory.unique_id}</p>
                </div>
                <hr className="text-gray-300 w-full" />
                <SharedInventoryStateSelect
                  className="flex-1 md:max-w-[320px]"
                  label={t("common.select.state_status")}
                  required={true}
                  name={`inventories.${index}.state`}
                  params={{required: true}}
                  isClearable={false}
                  isSearchable={false}
                />
                {tenant.type_code === "auto" && (
                  <>
                    <hr className="text-gray-300 w-full" />
                    <FormControl
                      name={`inventories.${index}.body`}
                      label={t("orders.inventorization.comment")}
                      placeholder={t("orders.inventorization.comment")}
                      className="form-control"
                      rootclassname="flex-1"
                    />
                    <hr className="text-gray-300 w-full" />
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <span className="text-[13px] text-gray-400">
                          {t("orders.inventorization.prev_total_distance")}
                        </span>
                        <div
                          placeholder={t("orders.inventorization.prev_total_distance")}
                          className="form-control form-disabled hover:bg-gray-100 opacity-100 ">
                          {inv.inventory.extra.total_distance || 0}
                        </div>
                      </div>
                      <FormControl
                        name={`inventories.${index}.extra.total_distance`}
                        label={t("orders.inventorization.new_total_distance")}
                        placeholder={t("orders.inventorization.new_total_distance")}
                        className="form-control"
                        rootclassname="flex-1"
                        params={{required: true}}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-4 border-b border-b-gray-100 w-full py-4">
                <FormControl
                  name={`inventories.${index}.body`}
                  label={`${inv.inventory.name} (${inv.inventory.unique_id})`}
                  placeholder={t("orders.inventorization.comment")}
                  className="form-control"
                  rootclassname="flex-1"
                />
                <SharedInventoryStateSelect
                  className="flex-1"
                  label={t("common.select.state_status")}
                  required={true}
                  name={`inventories.${index}.state`}
                  params={{required: true}}
                  isClearable={false}
                  isSearchable={false}
                />
                {tenant.type_code === "auto" && (
                  <>
                    <div className="flex flex-col gap-2">
                      <span className="text-[13px] text-gray-400">
                        {t("orders.inventorization.prev_total_distance")}
                      </span>
                      <div
                        placeholder={t("orders.inventorization.prev_total_distance")}
                        className="form-control form-disabled hover:bg-gray-100 opacity-100 ">
                        {inv.inventory.extra.total_distance || 0}
                      </div>
                    </div>
                    <FormControl
                      name={`inventories.${index}.extra.total_distance`}
                      label={t("orders.inventorization.new_total_distance")}
                      placeholder={t("orders.inventorization.new_total_distance")}
                      className="form-control"
                      rootclassname="flex-1"
                      params={{required: true}}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-end gap-2 mt-3 md:flex-row flex-col-reverse md:flex-nowrap flex-wrap">
          <FormControlDatetime
            name="datetime"
            className="form-control"
            placeholder={t("orders.side_menu.product_accept_date")}
            datetimeConfig={{
              minuteStep: 1,
              showTime: {format: "HH:mm"},
              allowClear: false
            }}
          />

          <button
            className={`btn btn-color-white gap-3 ${isMobile ? "btn-primary" : "btn-black"}`}
            disabled={!formState.isValid}
            onClick={onSubmit}>
            <Icon icon="arrow_top_left" />
            <p>{t("orders.inventorization.confirm")}</p>
          </button>
        </div>
      </FormProvider>
    )
  )
}
