function objectToQueryString(obj) {
  const queryParams = []

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]

      if (value !== null && value !== undefined) {
        const encodedKey = encodeURIComponent(key)
        const encodedValue = encodeURIComponent(value)
        queryParams.push(`${encodedKey}=${encodedValue}`)
      }
    }
  }

  return queryParams.join("&")
}

export abstract class ApiAbstract {
  constructor(protected uri: string) {}

  protected getUrlV2(branch?: string | number, params?: any): string {
    let url = `/${this.uri}`
    if (branch) url += `/${branch}`
    return `${url}/?${objectToQueryString(params)}`
  }

  protected getUrl(branch?: string | number): string {
    let url = `/${this.uri}`
    if (branch) url += `/${branch}`
    return `${url}/`
  }
}
