import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router"
import HeaderContent from "src/components/header/header-content"
import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"

export default function UserPermissionComponent() {
  const navigate = useNavigate()
  const activeRoute = useCurrentRoute()
  const {t} = useTranslation()

  const tabsConfig: TabConfig = {
    items: [
      {key: "main", label: t("users.pages.default")},
      {key: "sublease", label: t("users.pages.sublease")},
      {key: "roles", label: t("users.pages.roles")}
    ],
    onClick: (config: TabPanelConfig) => navigate(`/users/${config.key}`),
    activeTab: activeRoute,
    style: {padding: "0 16px"}
  }
  return (
    <>
      <HeaderContent>
        {/*<div className="text-2xl font-semibold header-content">{t("users.header")}</div>*/}
        <TabPanel {...tabsConfig} />
      </HeaderContent>

      <Outlet />
    </>
  )
}
