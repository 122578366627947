import {BehaviorSubject} from "rxjs"

export class LoadingService {
  loading$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  setLoading = (val: string) => this.loading$.next([...this.loading$.value, val])
  removeLoading = (val: string) => this.loading$.next(this.loading$.value.filter((str) => str !== val))
}

const loadingService = new LoadingService()
export default loadingService
